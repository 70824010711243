import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from "@angular/router";
import { FunctionsService } from './functions.service';
import { DataService } from './data.service';
import { CookieService } from "angular2-cookie/core";
import { BehaviorSubject } from 'rxjs';

export const httpOptions = {
  headers: new HttpHeaders({"Content-Type": "application/json",authuser: "",user_type: ""}),
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(public httpClient: HttpClient, private router: Router, private fun: FunctionsService, private dataService: DataService,private cookieService: CookieService) { }
  private keyStatusSubject = new BehaviorSubject<boolean>(this.checkKeyStatus());
  keyStatus$ = this.keyStatusSubject.asObservable();
  logout(): void {
    // console.log('logout')
    localStorage.clear();
    httpOptions.headers = httpOptions.headers.set("authuser", "");
    this.dataService.current_user = { 
      name: '',
      mobile: '',
      uid:'',
      panNo:'',
      panName:''
    };
    this.cookieService.remove("test");
    this.fun.navigate('login', false)
  }

  private checkKeyStatus(): boolean {
    return !!localStorage.getItem('newUserAuthKey');
  }

  private isAuthorized = false;

  setAuthorizationStatus(status: boolean) {
    this.isAuthorized = status;
  }

  getAuthorizationStatus(): boolean {
    return this.isAuthorized;
  }

}
