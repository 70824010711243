// encryption.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptionServiceService {
  private secretKey = 'yourSecretKey';

  encrypt(id: number): string {
    const idString = id.toString();
    const keyArray = this.secretKey.split('');

    const encryptedChars = idString
      .split('')
      .map((char, index) => String.fromCharCode(char.charCodeAt(0) ^ keyArray[index % keyArray.length].charCodeAt(0)));

    const encryptedString = encryptedChars.join('');

    return btoa(encryptedString);
  }

  decrypt(encryptedId: string): number {
    const decryptedString = atob(encryptedId);
    const decryptedChars = decryptedString.split('');
    const keyArray = this.secretKey.split('');

    const decryptedId = decryptedChars
      .map((char, index) => String.fromCharCode(char.charCodeAt(0) ^ keyArray[index % keyArray.length].charCodeAt(0)))
      .join('');

    return parseInt(decryptedId, 10);
  }


  decryptEmail(encryptedEmail: string): string {
    try {
      const decryptedString = atob(encryptedEmail);
      const decryptedChars = decryptedString.split('');
      const keyArray = this.secretKey.split('');

      const decryptedEmail = decryptedChars
        .map((char, index) => String.fromCharCode(char.charCodeAt(0) ^ keyArray[index % keyArray.length].charCodeAt(0)))
        .join('');

      return decryptedEmail;
    } catch (error) {
      console.error('Decryption error:', error);
      return ''; // Return a default value or handle the error appropriately
    }
  }
}
