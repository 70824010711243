import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2pdf from 'html2pdf.js';
import { ProjParams } from 'src/app/config';

import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss'],
})
export class ViewInvoiceComponent implements OnInit {
  isLoading : boolean = false;
  currentDate: string;
  dateAfterOneMonth: string;
  clientDetails: any;
  companyName: any;
  gstNo: any;
  address: any;
  shouldDisplayAlert: boolean = false; 
  showInput: boolean = false;
  isFlagTrue: boolean = false;
  invoiceList: any;
invoiceItems: any[] = [];
invoiceMonth: any;
invoiceYear: any;
invoiceId: number = 0;
invoiceNo: any;
  show: boolean = false;
  tableRows: string;
  sacCodeValue: any;
  billNoValue: any;
  invoiceDateValue: string;
  totalAmountWithoutTaxValue: string;
  dateAfterMonthValue: any;
  totalAmountAfterTaxValue: any;
  cgstAmountValue: any;
  totalAmountWithoutTax1Value: string;
  invoiceYearValue: any;
  sgstAmountValue: any;
  amountInWordsValue: string;
  addressValue: any;
  address1Value: any;
  companyNameValue: any;
  companyName1Value: any;
  gstTextWithNoValue: string;
  gstTextWithNo1Value: string;
billNo: any;
  invoice: any;
  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.invoiceId = params['id'];
      // Now you have access to the invoiceId in this component
      console.log('Invoice ID:', this.invoiceId);
    });
  
    this.clientDetail();
    var url = ProjParams.clientUrl + 'xb/apiPanel/invoiceList'; 
  
    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
    this.isLoading = false;

          this.invoiceList = data.data;
          this.invoice = this.invoiceList.find(invoice => invoice.id == this.invoiceId);
          this.invoiceMonth = this.invoice.invoice_month;
          this.invoiceYearValue = this.invoice.invoice_year;
          this.titleService.setTitle(`Invoices | ${this.invoiceMonth} ${this.invoiceYearValue}`)
          this.generatePDF()
  
        } else {
          console.error('Invalid data format:', data);
        }
      },
      
      (error) => {
    this.isLoading = false;

        const errorMessage = error.message;
        console.error('Error:', error);
      }
    );
    console.log('the invoice month', this.invoiceMonth)
 
  }


  clientToken = localStorage.getItem('newUserAuthKey');




  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };



  goBack(){
    this.router.navigate(['/api/invoice'])
  }

  convertToPDF() {
    // Select the pdf-content div
    const element = document.getElementById('pdf-content');

    // Options for html2pdf
    const options = {
      margin: 0.2,
      filename: 'Invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Start the conversion process
    html2pdf().from(element).set(options).save();
  }

convertToWords(number: number): string {
const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
const scales = ['', 'Thousand', 'Lakh', 'Crore'];


  if (number === 0) {
      return 'zero rupees';
  }

  let words = '';
  let scaleIndex = 0;

  while (number > 0) {
      const chunk = number % 1000;
      if (chunk !== 0) {
          let chunkWords = '';
          const hundreds = Math.floor(chunk / 100);
          const tensUnits = chunk % 100;

          if (hundreds > 0) {
              chunkWords += units[hundreds] + ' hundred ';
          }

          if (tensUnits >= 10 && tensUnits < 20) {
              chunkWords += teens[tensUnits - 10];
          } else {
              const tensDigit = Math.floor(tensUnits / 10);
              const unitsDigit = tensUnits % 10;

              if (tensDigit > 0) {
                  chunkWords += tens[tensDigit] + ' ';
              }
              if (unitsDigit > 0) {
                  chunkWords += units[unitsDigit];
              }
          }

          chunkWords = chunkWords.trim();
          words = chunkWords + ' ' + scales[scaleIndex] + ' ' + words;
      }

      scaleIndex++;
      number = Math.floor(number / 1000);
  }

  return words.trim() + ' rupees';
}


  clientDetail(){
    this.isLoading = true
    var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 
  
    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.clientDetails = data.data;
          this.companyName = this.clientDetails?.company_name;
          this.gstNo = this.clientDetails?.gst_no;
          this.address = this.clientDetails?.address;
          this.isLoading = false;
  
        } else {
          console.error('Invalid data format:', data);
        }
      },
      
      (error) => {
        this.isLoading = false;
        const errorMessage = error.message;
        console.error('Error:', error);
      }
    );
  }


  getInvoiceList(){
    this.isLoading = true


  }







  generatePDF() {
    console.log('generate pdf ran')

  
    const invoice = this.invoice;
  
    // Load the HTML template
  
  
      const invoiceDate = new Date(invoice?.createdAt);
      const amountInWords = invoice && typeof invoice.total_amount !== 'undefined' ? this.convertToWords(invoice.total_amount) : '';
  
  
  const createdAt = new Date(invoice?.createdAt);
  
  const dateAfterMonth = new Date(createdAt);
  dateAfterMonth.setDate(dateAfterMonth.getDate() + 30);
  this.dateAfterMonthValue = dateAfterMonth
  
  
  function formatDate(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const formattedDateAfterMonth = formatDate(dateAfterMonth);
  const gstText = 'GSTIN';
  
      const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
      let gstTextWithNo = '';
      if (this.gstNo) {
        gstTextWithNo = `${gstText} ${this.gstNo}`;
      }
  
      this.sacCodeValue = invoice.sac_code;
      console.log('sacCodeValue:', this.sacCodeValue);
      
      this.billNoValue = invoice.bill_no;
      console.log('billNoValue:', this.billNoValue);
      
      this.invoiceDateValue = formattedInvoiceDate;
      console.log('invoiceDateValue:', this.invoiceDateValue);
      
      this.invoiceMonth = invoice.invoice_month;
      console.log('invoiceMonth:', this.invoiceMonth);
      
      this.invoiceYearValue = invoice.invoice_year;
      console.log('invoiceYearValue:', this.invoiceYearValue);
      
      this.totalAmountWithoutTaxValue = parseFloat(invoice.without_gst_amount).toFixed(2);
      console.log('totalAmountWithoutTaxValue:', this.totalAmountWithoutTaxValue);
      
      this.totalAmountWithoutTax1Value = parseFloat(invoice.without_gst_amount).toFixed(2);
      console.log('totalAmountWithoutTax1Value:', this.totalAmountWithoutTax1Value);
      
      this.cgstAmountValue = invoice.cgst_amount;
      console.log('cgstAmountValue:', this.cgstAmountValue);
      
      this.sgstAmountValue = invoice.sgst_amount;
      console.log('sgstAmountValue:', this.sgstAmountValue);
      
      this.totalAmountAfterTaxValue = invoice.total_amount;
      console.log('totalAmountAfterTaxValue:', this.totalAmountAfterTaxValue);
      
      this.amountInWordsValue = amountInWords;
      console.log('amountInWordsValue:', this.amountInWordsValue);
      
      this.dateAfterMonthValue = formattedDateAfterMonth;
      console.log('dateAfterMonthValue:', this.dateAfterMonthValue);
      
      this.addressValue = this.address;
      console.log('addressValue:', this.addressValue);
      
      this.address1Value = this.address;
      console.log('address1Value:', this.address1Value);
      
      this.companyNameValue = this.companyName;
      console.log('companyNameValue:', this.companyNameValue);
      
      this.companyName1Value = this.companyName;
      console.log('companyName1Value:', this.companyName1Value);
      
      this.gstTextWithNoValue = gstTextWithNo;
      console.log('gstTextWithNoValue:', this.gstTextWithNoValue);
      
      this.gstTextWithNo1Value = gstTextWithNo;
      console.log('gstTextWithNo1Value:', this.gstTextWithNo1Value);
      
  
  
      // let serialNumber = 1; 
      // invoice.api_invoice_items.forEach((apiInvoiceItem) => {
        
      //     this.tableRows += `
      //       <tr>
      //         <td class="custom-text3"  style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${serialNumber}</td>
      //         <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.description || '--'}</td>
      //         <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem;">${invoice.sac_code || '--'}</td>
      //         <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.hit_count || '--'}</td>
      //         <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.hit_charge || '--'}</td>
      //         <td class="custom-text3"   style=" vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.total_amount || '--'}</td>
      //       </tr>`;
      //       serialNumber++; 
  
      //   });
        
  
    //   htmlContent = htmlContent.replace('{{tableRows}}', tableRows);
  
    //   const opt = {
    //     // margin: 0.5,
    //     filename: 'invoice.pdf',
    //     // image: { type: 'jpg', quality: 2 },
    //     // jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait', dpi: 1200 },
    //     // html2canvas: { logging: true, scrollX: 0, scrollY: 0 },
    //   //   pagebreak: { mode: ['avoid-all']
    //   //  }
    //   };
  
    //   html2pdf().from(htmlContent).output('dataurlnewwindow');
    // ;
  
    
  }

}
