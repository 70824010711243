import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProjParams } from './config';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientDetailsServiceService {
  private clientDetails: any = {};
  private clientDetail: any = null;
  private clientServiceDetail: any = null;
  clientToken = localStorage.getItem('newUserAuthKey');
  httpOptionsClient = {
    headers: new HttpHeaders({
      'clientauth': this.clientToken
    })
  };
  constructor(private http: HttpClient) {}



  setclientDetails(details: any) {
    this.clientDetails = details;
  }

  getclientDetails() {
    return this.clientDetails;
  }


  getClientUserDetail(): Observable<any> {
    if (this.clientDetail) {
      return of(this.clientDetail);
    } else {
      return this.fetchClientUserDetail().pipe(
        map(data => {
          this.clientDetail = data;
          return this.clientDetail;
        })
      );
    }
  }

  private fetchClientUserDetail(): Observable<any> {
    const url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail';

    return this.http.get(url, this.httpOptionsClient).pipe(
      tap((data: any) => {
        if (data && data.data) {
          this.clientDetail = data.data;
        } else {
          console.error('Invalid data format:', data);
        }
      }),
      catchError((error) => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }

  getClientServiceDetail(): Observable<any> {
    if (this.clientServiceDetail) {
      return of(this.clientServiceDetail);
    } else {
      return this.fetchClientServiceDetail().pipe(
        map(data => {
          this.clientServiceDetail = data;
          return this.clientServiceDetail;
        })
      );
    }
  }

  fetchClientServiceDetail(): Observable<any> {
    const url = ProjParams.clientUrl + 'xb/apiPanel/getService';

    return this.http.get(url, this.httpOptionsClient).pipe(
      tap((data: any) => {
        if (data && data.data) {
          this.clientServiceDetail = data.data;
        } else {
          console.error('Invalid data format:', data);
        }
      }),
      catchError((error) => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }

}