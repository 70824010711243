import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
NgbActiveModal


@Component({
  selector: 'app-history-table-filter',
  templateUrl: './history-table-filter.component.html',
  styleUrls: ['./history-table-filter.component.scss'],
})
export class HistoryTableFilterComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {}

  //close modal icon logic
  closeModal() {
    this.activeModal.close('Modal closed');
  }
}

