import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SearchGstFilingComponent } from './search-gst-filing.component';

  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule, FormsModule ],
      declarations: [ SearchGstFilingComponent ],
      exports: [ SearchGstFilingComponent ]
  })
  export class SearchGstFilingModule {}