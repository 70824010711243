import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SearchGstComponent } from './search-gst.component';

  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule, FormsModule ],
      declarations: [ SearchGstComponent ],
      exports: [ SearchGstComponent ]
  })
  export class SearchGstModule {}