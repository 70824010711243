import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ProjParams } from 'src/app/config';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit {
  responseData: any[];
  isLoading: boolean = false;
  countList: any[] = [];
  openRowId: number | null = null;

  clientToken = localStorage.getItem('newUserAuthKey');
  keyStatus = localStorage.getItem('newUserAuthKey');
  dropdownState: { [key: string]: boolean } = {};



   httpOptionsClient = {
    headers: new HttpHeaders({
       'clientauth':this.clientToken
     }) 
   };
  serviceId: string;
  rowData: any;


  constructor(private http: HttpClient, private dataService: DataService, private authService: AuthService, private router: Router, private flagService: DashboardFlagService
    , private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('XCIBIL - Service List')
    this.flagService.setDashboardFlag(true);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(false);
    this.flagService.sendMessage('Load');

    this.authService.keyStatus$.subscribe((keyStatus) => {

    

      this.isLoading = true;
      var url = ProjParams.clientUrl + 'xb/apiPanel/getService';
      this.http.get(url, this.httpOptionsClient).subscribe(
        (data: { data: any[] }) => {
          this.isLoading = false;
          // console.log(data);
          this.responseData = data.data;
        },
        
        (error) => {
          this.isLoading = false;
          if (error.error.message) {
            console.error('Error:', error); 
          }          console.error('Error:', error);
        }
      );
  
  });
  


    
  }

  toggleDropdown(id: string): void {
    this.serviceId = id
    this.dropdownState[id] = !this.dropdownState[id];
  }

  isDropdownOpen(id: string): boolean {
    return this.dropdownState[id];
  }

  
  toggleRow(row: any) {
    if (this.openRowId === row.id) {
      this.openRowId = null;
    } else {
      this.openRowId = row.id;
    }

    this.rowData = row;
  }

  isRowOpen(rowId: number): boolean {
    return this.openRowId === rowId;
  }


  


}
