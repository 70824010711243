import {
  NgModule
} from '@angular/core';
import {
  RouteReuseStrategy,
  RouterModule
} from '@angular/router';

import {
  IonicModule,
  IonicRouteStrategy,
  NavParams
} from '@ionic/angular';
import {
  CookieService
} from "angular2-cookie/services/cookies.service";
import {
  AppComponent
} from './app.component';
import {
  AppRoutingModule
} from './app-routing.module';

import {
  SidebarNewModule
} from './homepages/sidebar-new/sidebar-new.module';
import {
  NavbarNewModule
} from './homepages/navbar-new/navbar-new.module';
import {
  FooterNewModule
} from './homepages/footer-new/footer-new.module';
import {
  AdminLayoutComponent
} from './homepages/admin-layout/admin-layout.component';
import {
  NgbModule,
  NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import {
  CommonModule
} from '@angular/common';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  BankingApiNewComponent
} from './homepages/banking-api-new/banking-api-new.component';
import {
  PanApiNewComponent
} from './homepages/pan-api-new/pan-api-new.component';
import {
  XcibilReportNewComponent
} from './homepages/xcibil-report-new/xcibil-report-new.component';
import {
  InvoiceApiNewComponent
} from './homepages/invoice-api-new/invoice-api-new.component';
import {
  SettingsModalModule
} from './homepages/settings-modal/settings-modal.module';
import {
  ApiLoginModule
} from './user-api/api-login/api-login.module';
import {
  AuthGuard
} from './auth.guard';
import {
  ServiceListModule
} from './homepages/service-list/service-list.module';
import {
  ProfileModalModule
} from './homepages/navbar-profile-modal/navbar-profile-modal.module';
import {
  AllApiPageModule
} from './user-api/all-api-page/all-api-page.module';
import {
  CredenAndBillModule
} from './homepages/creden-and-bill/creden-and-bill.module';
import {
  DashboardNewComponent
} from './homepages/dashboard-new/dashboard-new.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  AddMoneyModule
} from './homepages/navbar-add-money-modal/navbar-add-money-modal.module';
import {
  PasswordResetModule
} from './homepages/pass-reset/pass-reset.module';
import {
  EmailSubmitModule
} from './homepages/api-login-modal/api-login-modal.module';
import {
  OtpSubmitModule
} from './homepages/api-login-modal-two/api-login-modal-two.module';
import {
  PasswordSubmitModule
} from './homepages/api-login-modal-three/api-login-modal-three.module';
import {
  SearchPanModule
} from './homepages/search-pan/search-pan.module';
import {
  SearchEquifaxModule
} from './homepages/search-equifax/search-equifax.module';
import {
  SearchXcibilModule
} from './homepages/search-xcibil/search-xcibil.module';
import {
  ViewInvoiceModule
} from './homepages/view-invoice/view-invoice.module';
import {
  ErrorPageModule
} from './error-page/error-page.module';
import {
  ServerStatusService
} from './server-status.service';
import {
  ServerGuard
} from './access.guard';
import {
  ApiLandingPage
} from './homepages/api-landing/api-landing.module';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  ViewReportModule
} from './homepages/xcibil-report/xcibil-report.module';
import {
  GstDetailModule
} from './gst-detail/gst-detail.module';
import {
  SearchGstModule
} from './homepages/search-gst/search-gst.module';
import {
  SearchGstFilingModule
} from './homepages/search-gst-filing/search-gst-filing.module';
import {
  QueriesModule
} from './homepages/queries/queries.module';
import {
  RaiseQueryModule
} from './homepages/raise-query/raise-query.module';
import {
  QueriesComentModule
} from './homepages/query-detail/query-detaail.module';
import {
  GstAuthModule
} from './homepages/gst-auth/gst-auth.module';
import { GstApiModule } from './homepages/gst-api/gst-api.module';
import { ServerErrorInterceptor } from './interceptors/server-error.service';




@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, DashboardNewComponent, BankingApiNewComponent, PanApiNewComponent, XcibilReportNewComponent, InvoiceApiNewComponent, XcibilReportNewComponent,

  ],
  entryComponents: [],
  imports: [IonicModule.forRoot(), RouterModule, AppRoutingModule, BrowserModule, FormsModule,
    ReactiveFormsModule, ApiLoginModule, ServiceListModule, AllApiPageModule, CredenAndBillModule,
    HttpClientModule, SidebarNewModule, FooterNewModule, NavbarNewModule, NgbModule, CommonModule, ProfileModalModule,
    NgbPaginationModule, SettingsModalModule, AddMoneyModule, PasswordResetModule, EmailSubmitModule, OtpSubmitModule, PasswordSubmitModule, SearchPanModule, SearchEquifaxModule, SearchXcibilModule, ViewInvoiceModule,
    ErrorPageModule, ApiLandingPage, BrowserAnimationsModule, ViewReportModule, GstDetailModule, SearchGstModule, SearchGstFilingModule, QueriesModule, RaiseQueryModule, QueriesComentModule, GstAuthModule, GstApiModule,
  ],

  providers: [CookieService, AuthGuard, {
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy
  }, NavParams, ServerStatusService, ServerGuard,  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],

})
export class AppModule {}