import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApiLoginComponent } from './user-api/api-login/api-login.component';
import { XcibilReportNewComponent } from './homepages/xcibil-report-new/xcibil-report-new.component';
import { InvoiceApiNewComponent } from './homepages/invoice-api-new/invoice-api-new.component';
import { PanApiNewComponent } from './homepages/pan-api-new/pan-api-new.component';
import { BankingApiNewComponent } from './homepages/banking-api-new/banking-api-new.component';
import { AdminLayoutComponent } from './homepages/admin-layout/admin-layout.component';
import { SmsDashboardNewComponent } from './homepages/sms-dashboard-new/sms-dashboard-new.component';
import { SidebarNewComponent } from './homepages/sidebar-new/sidebar-new.component';
import { AllApiPageComponent } from './user-api/all-api-page/all-api-page.component';
import { AuthGuard } from './auth.guard';
import { ServiceListComponent } from './homepages/service-list/service-list.component';
import { CredenAndBillComponent } from './homepages/creden-and-bill/creden-and-bill.component';
import { PassResetComponent } from './homepages/pass-reset/pass-reset.component';
import { SearchPanComponent } from './homepages/search-pan/search-pan.component';
import { SearchEquifaxComponent } from './homepages/search-equifax/search-equifax.component';
import { SearchXcibilComponent } from './homepages/search-xcibil/search-xcibil.component';
import { XcibilReportComponent } from './homepages/xcibil-report/xcibil-report.component';
import { ViewInvoiceComponent } from './homepages/view-invoice/view-invoice.component';
import { XcibilReportGuard } from './xcibil-report.guard';
import { ErrorPageComponent } from './error-page/error-page.component';

import { ErrorAccessGuard } from './error-access.guard';
import { ApiLandingComponent } from './homepages/api-landing/api-landing.component';
import { GstDetailComponent } from './gst-detail/gst-detail.component';
import { SearchGstComponent } from './homepages/search-gst/search-gst.component';
import { SearchGstFilingComponent } from './homepages/search-gst-filing/search-gst-filing.component';
import { QueriesComponent } from './homepages/queries/queries.component';
import { QueryDetailComponent } from './homepages/query-detail/query-detail.component';
import { GstAuthComponent } from './homepages/gst-auth/gst-auth.component';
import { GstApiComponent } from './homepages/gst-api/gst-api.component';
SidebarNewComponent

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'xcibil',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./homepages/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'otplogin',
    loadChildren: () => import('./pages/otplogin/otplogin.module').then( m => m.OtploginPageModule)
  },
  {
    path: 'refunds',
    loadChildren: () => import('./homepages/refunds/refunds.module').then( m => m.RefundsPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./homepages/contactuss/contactuss.module').then( m => m.ContactussPageModule)
  },
  {
    path: 'pricingpolicy',
    loadChildren: () => import('./homepages/pricingpolicy/pricingpolicy.module').then( m => m.PricingpolicyPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./homepages/privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'termscondition',
    loadChildren: () => import('./homepages/termscondition/termscondition.module').then( m => m.TermsconditionPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./homepages/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'updatetransaction',
    loadChildren: () => import('./pages/updatetransaction/updatetransaction.module').then( m => m.UpdatetransactionPageModule)
  },
  {
    path: 'updaterelationships',
    loadChildren: () => import('./pages/updaterelationships/updaterelationships.module').then( m => m.UpdaterelationshipsPageModule)
  },
  {
    path: 'raisedefaultform',
    loadChildren: () => import('./pages/raisedefaultform/raisedefaultform.module').then( m => m.RaisedefaultformPageModule)
  },
  {
    path: 'raisedefaultview',
    loadChildren: () => import('./pages/raisedefaultview/raisedefaultview.module').then( m => m.RaisedefaultviewPageModule)
  },
  {
    path: 'settlementform',
    loadChildren: () => import('./pages/settlementform/settlementform.module').then( m => m.SettlementformPageModule)
  },
  {
    path: 'settlementview',
    loadChildren: () => import('./pages/settlementview/settlementview.module').then( m => m.SettlementviewPageModule)
  },
  {
    path: 'C/:id',
    loadChildren: () => import('./pages/confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'bussinessprofile',
    loadChildren: () => import('./pages/bussinessprofile/bussinessprofile.module').then( m => m.BussinessprofilePageModule)
  },
  {
    path: 'historytranjection',
    loadChildren: () => import('./pages/historytranjection/historytranjection.module').then( m => m.HistorytranjectionPageModule)
  },
  {
    path: 'creditbalance',
    loadChildren: () => import('./pages/creditbalance/creditbalance.module').then( m => m.CreditbalancePageModule)
  },
  {
    path: 'addproprietor',
    loadChildren: () => import('./pages/addproprietor/addproprietor.module').then( m => m.AddproprietorPageModule)
  },
  {
    path: 'viewrelationship',
    loadChildren: () => import('./pages/viewrelationship/viewrelationship.module').then( m => m.ViewrelationshipPageModule)
  },
  {
    path: 'appDownload',
    loadChildren: () => import('./pages/share/share.module').then( m => m.SharePageModule)
  },
  {
    path: 'settlement',
    loadChildren: () => import('./pages/settlement/settlement.module').then( m => m.SettlementPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'proprietorybusiness',
    loadChildren: () => import('./pages/proprietorybusiness/proprietorybusiness.module').then( m => m.ProprietorybusinessPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./pages/privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'termcondition',
    loadChildren: () => import('./pages/termcondition/termcondition.module').then( m => m.TermconditionPageModule)
  },
  {
    path: 'helpsupport',
    loadChildren: () => import('./pages/helpsupport/helpsupport.module').then( m => m.HelpsupportPageModule)
  },
  {
    path: 'xcibilcampaigns',
    loadChildren: () => import('./homepages/xcibilcampaign/xcibilcampaign.module').then( m => m.XcibilcampaignPageModule)
  },
  {
    path: 'counter',
    loadChildren: () => import('./homepages/counter/counter.module').then( m => m.CounterPageModule)
  },
  {
    path: 'features',
    loadChildren: () => import('./homepages/features/features.module').then( m => m.FeaturesPageModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./homepages/career/career.module').then( m => m.CareerPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./homepages/sitemap/sitemap.module').then( m => m.SitemapPageModule)
  },
  
  {
    path: 'home',
    component: ApiLandingComponent
  },
  {
    path: 'api-login',
    component: ApiLoginComponent
  },
  {
    path:'all-api-page',
    component: AllApiPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'password-reset/:email',
    component: PassResetComponent
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    canActivate: [ErrorAccessGuard]
  },
  {
    path: 'api',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'dashboard',
      //   component: DashboardNewComponent,
      //   canActivate: [AuthGuard],

      // },
      {
        path: 'banking-api',
        component: BankingApiNewComponent
      },
      {
        path: 'pan-api/credentials-and-billing',
        component: CredenAndBillComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'banking-api/credentials-and-billing',
        component: CredenAndBillComponent
      },
      {
        path: 'xcibil-report/credentials-and-billing',
        component: CredenAndBillComponent
      },
      {
        path: 'gst-api/credentials-and-billing',
        component: CredenAndBillComponent
      },
      {
        path: 'pan-api',
        component: PanApiNewComponent
      },
      {
        path: 'service-list',
        component: ServiceListComponent
      },
      {
        path: 'xcibil-report',
        component: XcibilReportNewComponent
      },
      {
        path: 'sms-dashboard',
        component: SmsDashboardNewComponent
      },
      {
        path: 'invoice',
        component: InvoiceApiNewComponent
      },
      {
        path: 'invoice/view-invoice/:id',
        component: ViewInvoiceComponent
      },
      {
        path: 'queries',
        component: QueriesComponent
      },
      {
        path: 'queries/detail/:id',
        component: QueryDetailComponent
      },
      {
        path: 'pan-api/search-pan',
        component: SearchPanComponent
      },
      {
        path: 'banking-api/fetch-equifax',
        component: SearchEquifaxComponent
      },
      {
        path: 'xcibil-report/search-xcibil',
        component: SearchXcibilComponent
      },
      {
        path: 'gst-api/search-gst',
        component: SearchGstComponent
      },
      {
        path: 'gst-api/search-gst-filing',
        component: SearchGstFilingComponent
      },
      {
        path: 'gst-api/gst-auth',
        component: GstAuthComponent
      },
      {
        path: 'xcibil-report/search-xcibil/view-xcibil-report',
        component: XcibilReportComponent,
        canActivate: [XcibilReportGuard]
      },
      {
        path: 'gst-api',
        component: GstDetailComponent
      },
      {
        path: 'gst-api/apis',
        component: GstApiComponent
      }

      
    ]   
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy,}],
  exports: [RouterModule],

})
export class AppRoutingModule { 
  
}
