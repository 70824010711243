  import { Component, OnInit } from '@angular/core';
  import { ActivatedRoute, Router, RouterModule } from '@angular/router';
  import { SidebarNewComponent } from '../sidebar-new/sidebar-new.component';
  import { FormsModule } from '@angular/forms';
  SidebarNewComponent
  RouterModule
  FormsModule
  @Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
  })


  export class AdminLayoutComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) { }

    shouldDisplayAlert: boolean = false; 

    ngOnInit() {
    }

  }
