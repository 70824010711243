import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiLoginModalThreeComponent } from '../api-login-modal-three/api-login-modal-three.component';
import { ApiLoginService } from 'src/app/api-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-api-login-modal-two',
  templateUrl: './api-login-modal-two.component.html',
  styleUrls: ['./api-login-modal-two.component.scss'],
})
export class ApiLoginModalTwoComponent implements OnInit {
  @Input() eemail: any;
  invalidOtp: boolean = false;
  errors: any;
  data = { otp: '', userId: '' };
  isLoading: boolean = false;
  isNotValid: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private apiLoginService: ApiLoginService
  ) {}

  ngOnInit() {
    this.data.userId = this.eemail;
  }


  openModal3() {
    this.activeModal.close();
    const modalRef = this.modalService.open(ApiLoginModalThreeComponent);
    modalRef.componentInstance.name = 'World';
  }

  clearErrorMessage() {
    this.invalidOtp = false;
  }

  submitOtp() {
    this.invalidOtp = false;

    if (!this.data) {
      Swal.fire('Info', 'Internal Server Error: Data is null or undefined', 'info');
      return;
    }

    if (!this.data.otp || this.data.otp.length !== 6 || isNaN(+this.data.otp)) {
      Swal.fire('Info', 'Please enter a valid 6-digit OTP sent to your email', 'info');
      this.invalidOtp = true;
      return;
    }

    this.isLoading = true;

    this.apiLoginService.verifyOtp(this.data).subscribe(
      (response) => {
        this.isLoading = false;
        this.activeModal.close();
        const modalRef = this.modalService.open(ApiLoginModalThreeComponent);
        modalRef.componentInstance.email = this.data.userId;
      },
      (error) => {
        this.apiLoginService.handleError(error);
        this.isLoading = false;
      }
    );
  }

  onOtpChange(): void {}
}
