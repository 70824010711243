import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { ProjParams } from 'src/app/config';
import { DataService } from 'src/app/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar-profile-modal',
  templateUrl: './navbar-profile-modal.component.html',
  styleUrls: ['./navbar-profile-modal.component.scss'],
})
export class NavbarProfileModalComponent implements OnInit {
  responseData: any;
  clientDetails: any = {};
  clientToken = localStorage.getItem('newUserAuthKey');




  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };
  clientDetail: any;
  

  constructor(private activeModal:NgbActiveModal, private dataService: DataService, private router: Router, private http: HttpClient, private clientdetailsservice: ClientDetailsServiceService) { }

  ngOnInit() {
    this.clientdetailsservice.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );
  }

    //close modal icon logic
    closeModal() {
      this.activeModal.close('Modal closed');
    }
    

}
