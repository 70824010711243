import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-api-landing',
  templateUrl: './api-landing.component.html',
  styleUrls: ['./api-landing.component.scss'],
})
export class ApiLandingComponent implements OnInit {
  currentIndex: number = 0;
  showMyContainer: boolean = false;
  showDiv = {
    previous : false,
    current : false,
    next : false
  }
  constructor(private router: Router, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('XCIBIL - Home')
  }

  next() {
    if (this.currentIndex < 2) {
      this.currentIndex++;
    }
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }

  }

  signIn(){
    this.router.navigate((['/api-login']))
  }

  navigateToNewApp(){
    this.router.navigate((['/home']))
  }
}
