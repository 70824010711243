import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServerStatusService {
  server: boolean = true
  failedUrl: any;
  constructor() { }

  setServerRunning() {
    localStorage.setItem('server', 'running')
    setTimeout(() => {
      localStorage.removeItem('failedUrl')
    }, 3000);
  }

  setServerDown() {
   localStorage.setItem('server', 'down')
  }

}
