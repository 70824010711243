import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterNewComponent} from './footer-new.component';

@NgModule({
    imports: [ RouterModule, CommonModule],
    declarations: [ FooterNewComponent],
    exports: [FooterNewComponent]
})

export class FooterNewModule {}
 