import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared.service';
import { ProjParams } from 'src/app/config';
import { Title } from '@angular/platform-browser';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gst-api',
  templateUrl: './gst-api.component.html',
  styleUrls: ['./gst-api.component.scss']
})
export class GstApiComponent implements OnInit {
  financialYears: string[] = [];
  clientId: any;
  secretKey: any;
  clientIduat: any;
  secretKeyuat: any;
  clientDetail: any;
  selectedTab: string = 'production';
  

  constructor(private communicationService: SharedService, private http: HttpClient, private titleService: Title, private flagService: DashboardFlagService,private clientDetailService: ClientDetailsServiceService, private location: Location) { 
    this.generateFinancialYears();
  }

  ngOnInit(): void {
    this.titleService.setTitle('XCIBIL - GST API PORTAL')
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setDashboardFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(true);
    this.flagService.sendMessage('Load');
    this.clientDetailService.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
          this.searchGst.prod.clientId = this.clientDetail.client_id;
          this.searchGstFiling.prod.clientId = this.clientDetail.client_id;
          this.getGstAuth.clientId = this.clientDetail.client_id;
          this.verifyAuth.clientId = this.clientDetail.client_id;
          this.getGstr1b2bCustomers.clientId = this.clientDetail.client_id;
          this.getGstr2bAllSuppliers.clientId = this.clientDetail.client_id;

          this.searchGst.prod.secretKey = this.clientDetail.secret_key;
          this.searchGstFiling.prod.secretKey = this.clientDetail.secret_key;
          this.getGstAuth.secretKey = this.clientDetail.secret_key;
          this.verifyAuth.secretKey = this.clientDetail.secret_key;
          this.getGstr1b2bCustomers.secretKey = this.clientDetail.secret_key;
          this.getGstr2bAllSuppliers.secretKey = this.clientDetail.secret_key;

          this.secretKey = this.clientDetail.secret_key;
          this.clientIduat = this.clientDetail.sandbox_client_id;
          this.secretKeyuat = this.clientDetail.sandbox_secret_key;
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );

    this.publicAPIs.apiMethods = this.publicapisPROD.apiMethods;
    this.authAPIs.apiMethods = this.authapisPROD.apiMethods;
    this.gstr1APIs.apiMethods = this.gstr1apisPROD.apiMethods;
    this.gstr2bAPIs.apiMethods = this.gstr2bapisPROD.apiMethods;

  }

  generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let startYear = 2017;
    
    // If it's after March, add the next financial year 
    if (currentMonth > 2) {
      startYear = currentYear;
    } else {
      startYear = currentYear - 1;
    }

    // Generate financial years from 2017-18 up to the current year
    for (let year = 2017; year <= startYear; year++) {
      this.financialYears.push(`${year}-${(year + 1).toString().slice(-2)}`);
    }
    console.log(this.financialYears)
  }


  selectTab(tab: string): void {
    this.selectedTab = tab;


    if (this.selectedTab == 'sandbox') {
      this.publicAPIs.apiMethods = this.publicapisUAT.apiMethods;
      this.authAPIs.apiMethods = this.authapisUAT.apiMethods;
      this.gstr1APIs.apiMethods = this.gstr1apisUAT.apiMethods;
      this.gstr2bAPIs.apiMethods = this.gstr2bapisUAT.apiMethods;
      this.searchGst.prod.clientId = this.clientDetail.sandbox_client_id;
      this.searchGstFiling.prod.clientId = this.clientDetail.sandbox_client_id;
      this.getGstAuth.clientId = this.clientDetail.sandbox_client_id;
      this.verifyAuth.clientId = this.clientDetail.sandbox_client_id;
      this.getGstr1b2bCustomers.clientId = this.clientDetail.sandbox_client_id;
      this.getGstr2bAllSuppliers.clientId = this.clientDetail.sandbox_client_id;

      this.searchGst.prod.secretKey = this.clientDetail.sandbox_secret_key;
      this.searchGstFiling.prod.secretKey = this.clientDetail.sandbox_secret_key;
      this.getGstAuth.secretKey = this.clientDetail.sandbox_secret_key;
      this.verifyAuth.secretKey = this.clientDetail.sandbox_secret_key;
      this.getGstr1b2bCustomers.secretKey = this.clientDetail.sandbox_secret_key;
      this.getGstr2bAllSuppliers.secretKey = this.clientDetail.sandbox_secret_key;

      // empty response and messaged
      this.searchGst.prod.detail =  [];
      this.searchGstFiling.prod.detail = [];
      this.getGstAuth.detail = [];
      this.verifyAuth.detail = [];
      this.getGstr1b2bCustomers.detail = [];
      this.getGstr2bAllSuppliers.detail = [];

      this.searchGst.prod.response =  '';
      this.searchGstFiling.prod.response =  '';
      this.getGstAuth.response =  '';
      this.verifyAuth.response =  '';
      this.getGstr1b2bCustomers.response =  '';
      this.getGstr2bAllSuppliers.response =  '';

      this.searchGst.prod.detailMessage =  '';
      this.searchGstFiling.prod.detailMessage =  '';
      this.getGstAuth.detailMessage =  '';
      this.verifyAuth.detailMessage =  '';
      this.getGstr1b2bCustomers.detailMessage =  '';
      this.getGstr2bAllSuppliers.detailMessage =  '';
      this.getGstAuth.showOtpInput = false;
      this.verifyAuth.showOtpInput = false;

      


    } else {
      this.publicAPIs.apiMethods = this.publicapisPROD.apiMethods;
      this.authAPIs.apiMethods = this.authapisPROD.apiMethods;
      this.gstr1APIs.apiMethods = this.gstr1apisPROD.apiMethods;
      this.gstr2bAPIs.apiMethods = this.gstr2bapisPROD.apiMethods;

      this.searchGst.prod.clientId = this.clientDetail.client_id;
      this.searchGstFiling.prod.clientId = this.clientDetail.client_id;
      this.getGstAuth.clientId = this.clientDetail.client_id;
      this.verifyAuth.clientId = this.clientDetail.client_id;
      this.getGstr1b2bCustomers.clientId = this.clientDetail.client_id;
      this.getGstr2bAllSuppliers.clientId = this.clientDetail.client_id;
      this.getGstAuth.showOtpInput = false;
      this.verifyAuth.showOtpInput = false;


      this.searchGst.prod.secretKey = this.clientDetail.secret_key;
      this.searchGstFiling.prod.secretKey = this.clientDetail.secret_key;
      this.getGstAuth.secretKey = this.clientDetail.secret_key;
      this.verifyAuth.secretKey = this.clientDetail.secret_key;
      this.getGstr1b2bCustomers.secretKey = this.clientDetail.secret_key;
      this.getGstr2bAllSuppliers.secretKey = this.clientDetail.secret_key;

      //empty responses and messages

      this.searchGst.prod.detail =  [];
      this.searchGstFiling.prod.detail = [];
      this.getGstAuth.detail = [];
      this.verifyAuth.detail = [];
      this.getGstr1b2bCustomers.detail = [];
      this.getGstr2bAllSuppliers.detail = [];

      this.searchGst.prod.response = '';
      this.searchGstFiling.prod.response = '';
      this.getGstAuth.response = '';
      this.verifyAuth.response = '';
      this.getGstr1b2bCustomers.response = '';
      this.getGstr2bAllSuppliers.response = '';
 
      this.searchGst.prod.detailMessage =  '';
      this.searchGstFiling.prod.detailMessage =  '';
      this.getGstAuth.detailMessage =  '';
      this.verifyAuth.detailMessage =  '';
      this.getGstr1b2bCustomers.detailMessage =  '';
      this.getGstr2bAllSuppliers.detailMessage =  '';
    }
  }

  isFormVisible: boolean = false;
  taxpayerId = '';
  name = '';
  isLoading : boolean = false;

  toggleForm(api: any) {
    api.isFormVisible = !api.isFormVisible;
  }


  publicAPIs = {
    apiMethods: []
  };


  publicapisPROD = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/searchGst/',
        description: 'Search GST Detail',
        isFormVisible: false,
        notes: 'This API will be used to search taxpayer details',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'GST not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/searchGstFiling/',
        description: 'Search GST Filing Detail',
        isFormVisible: false,
        notes: 'This API will be used to search taxpayer GST Filings.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'GST not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '' , headers: ''},
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
    ]
  };

  publicapisUAT = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/searchGstUAT/',
        description: 'Search GST Detail',
        isFormVisible: false,
        notes: 'This API will be used to search taxpayer details',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'GST not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/searchFilingGstUAT/',
        description: 'Search GST Filing Detail',
        isFormVisible: false,
        notes: 'This API will be used to search taxpayer GST Filings.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'GST not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '' , headers: ''},
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
    ]
  };

  authAPIs = {
    apiMethods: []
  };

  authapisPROD = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/gstAuth/',
        description: 'Request for OTP',
        isFormVisible: false,
        notes: 'This API will be used to authorize taxpayer.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Route not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/otpVerification/',
        description: 'OTP Verification',
        isFormVisible: false,
        notes: 'This API will be used for OTP verification.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key or Invalid OTP', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      }      
    ]
  };

  authapisUAT = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/gstAuthUAT/',
        description: 'Request for OTP',
        isFormVisible: false,
        notes: 'This API will be used to authorize taxpayer.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Route not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/otpUATVerification/',
        description: 'OTP Verification',
        isFormVisible: false,
        notes: 'This API will be used for OTP verification.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      }      
    ]
  };

  gstr1APIs = {
    apiMethods: []
  };

  gstr1apisPROD = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/gstr1/b2b/',
        description: 'Get Customers/Invoices',
        isFormVisible: false,
        notes: 'Getting all B2B Customers/Invoices for a return period.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
     
    ]
  };

  gstr1apisUAT = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/UAT/gstr1/b2b/',
        description: 'Get Customers/Invoices',
        isFormVisible: false,
        notes: 'Getting all B2B Customers/Invoices for a return period.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }
        ]
      },
     
    ]
  };

  gstr2bAPIs = {
    apiMethods: []
  };

  gstr2bapisPROD= {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/gstr2b/All/',
        description: 'Get Suppliers/Invoices',
        isFormVisible: false,
        notes: 'Getting all Suppliers/Invoices for given GSTIN, Return Period.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Route not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }

        ]
      },
     
    ]
  };

  gstr2bapisUAT = {
    apiMethods: [
      {
        methodType: 'GET',
        endpoint: '/public/apiRoute/UAT/gstr2b/All/',
        description: 'Get Suppliers/Invoices',
        isFormVisible: false,
        notes: 'Getting all Suppliers/Invoices for given GSTIN, Return Period.',
        responseMessages: [
          { statusCode: 403, reason: 'Authorization failed,Invalid Client Id or Secret key', responseModel: '', headers: '' },
          { statusCode: 404, reason: 'Route not found', responseModel: '', headers: '' },
          { statusCode: 500, reason: 'Internal Server Error', responseModel: '', headers: '' },
          { statusCode: 503, reason: 'Service is Not Active ', responseModel: '', headers: '' }

        ]
      },
     
    ]
  };


  


  searchGst = {
    prod: {
      clientId: '',
      secretKey: '',
      panNumber: '',
      detail: [],
      detailMessage: '',
      isFieldEmpty: false,
      show: false,
      searched: false,
      response: '',
      showDetail: false,
    showLoader: false

    }
  };


  saveFormData() {
    const formData = {
      clientId: this.searchGst.prod.clientId,
      secretKey: this.searchGst.prod.secretKey,
      panNumber: this.searchGst.prod.panNumber
    };
    this.communicationService.setFormData(formData);
  }

  deleteFormData() {
    const formData = {
      clientId: '',
      secretKey: '',
      panNumber: ''
    };
    this.communicationService.setFormData(formData);
  }


  searchGstDetail() {
    if (!this.searchGst.prod.clientId || !this.searchGst.prod.secretKey || !this.searchGst.prod.panNumber) {
      this.searchGst.prod.isFieldEmpty = true;
      setTimeout(() => {
        this.searchGst.prod.isFieldEmpty = false;
      }, 3000);
      return; 
    }

    if (!this.validateGstNumber(this.searchGst.prod.panNumber)) {
      this.searchGst.prod.detailMessage = 'Invalid GST Number format';
      return;
    }
  
    let url;
    if (this.selectedTab == 'production') {
      url = ProjParams.apiurlNew + `public/apiRoute/searchGst/?gstno=${this.searchGst.prod.panNumber}`;
    } else{
      url = ProjParams.apiurlNew + `public/apiRoute/searchGstUAT/?gstno=${this.searchGst.prod.panNumber}`;
    }

    this.searchGst.prod.showLoader = true;

    const headers = new HttpHeaders({
      'clientId': this.searchGst.prod.clientId,
      'secretKey': this.searchGst.prod.secretKey
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
    this.searchGst.prod.showLoader = false;

        this.searchGst.prod.response = response
        this.searchGst.prod.show = true;  

        if(response.message ===  'Service is Not Active ')
          {
          this.searchGst.prod.detailMessage = response.message;
          }
        else if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.searchGst.prod.detailMessage = response.message;
          }else {
            this.communicationService.sendApiHitSignal();

          this.searchGst.prod.showDetail = true;
          this.searchGst.prod.detail = response
          this.searchGst.prod.detailMessage = response.message;
        }
        this.searchGst.prod.searched = true;
      },
      (error) => {
        this.searchGst.prod.response = error;
        this.searchGst.prod.showLoader = false;
        if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
          Swal.fire({
            title: 'Information',
            text: error.error.message || 'An unexpected error occurred.',
            icon: 'info',
            confirmButtonText: 'OK'
          });
          return;
        }
        this.searchGst.prod.show = true; 
        this.isLoading = false;
        this.searchGst.prod.detailMessage = error.error.message;

        console.error(error);
          this.searchGst.prod.detail = error.error.data
        
          this.searchGst.prod.detailMessage = error.error.message;
          this.communicationService.sendApiHitSignal();
        } 
    );
  }





  searchGstFiling = {
    prod: {
      clientId: '',
      secretKey: '',
      panNumber: '',
      detail: [],
      detailMessage: '',
      financialYear : 'Select a financial year',
      isFieldEmpty: false,
      show: false,
      response: '',
      searched: false,
      showDetail: false,
      gstNumber: '',
    showLoader: false

    }
  };





  searchGstFilingDetail() {
    
    if (!this.searchGstFiling.prod.clientId || !this.searchGstFiling.prod.secretKey || !this.searchGstFiling.prod.gstNumber || this.searchGstFiling.prod.financialYear === 'Select a financial year') {
      this.searchGstFiling.prod.isFieldEmpty = true;
      setTimeout(() => {
        this.searchGstFiling.prod.isFieldEmpty = false;
      }, 3000);
      return; 
    }
    
    if (!this.validateGstNumber(this.searchGstFiling.prod.gstNumber)) {
      this.searchGstFiling.prod.detailMessage = 'Invalid GST Number format';
      return;
    }


    this.isLoading = true;
    let url;
    if (this.selectedTab == 'production') {
      url = ProjParams.apiurlNew +`public/apiRoute/searchGstFiling/?gstno=${this.searchGstFiling.prod.gstNumber}&fy=${this.searchGstFiling.prod.financialYear}`;
      
    } else {
      url = ProjParams.apiurlNew+`public/apiRoute/searchFilingGstUAT/?gstno=${this.searchGstFiling.prod.gstNumber}&fy=${this.searchGstFiling.prod.financialYear}`;
    }

    this.searchGstFiling.prod.showLoader = true;


  
    const headers = new HttpHeaders({
      'clientid': this.searchGstFiling.prod.clientId,
      'secretkey': this.searchGstFiling.prod.secretKey
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
    this.searchGstFiling.prod.showLoader = false;
        
        this.searchGstFiling.prod.response = response
        this.searchGstFiling.prod.show = true
   

        if(response.message ===  'Service is Not Active ')
          {
          this.searchGstFiling.prod.detailMessage = response.message;
          }
        else if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.searchGstFiling.prod.detailMessage = response.message;
          }else {
            this.communicationService.sendApiHitSignal();

          this.searchGstFiling.prod.showDetail = true;
          this.searchGstFiling.prod.detail = response.data
          this.searchGstFiling.prod.detailMessage = response.message;
        }
      },
      (error) => {
        this.searchGstFiling.prod.response = error;
    this.searchGstFiling.prod.showLoader = false;
    if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
      Swal.fire({
        title: 'Information',
        text: error.error.message || 'An unexpected error occurred.',
        icon: 'info',
        confirmButtonText: 'OK'
      });
      return;
    }
        this.searchGstFiling.prod.show = true; 
        this.isLoading = false;
        this.searchGstFiling.prod.detailMessage = error.error.message;


        console.error(error);
          this.searchGstFiling.prod.detail = error.error.data
        
          this.searchGstFiling.prod.detailMessage = error.error.message;
          this.communicationService.sendApiHitSignal();
      }
    );
  }



  getGstAuth = {
      clientId: '',
      secretKey: '',
      panNumber: '',
      panName: '',
      detail: [],
      detailMessage: '',
      isFieldEmpty: false,
      show: false,
      searched: false,
      response: '',
      stateCode: '',
      ipAddress: '',
      showDetail: false,
      showOtpInput: false,
      txnNo: '',
      otp: '',
      showLoader: false
  };


  getGstAuthDetail() {
    if (!this.getGstAuth.clientId || !this.getGstAuth.secretKey || !this.getGstAuth.panName || !this.getGstAuth.ipAddress || !this.getGstAuth.stateCode) {
      this.getGstAuth.isFieldEmpty = true;
      setTimeout(() => {
        this.getGstAuth.isFieldEmpty = false;
      }, 3000);
      return; 
    }
    
    this.isLoading = true;
    this.getGstAuth.detail = [];
    this.getGstAuth.detailMessage = '';
    this.getGstAuth.response = '';
      
    let url;
    if (this.selectedTab == 'production') {
      url = ProjParams.apiurlNew + 'public/apiRoute/gstAuth'    
    } else{
      url = ProjParams.apiurlNew + 'public/apiRoute/gstAuthUAT'    
    }

  
    const headers = new HttpHeaders({
      'clientId': this.getGstAuth.clientId,
      'secretKey': this.getGstAuth.secretKey,
      'gstUsername': this.getGstAuth.panName,//removed underscore
      'ipAddress': this.getGstAuth.ipAddress,//removed underscore
      'stateCd': this.getGstAuth.stateCode//removed underscore
    });
    this.getGstAuth.showLoader = true;

    this.http.get(url, { headers }).subscribe(
      (response: any) => {
    this.getGstAuth.showLoader = false;

        this.isLoading = false;
        this.getGstAuth.response = response;
    
        if (response.status === 'Failed' && response.statusCode === 404 && response.message === 'User not found.') {
          this.getGstAuth.detailMessage = response.message;
        } else {
          this.getGstAuth.detailMessage = response.message;
          if (response.message === "OTP Sent to your Mobile No.") {
            this.getGstAuth.showOtpInput = true;
          this.getGstAuth.detail = response.data.data
          this.getGstAuth.txnNo = response.data.header.txn;
          }
        }
        this.getGstAuth.searched = true;
      },
      (error) => {
        this.getGstAuth.response = error;

    this.getGstAuth.showLoader = false;
    if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
      Swal.fire({
        title: 'Information',
        text: error.error.message || 'An unexpected error occurred.',
        icon: 'info',
        confirmButtonText: 'OK'
      });
      return;
    }
        this.getGstAuth.detailMessage = error.error.message;
        this.isLoading = false;
        console.error(error);
      }
    );
  }

  verifyAuth = {
    clientId: '',
    secretKey: '',
    panNumber: '',
    panName: '',
    detail: [],
    detailMessage: '',
    isFieldEmpty: false,
    show: false,
    searched: false,
    response: '',
    stateCode: '',
    ipAddress: '',
    showDetail: false,
    showOtpInput: false,
    txnNo: '',
    otp: '',
    showLoader: false,

};

  verifyAuthDetail(){

    if (!this.verifyAuth.clientId || !this.verifyAuth.secretKey || !this.verifyAuth.panName || !this.verifyAuth.ipAddress || !this.verifyAuth.stateCode || !this.verifyAuth.txnNo) {
      this.verifyAuth.isFieldEmpty = true;
      setTimeout(() => {
        this.verifyAuth.isFieldEmpty = false;
      }, 3000);
      return; 
    }

    this.verifyAuth.detail = [];
    this.verifyAuth.detailMessage = '';
    this.verifyAuth.response = '';

          
    let url;
    if (this.selectedTab == 'production') {
      url = ProjParams.apiurlNew + `public/apiRoute/otpVerification/?otp=${this.verifyAuth.otp}`;
    } else{
      url = ProjParams.apiurlNew + `public/apiRoute/otpUATVerification/?otp=${this.verifyAuth.otp}`;
    }



  this.verifyAuth.showLoader = true;
    const headers = new HttpHeaders({
      'clientId': this.verifyAuth.clientId,
      'secretKey': this.verifyAuth.secretKey,
      'gstUsername': this.verifyAuth.panName,
      'ipAddress': this.verifyAuth.ipAddress,
      'stateCd': this.verifyAuth.stateCode,
      'txnNo': this.verifyAuth.txnNo
    });



  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
  this.verifyAuth.showLoader = false;

        this.isLoading = false;
        this.verifyAuth.response = response;
        console.log(this.verifyAuth.response)
        if (response.status === 'Failed' && response.statusCode === 403 && response.message === 'Invalid Otp. Please Try Again!') {
        } else {
          if (response.message === "OTP Verification Successful") {
            this.verifyAuth.detail = response.data.data
            this.verifyAuth.showOtpInput = true;
          }
        }
      },
      (error) => {
  this.verifyAuth.showLoader = false;
  this.verifyAuth.response = error;
  if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
    Swal.fire({
      title: 'Information',
      text: error.error.message || 'An unexpected error occurred.',
      icon: 'info',
      confirmButtonText: 'OK'
    });
    return;
  }
        if (error.error.status === 'Failed' && error.error.statusCode === 403 && error.error.message === 'Invalid Otp. Please Try Again!') {
          this.verifyAuth.detailMessage = error.error.message;
        }
        this.verifyAuth.detailMessage = error.error.message;
        
        this.isLoading = false;
        console.error(error);
      }
    );
  }




  getGstr1b2bCustomers = {
    clientId: '',
    secretKey: '',
    panNumber: '',
    panName: '',
    detail: [],
    detailMessage: '',
    isFieldEmpty: false,
    show: false,
    searched: false,
    gstNumber: '',
    response: '',
    stateCode: '',
    ipAddress: '',
    showDetail: false,
    showOtpInput: false,
    txnNo: '',
    otp: '',
    retPeriod: '',
    showLoader: false
};



getGstr1b2bCustomersDetail(){

  if (!this.getGstr1b2bCustomers.clientId || !this.getGstr1b2bCustomers.secretKey || !this.getGstr1b2bCustomers.panName || !this.getGstr1b2bCustomers.ipAddress || !this.getGstr1b2bCustomers.stateCode || !this.getGstr1b2bCustomers.txnNo || !this.getGstr1b2bCustomers.retPeriod || !this.getGstr1b2bCustomers.gstNumber) {
    this.getGstr1b2bCustomers.isFieldEmpty = true;
    setTimeout(() => {
      this.getGstr1b2bCustomers.isFieldEmpty = false;
    }, 3000);
    return; 
  }


  if (!this.validateGstNumber(this.getGstr1b2bCustomers.gstNumber)) {
    this.getGstr1b2bCustomers.detailMessage = 'Invalid GST Number format';
    return;
  }


            
  let url;
  if (this.selectedTab == 'production') {
    url = ProjParams.apiurlNew + `public/apiRoute/gstr1/b2b/?gstin=${this.getGstr1b2bCustomers.gstNumber}&retperiod=${this.getGstr1b2bCustomers.retPeriod}`;
  } else{
    url = ProjParams.apiurlNew + `public/apiRoute/UAT/gstr1/b2b/?gstin=${this.getGstr1b2bCustomers.gstNumber}&retperiod=${this.getGstr1b2bCustomers.retPeriod}`;
  }


  this.getGstr1b2bCustomers.response = '';
  this.getGstr1b2bCustomers.detail = [];
  this.getGstr1b2bCustomers.detailMessage = '';


  const headers = new HttpHeaders({
    'clientId': this.getGstr1b2bCustomers.clientId,
    'secretKey': this.getGstr1b2bCustomers.secretKey,
    'gstUsername': this.getGstr1b2bCustomers.panName,
    'ipAddress': this.getGstr1b2bCustomers.ipAddress,
    'stateCd': this.getGstr1b2bCustomers.stateCode,
    'txnNo': this.getGstr1b2bCustomers.txnNo
  });

  this.getGstr1b2bCustomers.showLoader = true;

  this.http.get(url, { headers }).subscribe(
    (response: any) => {
      this.getGstr1b2bCustomers.showLoader = false;
      this.isLoading = false;
      this.getGstr1b2bCustomers.response = response;
      if (response.status === 'Failed' && response.statusCode === 403 && response.message === 'Contact Support to recharge the Hits') {
      } else {
        if (response.message === "GST Customers List" && response.data && response.data.data) {
          // Handle the successful case where data exists
          this.getGstr1b2bCustomers.detail = response.data.data;
        } else if (response.message === "GST Customers List" && (!response.data || !response.data.data)) {
          // Handle the case where the data is missing
          this.getGstr1b2bCustomers.detailMessage = "No records found";
          console.log('No records found');
        }
      }
    },
    (error) => {
  this.getGstr1b2bCustomers.response = error;

      this.getGstr1b2bCustomers.showLoader = false;
      if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
        Swal.fire({
          title: 'Information',
          text: error.error.message || 'An unexpected error occurred.',
          icon: 'info',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (error.error.status === 'Failed' && error.error.statusCode === 403 && error.error.message === 'Contact Support to recharge the Hits') {
        this.getGstr1b2bCustomers.detailMessage = error.error.message;
      }
      this.getGstr1b2bCustomers.detailMessage = error.error.message;

      this.isLoading = false;
      console.error(error);
    }
  );
}




getGstr2bAllSuppliers = {
  clientId: '',
  secretKey: '',
  panNumber: '',
  panName: '',
  detail: [],
  detailMessage: '',
  isFieldEmpty: false,
  show: false,
  searched: false,
  response: '',
  stateCode: '',
  ipAddress: '',
  showDetail: false,
  showOtpInput: false,
  txnNo: '',
  otp: '',
  retPeriod: '',
  gstNumber: '',
  showLoader: false

};



getGstr2AllSuppliersDetail(){

  if (!this.getGstr2bAllSuppliers.clientId || !this.getGstr2bAllSuppliers.secretKey || !this.getGstr2bAllSuppliers.panName || !this.getGstr2bAllSuppliers.ipAddress || !this.getGstr2bAllSuppliers.stateCode || !this.getGstr2bAllSuppliers.txnNo || !this.getGstr2bAllSuppliers.retPeriod || !this.getGstr2bAllSuppliers.gstNumber) {
    this.getGstr2bAllSuppliers.isFieldEmpty = true;
    setTimeout(() => {
      this.getGstr2bAllSuppliers.isFieldEmpty = false;
    }, 3000);
    return; 
  }


  
  if (!this.validateGstNumber(this.getGstr2bAllSuppliers.gstNumber)) {
    this.getGstr2bAllSuppliers.detailMessage= 'Invalid GST Number format';
    return;
  }


  



  let url;
  if (this.selectedTab == 'production') {
    url = ProjParams.apiurlNew + `public/apiRoute/gstr2b/All/?gstin=${this.getGstr2bAllSuppliers.gstNumber}&retperiod=${this.getGstr2bAllSuppliers.retPeriod}`;
  } else{
    url = ProjParams.apiurlNew + `public/apiRoute/UAT/gstr2b/All/?gstin=${this.getGstr2bAllSuppliers.gstNumber}&retperiod=${this.getGstr2bAllSuppliers.retPeriod}`;
  }


  this.getGstr2bAllSuppliers.showLoader = true;




  const headers = new HttpHeaders({
    'clientId': this.getGstr2bAllSuppliers.clientId,
    'secretKey': this.getGstr2bAllSuppliers.secretKey,
    'gstUsername': this.getGstr2bAllSuppliers.panName,
    'ipAddress': this.getGstr2bAllSuppliers.ipAddress,
    'stateCd': this.getGstr2bAllSuppliers.stateCode,
    'txnNo': this.getGstr2bAllSuppliers.txnNo
  });

  this.getGstr2bAllSuppliers.response = '';
  this.getGstr2bAllSuppliers.detail = [];
  this.getGstr2bAllSuppliers.detailMessage = '';





  this.http.get(url, { headers }).subscribe(
    (response: any) => {
      this.getGstr2bAllSuppliers.showLoader = false;
      this.isLoading = false;
      this.getGstr2bAllSuppliers.response = response;
      if (response.status === 'Failed' && response.statusCode === 403 && response.message === 'Contact Support to recharge the Hits') {
      } else {
        if (response.message === "GST Suppliers List" && response.data && response.data.status_cd === "1") {
          // Handle the successful case where data exists
          this.getGstr2bAllSuppliers.detail = response.data.data || [];
        } else if (response.message === "GST Suppliers List" && (!response.data || response.data.status_cd === "0")) {
          // Handle the case where the data is missing or status_cd is "0"
          this.getGstr2bAllSuppliers.detailMessage = "No records found";
          console.log('No records found');
        } 
      }
    },
    (error) => {
  this.getGstr2bAllSuppliers.response = error;

      this.getGstr2bAllSuppliers.showLoader = false;
      if (error.error.message === 'User is Not Active. Please contact XCIBIL admin') {
        Swal.fire({
          title: 'Information',
          text: error.error.message || 'An unexpected error occurred.',
          icon: 'info',
          confirmButtonText: 'OK'
        });
        return;
      }

      if (error.error.status === 'Failed' && error.error.statusCode === 403 && error.error.message === 'Contact Support to recharge the Hits') {
        this.getGstr2bAllSuppliers.detailMessage = error.error.message;
      }
      this.getGstr2bAllSuppliers.detailMessage = error.error.message;

      this.isLoading = false;
      console.error(error);


     
    }
  );
}
  

isDetailEmpty(detail: any): boolean {
  return !detail || (Array.isArray(detail) && detail.length === 0) || detail === '';
}

validateGstNumber(gstno: string): boolean {
  // GST number format: 15 alphanumeric characters
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1-9][Z][A-Z0-9]{1}$/;
  return gstRegex.test(gstno);
}


formatRetPeriod(retPeriod: string): string {
  const [year, month] = retPeriod.split('-');
  return `${month}${year}`;
}

copyToClipboard(txnNo: string): void {
  const textarea = document.createElement('textarea');
  textarea.value = txnNo;
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();
  try {
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Transaction number copied to clipboard:', txnNo);
    } else {
      console.error('Failed to copy the transaction number.');
    }
  } catch (err) {
    console.error('Error copying to clipboard:', err);
  }
  document.body.removeChild(textarea);
}



goBack(){
  this.location.back()
}

 
}


