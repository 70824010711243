import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { ProjParams } from 'src/app/config';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { SharedService } from 'src/app/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-gst',
  templateUrl: './search-gst.component.html',
  styleUrls: ['./search-gst.component.scss'],
})
export class SearchGstComponent implements OnInit {
  isLoading: boolean = false;
  clientId: string = '';
  secretKey: string = '';
  panNumber: string = '';
  clientIduat: string = '';
  secretKeyuat: string = '';
  panNumberuat: string = '';
  panDetail: any;
  panuatDetail: any;
  panDetailMessage: any;
  uatSearched: boolean = false;
  prodSearched: boolean = false;
  panuatDetailMessage: any;
  showDetail: boolean;
  show: boolean;
  mode: string;
  showUAT: boolean;
  clientDetail: any;

  constructor(private http: HttpClient, private router: Router, private flagService: DashboardFlagService, private communicationService: SharedService, private titleService: Title, private clientDetailService: ClientDetailsServiceService) { }

  ngOnInit() {
    this.titleService.setTitle('Search GST Detail')
    this.clientDetailService.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
          this.clientId = this.clientDetail.client_id;
          this.secretKey = this.clientDetail.secret_key;
          this.clientIduat = this.clientDetail.sandbox_client_id;
          this.secretKeyuat = this.clientDetail.sandbox_secret_key;
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );
  }
  response: any;



  searchPan() {
    if (!this.validateForm()) {
      return;
    }
  
    this.isLoading = true;
    const url = ProjParams.apiurlNew + `public/apiRoute/searchGst/?gstno=${this.panNumber}`;

  
    const headers = new HttpHeaders({
      'clientId': this.clientId,
      'secretKey': this.secretKey
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.response = response
        this.show = true
        this.mode = 'P'
  

        if(response.message ===  'Service is Not Active ')
          {
          this.panDetailMessage = response.message;
          }
        else if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.panDetailMessage = response.message;
          }else {
            this.communicationService.sendApiHitSignal();

          this.showDetail = true;
          this.panDetail = response.data
          this.panDetailMessage = response.message;
        }
        this.prodSearched = true;
      },
      (error) => {
        this.show = true; 
        this.isLoading = false;
        this.panDetailMessage = error.error.message;

        console.error(error);
        if (error.error.status === 'Failed' && error.error.statusCode === 404 && error.error.message === 'GST not found') {
          this.panDetail = error.error.data
        
          this.panDetailMessage = error.error.message;
          this.communicationService.sendApiHitSignal();


          
        } 
      }
    );
  }





  showDetailUAT: boolean = false;
  searchPanuat() {
    if (!this.validateFormuat()) {
      return;
    }
  
    this.isLoading = true;
    const url = ProjParams.apiurlNew + `public/apiRoute/searchGstUAT/?gstno=${this.panNumberuat}`;

  
    const headers = new HttpHeaders({
      'clientId': this.clientIduat,
      'secretKey': this.secretKeyuat
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        this.showUAT = true;
        this.mode = 'S'
        if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.panuatDetailMessage = response.message;
          }
          else if(response.message ===  'Service is Not Active ')
            {
            this.panuatDetailMessage = response.message;
            }
         else {
          this.communicationService.sendApiHitSignal();

          this.showDetailUAT = true
          this.panuatDetail = response.data

          this.panuatDetailMessage = response.message;

        }

        this.uatSearched = true;
      },
      (error) => {
        this.showUAT = true;
        this.isLoading = false;
        this.panuatDetailMessage = error.error.message;

        console.error(error);
        if (error.error.status === 'Failed' && error.error.statusCode === 404 && error.error.message === 'GST not found') {
          this.panuatDetail = error.error.data
       
          this.panuatDetailMessage = error.error.message;
          console.log('uat error res:', error.error.message)
          this.communicationService.sendApiHitSignal();


        }
      }
    );
  }

  prodSearchOn(){
    this.prodSearched = true;
    this.uatSearched =  false;

    this.panuatDetailMessage = '';
    this.clientIduat = '';
    this.secretKeyuat = ''
    this.panuatDetail = ''
  }

  uatSearchOn(){
    this.uatSearched = true
    this.prodSearched = false;

    this.panDetailMessage = '';
    this.clientId = '';
    this.secretKey = '';
    this.panDetail = ''
  }

  
  hide(){
    this.show = false;
    // this.clientId = '';
    // this.secretKey = '';
    this.panDetail = '';
    this.panDetailMessage = '';
    this.panNumber = '';
  }

  hideUAT(){
    this.showUAT = false;
    // this.clientIduat = '';
    // this.secretKeyuat = '';
    this.panuatDetail = '';
    this.panuatDetailMessage = '';
    this.panNumberuat = ''
  }

  goBack(){
    this.router.navigate(['/api/gst-api']);
  }

   
  validateForm(): boolean {
    let isValid = true;

    isValid = this.validateField('clientId') && isValid;
    isValid = this.validateField('secretKey') && isValid;
    isValid = this.validateField('panNumber') && isValid;

    return isValid;
  }
  clientIdError: string;
  secretKeyError: string;
  panNumberError: string;


  validateField(field: string): boolean {
    let isValid = true;
    let value = '';
    let errorMessage = '';
    let isPAN = false;

    switch (field) {
      case 'clientId':
        value = this.clientId;
        errorMessage = 'Please enter a Client ID';
        this.clientIdError = '';
        break;
      case 'secretKey':
        value = this.secretKey;
        errorMessage = 'Please enter a Secret Key';
        this.secretKeyError = '';
        break;
      case 'panNumber':
        value = this.panNumber;
        if (!value) {
          errorMessage = 'Please enter the GST number';
          isValid = false; // Mark the field as invalid
        } else {
          errorMessage = 'Please enter a valid GST Number';
          isPAN = true; // Set isPAN to true for further validation
          this.panNumberError = ''; // Clear any previous error message
        }
        break;
    }

    const element = document.getElementById(field + 'Input') as HTMLInputElement;

    if (isPAN) {
      isValid = this.validatePAN(value);
    } else {
      isValid = !!value;
    }

    if (!isValid) {
      this.highlightField(element);
      this.setErrorMessage(field, errorMessage);
    } else {
      this.resetFieldStyle(element);
      this.setErrorMessage(field, '');
    }

    return isValid;
  }

  setErrorMessage(field: string, message: string) {
    switch (field) {
      case 'clientId':
        this.clientIdError = message;
        break;
      case 'secretKey':
        this.secretKeyError = message;
        break;
      case 'panNumber':
        this.panNumberError = message;
        break;
    }
  }

  clientIdErroruat: string;
  secretKeyErroruat: string;
  panNumberErroruat: string;
  validateFormuat(): boolean {
    let isValid = true;

    isValid = this.validateFielduat('clientIduat') && isValid;
    isValid = this.validateFielduat('secretKeyuat') && isValid;
    isValid = this.validateFielduat('panNumberuat') && isValid;

    return isValid;
  }

  validateFielduat(field: string): boolean {
    let isValid = true;
    let value = '';
    let errorMessageuat = '';
    let isPAN = false;
  
    switch (field) {
      case 'clientIduat':
        value = this.clientIduat;
        errorMessageuat = 'Please enter a Client ID';
        this.clientIdErroruat = '';
        break;
      case 'secretKeyuat':
        value = this.secretKeyuat;
        errorMessageuat = 'Please enter a Secret Key';
        this.secretKeyErroruat = '';
        break;
      case 'panNumberuat':
        value = this.panNumberuat;
        if (!value) {
          errorMessageuat = 'Please enter the GST number';
          isValid = false; // Mark the field as invalid
        } else {
          errorMessageuat = 'Please enter a valid GST Number';
          isPAN = true; // Set isPAN to true for further validation
          this.panNumberErroruat = ''; // Clear any previous error message
        }
        break;
    }
  
    const elementuat = document.getElementById(field + 'Input') as HTMLInputElement;
  
    if (isPAN && isValid) {
      isValid = this.validatePANuat(value); // Validate PAN format if necessary
    }
  
    if (!isValid) {
      this.highlightFielduat(elementuat);
      this.setErrorMessageuat(field, errorMessageuat);
    } else {
      this.resetFieldStyleuat(elementuat);
      this.setErrorMessageuat(field, '');
    }
  
    return isValid;
  }

  setErrorMessageuat(field: string, message: string) {
    switch (field) {
      case 'clientIduat':
        this.clientIdErroruat = message;
        break;
      case 'secretKeyuat':
        this.secretKeyErroruat = message;
        break;
      case 'panNumberuat':
        this.panNumberErroruat = message;
        break;
    }
  }

  toUpperCase(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.panNumber = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorder(event); // Call resetBorder here instead of in the template
  }

  resetBorder(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightField(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyle(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyle(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  toUpperCaseuat(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.panNumberuat = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorderuat(event); // Call resetBorder here instead of in the template
  }

  resetBorderuat(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightFielduat(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyleuat(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyleuat(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  validatePAN(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }

  validatePANuat(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }


  


}
