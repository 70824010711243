import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
  import {
    SidebarNewComponent
  } from './sidebar-new.component';
  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule ],
      declarations: [ SidebarNewComponent ],
      exports: [ SidebarNewComponent ]
  })
  export class SidebarNewModule {}