import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';

import {
  Router
} from '@angular/router';
import { ServerStatusService } from '../server-status.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProjParams } from '../config';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  clientToken = localStorage.getItem('newUserAuthKey');




  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };

  failedUrl: any;
  constructor(private http: HttpClient, private router: Router, private serverService: ServerStatusService) {}

  ngOnInit(): void {
    this.checkHealth();
  }


  retry() {
    this.checkHealth();
  }

  checkHealth() {
    this.failedUrl = localStorage.getItem('failedUrl');
    const healthCheckUrl = ProjParams.clientUrl + 'xb/apiPanel/apiHitCounts';
    this.http.get(healthCheckUrl, this.httpOptionsClient).pipe(
      catchError(() => of (null))
    ).subscribe(response => {
      if (response) {
        this.serverService.setServerRunning();
        setTimeout(() => {
        this.router.navigate([this.failedUrl]); 
        }, 400);

      }
    });
  }
}
