import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QueriesComponent } from './queries.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbPaginationModule
  ],
  declarations: [QueriesComponent]
})
export class QueriesModule {}
