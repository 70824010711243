import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { ProjParams } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // Import autoTable separately
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared.service';
import { HistoryTableFilterComponent } from '../homepages/history-table-filter/history-table-filter.component';
import { HistoryTableSearchBoxComponent } from '../homepages/history-table-search-box/history-table-search-box.component';
import { NavbarProfileModalComponent } from '../homepages/navbar-profile-modal/navbar-profile-modal.component';
import { Title } from '@angular/platform-browser';
import { RaiseQueryComponent } from '../homepages/raise-query/raise-query.component';
Router
ActivatedRoute

@Component({
  selector: 'app-gst-detail',
  templateUrl: './gst-detail.component.html',
  styleUrls: ['./gst-detail.component.scss'],
})
export class GstDetailComponent implements OnInit {

  searchText: string;
  currentDate: Date;
  clientDetails: any;
  isMobileView: boolean = false;
  constructor(private route: ActivatedRoute, private modalService: NgbModal, private router: Router, private flagService: DashboardFlagService, private http: HttpClient,
    private sharedService: SharedService, private titleService: Title
  ) { 
    this.currentDate = new Date();

  }
  shouldDisplayAlert: boolean = false; 
  showInput: boolean = false;
  xcibilLogs: any[] = [];
  isLoading: boolean = false;
    //pagination logic
    currentPage = 1;
    pageSize = 10;

  ngOnInit() {
    this.titleService.setTitle('XCIBIL - GST')
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setDashboardFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(true);
    this.flagService.sendMessage('Load');

    this.getXcibilLogs();
    this.getDashboardCounts();
    this.checkScreenSize();
    this.sharedService.paymentObservable$.subscribe(paymentFlag => {
      if (paymentFlag) {
        this.getXcibilLogs();
      } else {
        return;
      }
    });

    var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 

    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.clientDetails = data.data;
        } else {
          console.error('Invalid data format:', data);
        }
      },
      
      (error) => {
        const errorMessage = error.message;
        console.error('Error:', error);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobileView = window.innerWidth < 600; // Adjust the width as per your breakpoint
    if (this.isMobileView) {
      this.pageSize = 8;
    }
  }


  //mock data for xcibil report api table



  get paginatedData() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.xcibilLogs.slice(start, end);
  }

  
  get totalNumberOfPages(): number {
    const totalItems = this.xcibilLogs.length;
    return Math.ceil(totalItems / this.pageSize);
  }

  onPrevClick() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
  onNextClick() {
    if (this.currentPage < this.totalNumberOfPages) {
      this.currentPage++;
    }
  }
  
  onPageSizeChange() {
    // Reset to the first page when the page size changes
    this.currentPage = 1;
  
    // this.isOverflowEnabled = this.pageSize !== 10;
    
  }

  //open-table-filter
  openTableFilter() {
    const modalRef = this.modalService.open(HistoryTableFilterComponent);
    modalRef.componentInstance.name = 'World';

  }
  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }

  //open-history table search modal
  openHistoryTableSearchModal() {
    const modalRef = this.modalService.open(HistoryTableSearchBoxComponent, { size: 'lg', backdrop: 'static' });

  }

  openCredenAndBill(){
    this.router.navigate(['/api/gst-api/credentials-and-billing'])
  }

  navigateToXcibilDetail()
  {
    this.router.navigate(['/api/gst-api/search-gst'])
  }

  navigateToGstFiling()
  {
    this.router.navigate(['/api/gst-api/search-gst-filing'])
  }

  toggleInput() {
    this.showInput = true;
    this.searchText = '';

  }



  closeInput(){
    // console.log('close input called');
    this.searchText = '';
    if (!this.searchText) {
      this.filterHistoryLogs();
    }
    this.getXcibilLogs();
    this.showInput = false;
  }
  clearInput() {
    this.searchText = '';
    }


    filterHistoryLogs() {
      if (this.searchText.trim() === '') {
        this.getXcibilLogs(); 
      } else {
        this.xcibilLogs = this.xcibilLogs.filter(log =>
          log.clientname.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
    }

    clientToken = localStorage.getItem('newUserAuthKey');

    httpOptionsClient = {
      headers: new HttpHeaders({
         'clientauth':this.clientToken
       }) 
     };
   
     
   
     getXcibilLogs(){
       this.isLoading = true;
     
       var url = ProjParams.clientUrl + 'xb/apiPanel/serviceLogs'; // Fix concatenation here
       this.http.get(url, this.httpOptionsClient).subscribe(
        
         (data: any) => {
          this.sharedService.sendFlag(true); // Send flag when data is fetched
           this.isLoading = false;
           this.isFlagTrue = false;
           this.xcibilLogs = data.data.filter(item => item.service_id === 4);
         },
         (error) => {
           this.isLoading = false;
           this.isFlagTrue = false;
           if (error.error.message) {
            console.error('Error:', error); 
          }           console.error('Error:', error);
         }
       );
     }

     downloadExcel() {
      // Ask for confirmation
      if (!confirm("Do you want to download the Excel file?")) {
        return; // If user cancels, do nothing
      }
    
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Data');
    
      const headers = [
        "ID", "Client Name", "Client User ID", "Service ID", "Client ID",
        "PAN Number", "Status", "Hit Charge", "Amount", "Balance", "Description",
        "Status",  "Created At", "Updated At", "PAN",
        "PAN Name", "Mobile", "Email",
        "Service Name"
      ];
    
      worksheet.addRow(headers);
    
      this.xcibilLogs.forEach(item => {
        const createdAt = this.formatDate(item.createdAt);
        const updatedAt = this.formatDate(item.updatedAt);
    
        const values = [
          item.id, item.clientname, item.client_user_id, item.service_id, item.client_id,
          item.panno || '--', item.status || '--', item.hitcharge, item.amount, item.balance, item.status || '--',
          item.service_status, createdAt, updatedAt,
          item.api_user?.pan_no || '--', item.api_user?.pan_name || '--', item.api_user?.mobile || '--',
          item.api_user?.email || '--', item.api_service?.service_name || '--'
        ];
        worksheet.addRow(values);
      });
    
      workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'XCIBIL_API_LOGS' + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
    
  
  
    formatDate(dateString: string): string {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
          return '';
      }
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${day}-${month}-${year} `;
  }
  
  
  generatePDF() {
    if (!confirm("Do you want to download the PDF file?")) {
      return;
    }
  
    const pdf = new jsPDF.default(); 
    const startY = 10;
  
    const headers = [
      "Transaction ID", "Date", "Service Name","Description", "Amount", "Customer charge", "Balance", "Status"
    ];
  
    const body = this.xcibilLogs.map(item => {
      return [
        item.id, 
        this.formatDate(item.createdAt), 
        item.api_service?.service_name, 
        item.status,
        item.amount, 
        item.hitcharge, 
        item.balance, 
        item.service_status
      ];
    });
  
    autoTable(pdf, {
      head: [headers],
      body: body,
      startY: startY
    });
  
    pdf.save('XCIBIL_API_LOGS.pdf');
  }

  printPDF() {

    const pdf = new jsPDF.default();
    const startY = 10;
  
    const headers = [
      "Transaction ID", "Date", "Service Name","Description", "Amount", "Customer charge", "Balance", "Status"
    ];
  
    const body = this.xcibilLogs.map(item => {
      return [
        item.id, 
        this.formatDate(item.createdAt), 
        item.api_service?.service_name, 
        item.status,
        item.amount, 
        item.hitcharge, 
        item.balance, 
        item.service_status
      ];
    });
  
    autoTable(pdf, {
      head: [headers],
      body: body,
      startY: startY
    });
  
    // Open the print dialog
    pdf.autoPrint();
  
    // Output the PDF content to a new window
    const blobURL = URL.createObjectURL(pdf.output('blob'));
    const printWindow = window.open(blobURL);
  }
  

  resetData() {
    this.isLoading = true;
    this.xcibilLogs = []; // Reset data
  }
  
  reload() {
    this.isFlagTrue = true;
    this.resetData(); // Reset data
    this.getXcibilLogs(); // Fetch data again
  }
  
  isFlagTrue: boolean = false;
  
  countList: any;
  getDashboardCounts(): void {
    const url = ProjParams.clientUrl + 'xb/apiPanel/apiHitCounts';
  
  
    this.http.get(url, this.httpOptionsClient).pipe(
      map((data: any) => {
        this.countList = data.data;
        // console.log('Dashboard counts retrieved successfully:', this.countList);
      }),
      catchError((error) => {
        console.error('Error:', error);
        return throwError('Could not retrieve data from the server');
      }),
      finalize(() => {
      })
    ).subscribe();
  }
  

  getFormattedDateWithDay() {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return this.currentDate.toLocaleDateString('en-GB', options).replace(',', '');
  }
  

  openProfileModal() {
 this.modalService.open(NavbarProfileModalComponent, {windowClass: 'custom-modal15px'});

  }

  openRaiseQueryModal() {
    this.modalService.open(RaiseQueryComponent, {windowClass: 'custom-modal20px'});

  }

  navigateToGstAuth(){
    this.router.navigate(['/api/gst-api/gst-auth']);
  }
  
  
  showFilterBox = false;

  toggleDropdown() {
    this.showFilterBox = !this.showFilterBox;
  }

  navigateToGstApis(){
    this.router.navigate(['/api/gst-api/apis']);
  }
  
}
