import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlagService } from 'src/app/flag.service';
import { Subscription } from 'rxjs';
Router
RouterModule

export interface RouteInfo {
  path: string;
  label: string;
  icon: string;
  class: string;
}



@Component({
  selector: 'app-sidebar-new',
  templateUrl: './sidebar-new.component.html',
  styleUrls: ['./sidebar-new.component.scss'],
})
export class SidebarNewComponent implements OnInit {
  isSettingsActive: boolean = false;
  dashboard: any;
  banking: any;
  xcibil: any;
  pan : any;
  currentUrl: any
  private routerEventsSubscription: Subscription;

  constructor(private modalService: NgbModal, private router: Router, private flagService: FlagService, private route: ActivatedRoute) { }

    ngOnInit() {
          
      this.dashboard = localStorage.getItem('isDashboard') === 'true';
      this.banking = localStorage.getItem('isBanking') === 'true';
      this.pan = localStorage.getItem('isPanApi') === 'true';
      this.xcibil = localStorage.getItem('isXcibil') === 'true';
      
      this.routerEventsSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          // console.log(this.currentUrl)
        } 
      });


    }

  ngOnDestroy(): void {
    // Unsubscribe from router events to prevent memory leaks
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }


  //open-profile-modal
  openSettingsModal() {
    const modalRef = this.modalService.open(SettingsModalComponent, { size: 'xl'});
    modalRef.componentInstance.name = 'World';
    this.modalService.open(this.content, { centered: true });
    this.isSettingsActive = true;
  }
  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }



  sendName(name: string) {
    this.flagService.updateName(name);
  }


  logout(){
    localStorage.clear();
    this.router.navigate(['/api-login'])
  }
}
