import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { ProjParams } from 'src/app/config';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gst-auth',
  templateUrl: './gst-auth.component.html',
  styleUrls: ['./gst-auth.component.scss'],
})
export class GstAuthComponent implements OnInit {
  isLoading: boolean = false;
  clientId: string = '';
  secretKey: string = '';
  panNumber: string = '';
  clientIduat: string = '';
  secretKeyuat: string = '';
  panNumberuat: string = '';
  panNameuat: string = '';
  panName: string = '';
  panDetail: any;
  panuatDetail: any;
  ipAddress: string = '';
  ipAddressuat: string = '';
  stateCode: string = '';
  stateCodeuat: string = '';
  panDetailMessage: any;
  uatSearched: boolean = false;
  prodSearched: boolean = false;
  panuatDetailMessage: any;
  clientDetail: any;
  txnNo: string = '';
  otp: string = '';
  txnNouat: string = '';
  otpuat: string = '';
  showOtpInput: boolean = false;;
  showOtpInputuat: boolean = false;
  constructor(private http: HttpClient, private router: Router, private flagService: DashboardFlagService, private titleService: Title, private clientDetailService: ClientDetailsServiceService) { }

  ngOnInit() {
    this.titleService.setTitle('GST Authentication')
    this.flagService.setDashboardFlag(false);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(true);

    this.flagService.sendMessage('Load');

    this.clientDetailService.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
          this.clientId = this.clientDetail.client_id;
          this.secretKey = this.clientDetail.secret_key;
          this.clientIduat = this.clientDetail.sandbox_client_id;
          this.secretKeyuat = this.clientDetail.sandbox_secret_key;
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );
  }
  response: any;



  searchPan() {
    if (!this.validateForm()) {
      return;
    }
    
    this.isLoading = true;
    const url = ProjParams.apiurlNew + 'public/apiRoute/gstAuth';
  
    const headers = new HttpHeaders({
      'clientId': this.clientId,
      'secretKey': this.secretKey,
      'gst_username': this.panName,
      'ip_address': this.ipAddress,
      'state_cd': this.stateCode
    });
  
    const payload = {
      gstNo: this.panNumber,
      gst_username: this.panName
    };
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        this.response = response;
    
        if (response.status === 'Failed' && response.statusCode === 404 && response.message === 'User not found.') {
          this.panDetailMessage = response.message;
        } else {
          this.panDetailMessage = response.message;
          if (response.message === "OTP Sent to your Mobile No.") {
            Swal.fire('Info', response.message, 'info');
            this.showOtpInput = true;
            this.txnNo = response.txn; // Store the txn number
          }
        }
        this.prodSearched = true;
      },
      (error) => {
        this.isLoading = false;
        console.error(error);
        this.panDetailMessage = error.error.message;
      }
    );
  }
  




  verifyAuth(){
    const url = ProjParams.apiurlNew + `public/apiRoute/otpVerification/otp=${this.otp}`;

  
    const headers = new HttpHeaders({
      'clientId': this.clientId,
      'secretKey': this.secretKey,
      'gst_username': this.panName,
      'ip_address': this.ipAddress,
      'state_cd': this.stateCode,
      'txn_no': this.txnNo
    });

  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        this.response = response;
        if (response.status === 'Failed' && response.statusCode === 403 && response.message === 'Invalid Otp. Please Try Again!') {
          Swal.fire('Info', response.message, 'info');
        } else {
          if (response.message === "OTP Verification Successful") {
            Swal.fire('Info', response.message, 'info');
            this.showOtpInput = false;
            this.txnNo = response.txn; // Store the txn number
          }
        }
      },
      (error) => {
        if (error.error.status === 'Failed' && error.error.statusCode === 403 && error.error.message === 'Invalid Otp. Please Try Again!') {
          Swal.fire('Info', error.error.message, 'info');
        }
        this.isLoading = false;
        console.error(error);
  
       
      }
    );
  }



  searchPanuat() {
    if (!this.validateFormuat()) {
      return;
    }
  
    this.isLoading = true;
    const url = ProjParams.apiurlNew + 'public/apiRoute/gstAuthUAT';
  
    const headers = new HttpHeaders({
      'clientId': this.clientIduat,
      'secretKey': this.secretKeyuat,
      'gst_username': this.panNameuat,
      'ip_address': this.ipAddressuat,
      'state_cd': this.stateCodeuat
    });
  
    const payload = {
      gstNo: this.panNumberuat,
      gst_username: this.panNameuat
    }
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
  
        if (response.status === 'Failed' && response.statusCode === 404 && response.message === 'User not found.') {
          this.panuatDetailMessage = response.message;
        } else {
          this.panuatDetailMessage = response.message;
          if (response.message === "OTP Sent to your Mobile No.") {
            Swal.fire('Info', response.message, 'info');
            this.showOtpInputuat = true;
            this.txnNouat = response.txn; // Store the txn number
          }
        }

        this.uatSearched = true;
      },
      (error) => {

        this.isLoading = false;
        console.error(error);
  
          this.panuatDetailMessage = error.error.message;
       
      }
    );
  }


  verifyAuthuat(){
    const url = ProjParams.apiurlNew + `public/apiRoute/otpUATVerification/otp=${this.otpuat}`;

  
    const headers = new HttpHeaders({
      'clientId': this.clientIduat,
      'secretKey': this.secretKeyuat,
      'gst_username': this.panNameuat,
      'ip_address': this.ipAddressuat,
      'state_cd': this.stateCodeuat,
      'txn_no': this.txnNouat
    });

    const payload = {
      gstNo: this.panNumberuat,
      gst_username: this.panNameuat,
      txn_no: this.txnNouat,
      otp: this.otpuat
    }
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        this.response = response;
        if (response.status === 'Failed' && response.statusCode === 403 && response.message === 'Invalid Otp. Please Try Again!') {
          Swal.fire('Info', response.message, 'info');
        } else {
          if (response.message === "OTP Verification Successful") {
            Swal.fire('Info', response.message, 'info');
            this.showOtpInputuat = false;
            this.txnNouat = response.txn; // Store the txn number
          }
        }
      },
      (error) => {
        if (error.error.status === 'Failed' && error.error.statusCode === 403 && error.error.message === 'Invalid Otp. Please Try Again!') {
          Swal.fire('Info', error.error.message, 'info');
        }
        this.isLoading = false;
        console.error(error);
  
       
      }
    );
  }

  prodSearchOn(){
    this.prodSearched = true;
    this.uatSearched =  false;

    this.panuatDetailMessage = '';
    this.panNumberuat = '';
    this.panuatDetail = ''
    this.panNameuat = '';

  }

  uatSearchOn(){
    this.uatSearched = true
    this.prodSearched = false;

    this.panDetailMessage = '';

    this.panNumber = '';
    this.panDetail = ''
    this.panName = '';
  }


  goBack(){
    this.router.navigate(['/api/gst-api']);
  }

  validateForm(): boolean {
    let isValid = true;

    isValid = this.validateField('clientId') && isValid;
    isValid = this.validateField('secretKey') && isValid;
    isValid = this.validateField('panName') && isValid;
    isValid = this.validateField('ipAddress') && isValid;
    isValid = this.validateField('stateCode') && isValid;

    

    return isValid;
  }
  clientIdError: string;
  secretKeyError: string;
  panNumberError: string;
  panNameError: string;
  ipAddressError: string;
  stateCodeError: string;

  validateField(field: string): boolean {
    let isValid = true;
    let value = '';
    let errorMessage = '';
    let isPAN = false;

    switch (field) {
      case 'clientId':
        value = this.clientId;
        if (!value) {
          errorMessage = 'Please enter the client ID.';
          isValid = false; // Mark the field as invalid
        }
        break;
      case 'secretKey':
        value = this.secretKey;
        if (!value) {
          errorMessage = 'Please enter the secret key';
          isValid = false; // Mark the field as invalid
        }
        break;
        case 'panName':
          value = this.panName;
          if (!value) {
            errorMessage = 'Please enter the user name';
            isValid = false; // Mark the field as invalid
          }
          break;
          case 'ipAddress':
            value = this.ipAddress;
            if (!value) {
              errorMessage = 'Please enter the IP address';
              isValid = false; // Mark the field as invalid
            }
            break;
            case 'stateCode':
              value = this.stateCode;
              if (!value) {
                errorMessage = 'Please enter the state code';
                isValid = false; // Mark the field as invalid
              }
              break;

    }

    const element = document.getElementById(field + 'Input') as HTMLInputElement;

    if (isPAN) {
      isValid = this.validatePAN(value);
    } else {
      isValid = !!value;
    }

    if (!isValid) {
      this.highlightField(element);
      this.setErrorMessage(field, errorMessage);
    } else {
      this.resetFieldStyle(element);
      this.setErrorMessage(field, '');
    }

    return isValid;
  }

  validateFormuat(): boolean {
    let isValid = true;

    isValid = this.validateFielduat('clientIduat') && isValid;
    isValid = this.validateFielduat('secretKeyuat') && isValid;
    isValid = this.validateFielduat('panNameuat') && isValid;
    isValid = this.validateFielduat('ipAddressuat') && isValid;
    isValid = this.validateFielduat('stateCodeuat') && isValid;


    return isValid;
  }
  clientIdErroruat: string;
  secretKeyErroruat: string;
  panNumberErroruat: string;
  panNameErroruat: string;
  ipAddressErroruat: string;
  stateCodeErroruat: string;

  validateFielduat(field: string): boolean {
    let isValid = true;
    let value = '';
    let errorMessageuat = '';
    let isPAN = false;
  
    switch (field) {
      case 'clientIduat':
        value = this.clientIduat;
        if (!value) {
          errorMessageuat = 'Please enter the Client ID';
          isValid = false; // Mark the field as invalid
        }        
        break;
      case 'secretKeyuat':
        value = this.secretKeyuat;
        if (!value) {
          errorMessageuat = 'Please enter Secret Key ';
          isValid = false; // Mark the field as invalid
        }     
        break;
        case 'ipAddressuat':
          value = this.ipAddressuat;
          if (!value) {
            errorMessageuat = 'Please enter IP Address';
            isValid = false; // Mark the field as invalid
          }     
          break;
          case 'stateCodeuat':
            value = this.stateCodeuat;
            if (!value) {
              errorMessageuat = 'Please enter State code';
              isValid = false; // Mark the field as invalid
            }     
            break;


        case 'panNameuat':
          value = this.panNameuat;
          if (!value) {
            errorMessageuat = 'Please enter user name';
            isValid = false; // Mark the field as invalid
          }
          console.log('not enetered user name')
          break;

    }
  
    const elementuat = document.getElementById(field + 'Input') as HTMLInputElement;
  
    if (isPAN && isValid) {
      isValid = this.validatePAN(value); // Validate PAN format if necessary
    }
  
    if (!isValid) {
      this.highlightFielduat(elementuat);
      this.setErrorMessageuat(field, errorMessageuat);
    } else {
      this.resetFieldStyleuat(elementuat);
      this.setErrorMessageuat(field, '');
    }
  
    return isValid;
  }
  
  
  setErrorMessage(field: string, message: string) {
    switch (field) {
      case 'clientId':
        this.clientIdError = message;
        break;
      case 'secretKey':
        this.secretKeyError = message;
        break;

        case 'panName':
          this.panNameError = message;
          break;
          case 'ipAddress':
            this.ipAddressError = message;
            break;
            case 'stateCode':
              this.stateCodeError = message;
              break;
    }
  }

  setErrorMessageuat(field: string, message: string) {
    switch (field) {
      case 'clientIduat':
        this.clientIdErroruat = message;
        break;
      case 'secretKeyuat':
        this.secretKeyErroruat = message;
        break;

        case 'panNameuat':
          this.panNameErroruat = message;
        case 'ipAddresseuat':
          this.ipAddressErroruat = message;
        case 'stateCodeuat':
          this.stateCodeErroruat = message;

          break;
    }
  }


  validationMessages: { [key: string]: string } = {
    panNumberuat: '',
    clientIduat: '',
    secretKeyuat: '',
    panNameuat: ''
  };
  


  

  toUpperCase(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.panNumber = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorder(event); // Call resetBorder here instead of in the template
  }

  resetBorder(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightField(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyle(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyle(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  toUpperCaseuat(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.panNumberuat = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorderuat(event); // Call resetBorder here instead of in the template
  }

  resetBorderuat(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightFielduat(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyleuat(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyleuat(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  validatePAN(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }

  validatePANuat(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }


}
