import { Component, HostListener, OnInit } from '@angular/core';
import { QueryTableSearchBoxComponent } from '../query-table-search-box/query-table-search-box.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { Title } from '@angular/platform-browser';
import { RaiseQueryComponent } from '../raise-query/raise-query.component';
import { ProjParams } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import * as ExcelJS from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // Import autoTable separately
@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
})
export class QueriesComponent implements OnInit {
  clientToken = localStorage.getItem('newUserAuthKey');
  isLoading: boolean = false;

    httpOptionsClient = {
    headers: new HttpHeaders({
        'clientauth':this.clientToken
      }) 
    };
  currentPage = 1;
  pageSize = 11;
  queriesCount: any;
  closeInput() {
    throw new Error('Method not implemented.');
    }
    toggleInput() {
    throw new Error('Method not implemented.');
    }
    showInput: any;
    searchText: any;
    filterHistoryLogs() {
    throw new Error('Method not implemented.');
    }
    openTableFilter() {
    throw new Error('Method not implemented.');
    }

    isFlagTrue: any;
    reload() {
    throw new Error('Method not implemented.');
    }
      isSelected1: boolean = false;
      isSelected2: boolean = false;
      isSelected3: boolean = false;
      isSelected4: boolean = false;
      showFilterBox: boolean = false;
    serviceLogs: any;
    queries: any[] = []
    unfilteredList: any[] = [];
      constructor(private route: ActivatedRoute, private modalService: NgbModal, private flagService: DashboardFlagService, private titleService: Title, private http: HttpClient, private router: Router) 
      { 
      }
      shouldDisplayAlert: boolean = false; 
    
      ngOnInit() {
        this.titleService.setTitle('XCIBIL - Queries')
        this.flagService.setDashboardFlag(true);
        this.flagService.setPanApiFlag(false);
        this.flagService.setXcibilFlag(false);
        this.flagService.setBankingFlag(false);
        this.flagService.setGstFlag(false);
        this.flagService.sendMessage('Load');
        this.loadQueryTypes();
        this.checkScreenSize();

        var url = ProjParams.clientUrl + 'xb/apiPanel/getQuery';

        this.isLoading = true;
        this.http.get(url, this.httpOptionsClient).subscribe(
          (data: any) => {
            if (data && data.data) {
        this.isLoading = false;

              this.queries = data.data;
              this.unfilteredList = data.data
            } else {
        this.isLoading = false;

              console.error('Invalid data format:', data);
            }
          },
          
          (error) => {
        this.isLoading = false;

            if (error.error.message) {
              console.error('Error:', error); 
            }          console.error('Error:', error);
          }
        );

        var countUrl = ProjParams.clientUrl + 'xb/apiPanel/getQueryCounts';


        this.http.get(countUrl, this.httpOptionsClient).subscribe(
          (data: any) => {
            if (data && data.data) {
              this.queriesCount = data.data;
              console.log(this.queriesCount);
            } else {
              console.error('Invalid data format:', data);
            }
          },
          
          (error) => {
            if (error.error.message) {
              console.error('Error:', error); 
            }          console.error('Error:', error);
          }
        );
      }

      isMobileView: boolean = false;

      @HostListener('window:resize', ['$event'])
      onResize(event: Event): void {
        this.checkScreenSize();
      }
    
      checkScreenSize(): void {
        this.isMobileView = window.innerWidth < 600; // Adjust the width as per your breakpoint

        if (this.isMobileView) {
          this.pageSize = 16;
        }
      }
    
    
      get paginatedData() {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        return this.queries.slice(start, end);
      }

      get totalNumberOfPages(): number {
        const totalItems = this.queries.length;
        return Math.ceil(totalItems / this.pageSize);
      }
    
      onPrevClick() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      }
      
      onNextClick() {
        if (this.currentPage < this.totalNumberOfPages) {
          this.currentPage++;
        }
      }
      
      onPageSizeChange() {
        // Reset to the first page when the page size changes
        this.currentPage = 1;
      
        // this.isOverflowEnabled = this.pageSize !== 10;
        
      }
    
        //open-search-modal
        openQueryTableSearchBoxModal() {
          const modalRef = this.modalService.open(QueryTableSearchBoxComponent, { size: 'lg',});
          modalRef.componentInstance.name = 'World';
      
        }
    
    

        getQuery(){
          var url = ProjParams.clientUrl + 'xb/apiPanel/getQuery';

          this.http.get(url, this.httpOptionsClient).subscribe(
            (data: any) => {
              if (data && data.data) {
  
                this.queries = data.data;
              } else {
  
                console.error('Invalid data format:', data);
              }
            },
            
            (error) => {
  
              if (error.error.message) {
                console.error('Error:', error); 
              }          console.error('Error:', error);
            }
          );
  
        }

        getQueryCount(){
          
        var countUrl = ProjParams.clientUrl + 'xb/apiPanel/getQueryCounts';


        this.http.get(countUrl, this.httpOptionsClient).subscribe(
          (data: any) => {
            if (data && data.data) {
              this.queriesCount = data.data;
            } else {
              console.error('Invalid data format:', data);
            }
          },
          
          (error) => {
            if (error.error.message) {
              console.error('Error:', error); 
            }          console.error('Error:', error);
          }
        );
        }
        
      
        toggleSelection(boxNumber: number): void {
          switch (boxNumber) {
            case 1:
              this.isSelected1 = !this.isSelected1;
              if (this.isSelected1) {
                // Selecting box 1, deselect others
                this.isSelected2 = false;
                this.isSelected3 = false;
                this.isSelected4 = false;
              }
              break;
            case 2:
              this.isSelected2 = !this.isSelected2;
              if (this.isSelected2) {
                // Selecting box 2, deselect others
                this.isSelected1 = false;
                this.isSelected3 = false;
                this.isSelected4 = false;
              }
              break;
            case 3:
              this.isSelected3 = !this.isSelected3;
              if (this.isSelected3) {
                // Selecting box 3, deselect others
                this.isSelected1 = false;
                this.isSelected2 = false;
                this.isSelected4 = false;
              }
              break;
            case 4:
              this.isSelected4 = !this.isSelected4;
              if (this.isSelected4) {
                // Selecting box 4, deselect others
                this.isSelected1 = false;
                this.isSelected2 = false;
                this.isSelected3 = false;
              }
              break;
            default:
              break;
          }
        }

        openRaiseQueryModal() {
          const modalRef = this.modalService.open(RaiseQueryComponent, {windowClass: 'custom-modal20px'});
         modalRef.componentInstance.updateSuccessEvent.subscribe(() => {
          this.getQuery();
          this.getQueryCount();
        });

        }

        navigateToComments(id: number) {
          this.router.navigate(['/api/queries/detail', id]);
        }
        

        queryTypes: any[] = [];
        statuses = [
          { value: 'Open', name: 'Open' },
          { value: 'WIP', name: 'Work In Progress' },
          { value: 'Close', name: 'Closed' },

        ];
        loadQueryTypes(){
          var url = ProjParams.clientUrl + 'xb/apiPanel/getQueryTypes';
          const clientToken = localStorage.getItem('newUserAuthKey');
        
          
          this.http.get(url, this.httpOptionsClient).subscribe(
            (data: any) => {
              if (data && data.data) {
                this.queryTypes = data.data;
              } else {
                console.error('Invalid data format:', data);
      
              }
            },
            
            (error) => {
              if (error.error.message) {
                console.error('Error:', error); 
              }          console.error('Error:', error);
            }
          );
        }

        toggleFilterBox() {
          this.showFilterBox = !this.showFilterBox;
        }
        filterApplied: boolean = false;
        filters = {
          query_id: '',
          createdAt: '',
          query_types: [] as string[],
          statuses: [] as string[],
        };

        applyFilter() {
          this.queries = [...this.unfilteredList]; // Reset to original list

          this.queries = this.queries.filter((client) => {
            return (
              (!this.filters.query_id || client.query_id.includes(this.filters.query_id)) &&
              (!this.filters.createdAt ||
                new Date(client.createdAt).toDateString() ===
                new Date(this.filters.createdAt).toDateString()) &&
              (this.filters.query_types.length === 0 || this.filters.query_types.includes(client.query_type)) &&
              (this.filters.statuses.length === 0 || this.filters.statuses.includes(client.status))
            );
          });
          this.showFilterBox = false; // Optionally hide the filter box after applying
          this.filterApplied = true;
        }
        
        onQueryTypeChange(event: Event) {
          const input = event.target as HTMLInputElement;
          const index = this.filters.query_types.indexOf(input.value);
        
          if (input.checked) {
            if (index === -1) {
              this.filters.query_types.push(input.value);
            }
          } else {
            if (index !== -1) {
              this.filters.query_types.splice(index, 1);
            }
          }
        }
        
        onStatusChange(event: Event) {
          const input = event.target as HTMLInputElement;
          const index = this.filters.statuses.indexOf(input.value);
        
          if (input.checked) {
            if (index === -1) {
              this.filters.statuses.push(input.value);
            }
          } else {
            if (index !== -1) {
              this.filters.statuses.splice(index, 1);
            }
          }
        }
      

        hasActiveFilters(): boolean {
          return (
            this.filters.query_id !== '' ||
            this.filters.createdAt !== '' ||
            this.filters.query_types.length > 0 ||
            this.filters.statuses.length > 0
          );
        }

        clearFilter() {
          this.filters = {
            query_id: '',
            createdAt: '',
            query_types: [] as string[],
            statuses: [] as string[],
          };
          this.filterApplied = false;

        }

        formatDate(dateString: string): string {
          const date = new Date(dateString);
          if (isNaN(date.getTime())) {
              return '';
          }
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');
          return `${day}-${month}-${year} `;
      }
    
    
        
      generatePDF() {
        if (!confirm("Do you want to download the PDF file?")) {
          return;
        }
      
        const pdf = new jsPDF.default();
        const startY = 10;
      
        const headers = [
          "Query Number", "Query Type", "Date", "Description", "Status"
        ];
      
        const body = this.queries.map(item => {
          // Check if description is available and is a string
          const description = typeof item.description === 'string' ? item.description : '';
          const trimmedDescription = description.length > 30 ? description.slice(0, 30) + '...' : description;
      
          return [
            item.query_id,
            item.query_type,
            this.formatDate(item.createdAt),
            trimmedDescription,
            item.status
          ];
        });
      
        autoTable(pdf, {
          head: [headers],
          body: body,
          startY: startY
        });
      
        pdf.save('Query List.pdf');
      }
      
      


      downloadExcel() {
        if (!confirm("Do you want to download the Excel file?")) {
          return; 
        }
      
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
      
        const headers = [
          "Query Number", "Query Type", "Date", "Description", "Status",
        ];
      
        worksheet.addRow(headers);
      
        this.queries.forEach(item => {
          const createdAt = this.formatDate(item.createdAt);
          // Check if description is available and is a string
          const description = typeof item.description === 'string' ? item.description : '';
          const trimmedDescription = description.split(' ').slice(0, 10).join(' ');
          const finalDescription = description.split(' ').length > 10 ? `${trimmedDescription}...` : trimmedDescription;
      
          const values = [
            item.query_id, item.query_type, createdAt, finalDescription, item.status


          ];
          worksheet.addRow(values);
        });
      
        workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Query_List' + '.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
       
      
}
