import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2pdf from 'html2pdf.js';
import { StoreXcibilReportService } from 'src/app/store-xcibil-report.service';
import * as d3 from 'd3';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { FlagService } from 'src/app/flag.service';
import { AuthService } from 'src/app/auth.service';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-xcibil-report',
  templateUrl: './xcibil-report.component.html',
  styleUrls: ['./xcibil-report.component.scss'],
})
export class XcibilReportComponent implements OnInit {
  panDetail: any;
  panDate: any;
  showContent: boolean = false;
  public gaugeType = 'arch';
  public gaugeThick = 10;
  public defaultXcibilPoints = 0;
  public gaugethresholds = {
    '300': { color: 'blue' },
    '600': { color: 'blue' },
    '700': { color: 'blue' },
    '900': { color: 'blue' },
  };
  public gaugeMin = 300;
  public gaugemax = 900;


  // public gaugeType3 = 'arch';
  public gaugeThick3 = 10;
  public defaultXcibilPoints3 = 0;
  public gaugethresholds3 = {
    '300': { color: 'blue' },
    '600': { color: 'blue' },
    '700': { color: 'blue' },
    '900': { color: 'blue' },
  };
  public gaugeMin3 = 0;
  public gaugemax3 = 0;




  public gaugeType1 = 'semi';
  public gaugeThick1 = 20;
  public pendingValue : any;
  public gaugethresholds1 = {
    '300': { color: 'red' },
    '600': { color: 'orangeRed' },
    '700': { color: 'orange' },
    '900': { color: 'green' },
  };
  public pendingMin: any;
  public pendingMax: any;


  public gaugeType2 = 'semi';
  public gaugeThick2 = 20;
  public completedValue: any;
  public gaugethresholds2 = {
    '300': { color: 'red' },
    '600': { color: 'orangeRed' },
    '700': { color: 'orange' },
    '900': { color: 'green' },
  };
  public completedMin: any;
  public completedMax: any;
    name: any
  panNumber: any;
  showPending: boolean = false;
  showCompleted: boolean = false;
  panName: any;
  currentDateTime: string;
  currentTime: string;
  defaultedMin: number;
  unverifiedMin: number;
  constructor(private router: Router, private reportService: StoreXcibilReportService, private flagService: DashboardFlagService, private flagNameService: FlagService, private authService: AuthService, private titleService: Title) {

   }

  ngOnInit() {
    this.titleService.setTitle('Fetch XCIBIL Report')

    const isAuthorized = this.authService.getAuthorizationStatus();

    if (!isAuthorized) {
      Swal.fire('Info', 'Please fetch XCIBIL report to again view the full report.', 'info');
      this.router.navigate(['/api/xcibil-report/search-xcibil']); 
    }
  
    this.flagService.setDashboardFlag(false);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(true);
    this.flagService.setBankingFlag(false);
    this.flagNameService.updateName('xcibil-api');
    this.panDetail = this.reportService.panuatDetail;
    this.name = this.reportService.panuatDetail?.historyList[0]?.borrowed_from;
    this.defaultXcibilPoints = this.reportService?.panuatDetail?.default.xcibilPoints
    this.defaultXcibilPoints3 = this.reportService?.panuatDetail?.history.xcibilPoints
    this.pendingMin = parseFloat(this.reportService.panuatDetail?.pending.lowestTrans.replace(/,/g, ''));
    this.pendingMax = parseFloat(this.reportService.panuatDetail?.pending.highestTrans.replace(/,/g, ''));
    this.panNumber = this.reportService.panuatDetail?.panName;
    this.panName = this.reportService.panuatDetail?.panNumber;
    this.completedMin= parseFloat(this.reportService.panuatDetail?.complete.lowestTrans.replace(/,/g, ''));
    this.completedMax= parseFloat(this.reportService.panuatDetail?.complete.highestTrans.replace(/,/g, ''));
    this.completedValue = parseFloat(this.reportService.panuatDetail?.complete.average.replace(/,/g, ''));
    this.pendingValue = parseFloat(this.reportService.panuatDetail?.pending.average.replace(/,/g, ''));
    this.currentDateTime = new Date().toLocaleString();
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 60000); // Update every minute
    this.renderPendingGaugeChart();
    this.renderCompletedGaugeChart();
    this.renderDefaultedGaugeChart()
    this.renderUnverifiedGaugeChart()
  }

  updateTime(): void {
    const now = new Date();
    const hours = this.padZero(now.getHours());
    const minutes = this.padZero(now.getMinutes());
    const seconds = this.padZero(now.getSeconds());
    this.currentTime = `${hours}:${minutes}:${seconds}`;
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  show: boolean = false;

  goBack(){
    this.router.navigate(['/api/xcibil-report/search-xcibil'])
  }

  convertToPDF() {
    // Select the pdf-content div
    const element = document.getElementById('pdf-content');

    // Options for html2pdf
    const options = {
      margin: 1,
      filename: 'Xcibil Report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Start the conversion process
    html2pdf().from(element).set(options).save();

  }


  //pending and completed gauges
  renderPendingGaugeChart() {
    this.renderGaugeChart(
      '#gauge-chart-pending',
      this.pendingMin,
      this.pendingMax,
      this.pendingValue,
      'DeepSkyBlue', // Foreground color
      'lightGray', // Background color
      'black' // Needle color
    );
  }
  
  renderCompletedGaugeChart() {
    this.renderGaugeChart(
      '#gauge-chart-completed',
      this.completedMin,
      this.completedMax,
      this.completedValue,
      'DeepSkyBlue', // Foreground color
      'lightGray', // Background color
      'black' // Needle color
    );
  }


  renderDefaultedGaugeChart() {
    this.renderGaugeChart1(
      '#gauge-chart-defaulted',
      this.defaultedMin = 300,
      this.defaultedMin = 900,
      this.defaultXcibilPoints || 0,
      'green', // Foreground color
      'lightGray', // Background color
      'black' // Needle color
    );
  }

  
  renderUnverifiedGaugeChart() {
    this.renderGaugeChart1(
      '#gauge-chart-unverified',
      this.unverifiedMin = 300,
      this.unverifiedMin = 900,
      this.defaultXcibilPoints3 || 0,
      'green', // Foreground color
      'lightGray', // Background color
      'black' // Needle color
    );
  }
  
  
  
  private renderGaugeChart(
    selector: string,
    minValue: number,
    maxValue: number,
    value: number,
    fillColor: string,
    bgColor: string,
    needleColor: string
  ) {
    const width = 400;
    const height = 200;
    const radius = Math.min(width, height) / 2 - 10;
    const svg = d3.select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
  
    // Draw the background arc
    const backgroundArc = d3.arc()
      .innerRadius(radius - 20)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2);
  
    svg.append('path')
      .datum({ endAngle: Math.PI / 2 })
      .style('fill', bgColor)
      .attr('d', backgroundArc as any);
  
    // Function to scale the value to the correct angle
    const scale = d3.scaleLinear()
      .domain([minValue, maxValue])
      .range([-Math.PI / 2, Math.PI / 2]);
  
    // Determine the angle for the needle
    let needleAngle = scale(value);
    if (value === 0) {
      needleAngle = -Math.PI / 2; // Adjusted to point to the left (minValue) when value is 0
    }
  
    // Draw the foreground arc up to the value
    const foregroundArc = d3.arc()
      .innerRadius(radius - 20)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle(needleAngle);
  
    svg.append('path')
      .datum({ endAngle: needleAngle })
      .style('fill', fillColor)
      .attr('d', foregroundArc as any);
  
    // Draw the needle
    const needleData: [number, number][] = [
      [0, -radius / 15],
      [radius / 2, 0],
      [0, radius / 15],
      [-radius / 20, 0]
    ];
  
    const needleLine = d3.line<[number, number]>()
      .x(d => d[0])
      .y(d => d[1])
      .curve(d3.curveLinear);
  
    const needleGroup = svg.append('g')
      .attr('class', 'needle')
      .attr('transform', `rotate(${needleAngle * 180 / Math.PI - 90})`);
  
    needleGroup.append('path')
      .datum(needleData)
      .attr('d', needleLine as any)
      .style('fill', needleColor)
      .style('stroke', needleColor)
      .style('stroke-width', '1px')
      .style('filter', 'url(#needleShadow)');
  
    // Add shadow for needle
    const defs = svg.append('defs');
  
    const filter = defs.append('filter')
      .attr('id', 'needleShadow')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%');
  
    filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', '3');
  
    filter.append('feOffset')
      .attr('dx', '2')
      .attr('dy', '2')
      .attr('result', 'offsetblur');
  
    filter.append('feFlood')
      .attr('flood-color', 'rgba(0,0,0,0.3)');
  
    filter.append('feComposite')
      .attr('in2', 'offsetblur')
      .attr('operator', 'in');
  
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  
    // Add min and max labels
    svg.append('text')
      .text(minValue.toString())
      .attr('x', -radius + 10)
      .attr('y', 20)
      .style('font-size', '14px');
  
    svg.append('text')
      .text(maxValue.toString())
      .attr('x', radius - 30)
      .attr('y', 20)
      .style('font-size', '14px');
  
    // Add value display below the needle
    svg.append('text')
      .text(value.toString())
      .attr('x', 0)
      .attr('y', radius / 2.5)  // Adjust this value to position it closer to the needle
      .style('font-size', '19px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle');
  }



  private renderGaugeChart1(
    selector: string,
    minValue: number,
    maxValue: number,
    value: number,
    fillColor: string,
    bgColor: string,
    needleColor: string
  ) {
    const width = 400;
    const height = 200;
    const radius = Math.min(width, height) / 2 - 10;
    const svg = d3.select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
  
    // Draw the background arc covering 75% of the circle
    const backgroundArc = d3.arc()
      .innerRadius(radius - 12)
      .outerRadius(radius)
      .startAngle(-Math.PI / 1)
      .endAngle(Math.PI / 2);
  
    svg.append('path')
      .datum({ endAngle: Math.PI / 1 })
      .style('fill', bgColor)
      .attr('d', backgroundArc as any);
  
    // Function to scale the value to the correct angle
    const scale = d3.scaleLinear()
      .domain([minValue, maxValue])
      .range([-Math.PI / 1, Math.PI / 2]);
  
    // Determine the angle for the value arc
    let valueAngle = scale(value);
    if (value === 0) {
      valueAngle = -Math.PI / 1; // Adjusted to point to the left (minValue) when value is 0
    }
  
    // Draw the foreground arc up to the value
    const foregroundArc = d3.arc()
      .innerRadius(radius - 12)
      .outerRadius(radius)
      .startAngle(-Math.PI / 1)
      .endAngle(valueAngle);
  
    svg.append('path')
      .datum({ endAngle: valueAngle })
      .style('fill', fillColor)
      .attr('d', foregroundArc as any);
  
    // Add min and max labels
    svg.append('text')
      .text(minValue.toString())
      .attr('x', 10)
      .attr('y', 85)
      .style('font-size', '14px');
  
    svg.append('text')
      .text(maxValue.toString())
      .attr('x', radius - 30)
      .attr('y', 20)
      .style('font-size', '14px');
  
    // Add value display below the gauge
    svg.append('text')
      .text(value.toString())
      .attr('x', 0)
      .attr('y', 10)
      .style('font-size', '19px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle');
  }
  
  

  private scale(value: number, minValue: number, maxValue: number): number {
    return ((value - minValue) / (maxValue - minValue)) * Math.PI - Math.PI / 2;
  }
}
