import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjParams } from 'src/app/config';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ApiLoginService {
  constructor(private http: HttpClient) {}

  verifyOtp(data: any) {
    const apiUrl = ProjParams.apiurl + 'apiPanel/verifyOtp';
    return this.http.post(apiUrl, data);
  }

  sendOtp(data: any) {
    const apiUrl = ProjParams.apiurl + 'apiPanel/sendOtp';
    return this.http.post(apiUrl, data);
  }

  handleError(error: any) {
    let errorMessage = 'An error occurred';
    if (error.error && error.error.message) {
      errorMessage = error.error.message;
    }
    Swal.fire('Info', errorMessage, 'info');
  }
}
