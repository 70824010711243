import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServerStatusService } from '../server-status.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  isRunning: boolean = false; // Initialize with a default value

  constructor(private router: Router, private serverService: ServerStatusService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.serverService.setServerDown();
          setTimeout(() => {
            this.router.navigate(['/error']);
          }, 1000);
        }

        const routeUrl = this.router.routerState.snapshot.url;
        const trimmedRouteUrl = this.trimRouteUrl(routeUrl);
        const failedUrl = localStorage.getItem('failedUrl');
        if (!failedUrl) {
          localStorage.setItem('failedUrl', trimmedRouteUrl);
        }
        return throwError(error);
      })
    );
  }

  private trimRouteUrl(url: string): string {
    const apiAdminIndex = url.indexOf('/api/');
    if (apiAdminIndex !== -1) {
      return url.substring(apiAdminIndex);
    }
    return url;
  }
}
