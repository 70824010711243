import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
import { SearchEquifaxComponent } from './search-equifax.component';
import { FormsModule } from '@angular/forms';

  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule, FormsModule ],
      declarations: [ SearchEquifaxComponent ],
      exports: [ SearchEquifaxComponent ]
  })
  export class SearchEquifaxModule {}