import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiLoginModalTwoComponent } from '../api-login-modal-two/api-login-modal-two.component';
import { ApiLoginService } from 'src/app/api-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-api-login-modal',
  templateUrl: './api-login-modal.component.html',
  styleUrls: ['./api-login-modal.component.scss'],
})
export class ApiLoginModalComponent implements OnInit {
  @Input() eemail: any;
  sendOtp: any = { email: '', otp: '' };
  errors: any;
  isNotValid: boolean = false;
  isLoading: boolean = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private apiLoginService: ApiLoginService
  ) {}

  ngOnInit() {}

  closeModal() {
    this.activeModal.close('Modal closed');
  }

  validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.isNotValid = !emailPattern.test(this.sendOtp.email);
  }

  clearEmailErrorMessage() {
    this.isNotValid = false;
  }

  submitEmail() {
    if (!this.sendOtp.email) {
      Swal.fire('Info', 'Please enter your email address', 'info');
      return;
    }

    if (!this.isNotValid) {
      this.isLoading = true;
      this.apiLoginService.sendOtp(this.sendOtp).subscribe(
        (response) => {
          this.isLoading = false;
          this.activeModal.close();
          const modalRef = this.modalService.open(ApiLoginModalTwoComponent);
          modalRef.componentInstance.eemail = this.sendOtp.email;
          this.modalService.open(this.content, { centered: true });
        },
        (error) => {
          this.apiLoginService.handleError(error);
          this.isLoading = false;
        }
      );
    }
  }

  openModal() {
    this.activeModal.close();
    const modalRef = this.modalService.open(ApiLoginModalTwoComponent);
    modalRef.componentInstance.email = this.sendOtp.email;
    this.modalService.open(this.content, { centered: true });
  }

  content(content: any, arg1: { centered: true }) {
    throw new Error('Method not implemented.');
  }
}
