import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
NgbModal
@Component({
  selector: 'app-history-table-search-box',
  templateUrl: './history-table-search-box.component.html',
  styleUrls: ['./history-table-search-box.component.scss'],
})
export class HistoryTableSearchBoxComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal){ }

  ngOnInit() {}

  //close modal icon logic
  closeModal() {
    this.activeModal.close('Modal closed');
  }
}
