import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
import { SearchPanComponent } from './search-pan.component';
import { FormsModule } from '@angular/forms';

  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule, FormsModule],
      declarations: [ SearchPanComponent ],
      exports: [ SearchPanComponent ]
  })
  export class SearchPanModule {}