import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjParams } from 'src/app/config';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import Swal from 'sweetalert2';
import { load } from '@cashfreepayments/cashfree-js';
import { SharedService } from 'src/app/shared.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-navbar-add-money-modal',
  templateUrl: './navbar-add-money-modal.component.html',
  styleUrls: ['./navbar-add-money-modal.component.scss'],
})
export class NavbarAddMoneyModalComponent implements OnInit {
  @Input() serviceDetail: any; // This property will receive the value from the parent component

  dashboard: any;
  banking: any;
  pan: any;
  xcibil: any;
  serviceList: any;
  totalBal: number = 0;
  amount = 100;
  clientDetails: any;
  payment_method: any;
  selectedMethod: string;
  orderResponse: any;
  isLoading: boolean = false;
  selectedService: any;
  selectedServiceId: any; 
  cashfreeOrderId: any;
  apiHitSubscription: Subscription
  gst: boolean;
  constructor(private http: HttpClient, private router: Router, private activeModal: NgbActiveModal, private clientdetailsservice: ClientDetailsServiceService, private sharedService: SharedService) {

    this.apiHitSubscription = this.sharedService.apiHit$.subscribe(() => {
      this.getServiceList(); // Call your method here
    });
   }

   @Output() updateSuccessEvent = new EventEmitter<void>();


  ngOnInit() {
    this.initializeSDK();
    this.dashboard = localStorage.getItem('isDashboard') === 'true';
    this.banking = localStorage.getItem('isBanking') === 'true';
    this.pan = localStorage.getItem('isPanApi') === 'true';
    this.xcibil = localStorage.getItem('isXcibil') === 'true';
    this.gst = localStorage.getItem('isGst') === 'true';
    this.getServiceList();

    this.clientdetailsservice.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetails = data.data;
          
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    ); 


    if (this.banking) {
      this.selectedServiceId = 3;
      this.checkServiceStatus('EQUIFAX REPORT');

    } else if (this.pan) {
      this.selectedServiceId = 1;
      this.checkServiceStatus('PAN');

    } else if (this.xcibil) {
      this.selectedServiceId = 2;
      this.checkServiceStatus('XCIBIL REPORT');

    } 
    else if (this.gst) {
      this.selectedServiceId = 4;
      this.checkServiceStatus('GST');

    } else {
      this.selectedServiceId = '';
      this.checkServiceStatus('');

    }


   }
  clientToken = localStorage.getItem('newUserAuthKey');

  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };

  httpOptionsClient1 = {
    headers: new HttpHeaders({
       'clientauth':this.clientToken
     }) 
   };
 
  
getServiceList(){
  var url = ProjParams.clientUrl + 'xb/apiPanel/getService';
  this.http.get(url, this.httpOptionsClient).subscribe(
    (data: { data: any[] }) => {
      this.serviceList = data.data;
      // console.log(this.serviceList);

      for (let index = 0; index < this.serviceList.length; index++) {
        const element = this.serviceList[index];

        if (element.api_user_service && element.api_user_service.hit_amount) {
          this.totalBal += parseFloat(element.api_user_service.hit_amount);
        }
        
      }

    },
    
    (error) => {
      console.error('Error:', error);
    }
  );
}

// getclientdetails(){
//   var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 

//   this.http.get(url, this.httpOptionsClient).subscribe(
//     (data: any) => {
//       if (data && data.data) {
//         this.clientDetails = data.data;
//       } else {
//         console.error('Invalid data format:', data);
//       }
//     },
    
//     (error) => {
//       const errorMessage = error.message;
//       console.error('Error:', errorMessage);
//     }
//   );
// }




handleClick(amount: string) {
  amount = amount;
  switch(amount) {
    case '100':
      this.amount = 100;
      break;
    case '200':
      this.amount = 200;
      break;
    case '500':
      this.amount = 500;
      break;
      case '1000':
      this.amount = 1000;
      break;
    default:
      // console.log("Unknown service clicked");
      break;
  }
}

handleClickMethod(method: string) {
  this.selectedMethod = method;
  switch(method) {
    case 'card':
      this.selectedMethod = 'Card'
      break;
    case 'upi':
      this.selectedMethod = 'UPI'

      break;
    case 'paypal':
      this.selectedMethod = 'PayPal'
      
      break;
      case 'gpay':
        this.selectedMethod = 'GPay'
    
        break;
    default:
      console.log("Unknown method");
      break;
  }
}

navigateToInvoice(){
  this.activeModal.close();
  this.router.navigate(['/api/invoice']);
}

closeModal(){
  this.activeModal.close();
}



cashfree: any;
session_id: string;
service = {
  serviceId: "0",
  clientUserId: "0",
  hitAmount: "0"
};


async initializeSDK() {
  try {
    this.cashfree = await load({
      mode: 'production'
    });
    console.log('Cashfree SDK initialized successfully');
  } catch (error) {
    console.error('Error initializing Cashfree SDK:', error);
  }
}

  service_status : any = '';
 checkServiceStatus(serviceName: string) {
  const service = this.serviceDetail.find((s: { service_name: string; }) => s.service_name.toLowerCase() === serviceName.toLowerCase());
  if (service) {
    if (!service.api_user_service) {
      // console.log(`${serviceName} api_user_service is null`);
      this.service_status = 'Not Active';
    } else if (!service.api_user_service.service_status) {
      // console.log(`${serviceName} service is inactive`);
      this.service_status = 'Deactivated';
    } else {
      // console.log(`${serviceName} service is active`);
      this.service_status = 'Active';
    }
  } else {
    console.log(`${serviceName} service not found`);
  }
}
showAlert: boolean = false;
closeAlert(){
  this.showAlert = false;
}
async sendOrderDetails(){

  if (this.service_status === 'Not Active' || this.service_status === 'Deactivated') {
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 7000);
    return;
  }
  
  if (!this.amount) {
    alert('Please enter amount to be added!');
    return;
  }

  this.isLoading = true;

  const payload = {
    amount: this.amount
  }

const url = ProjParams.clientUrl + 'xb/apiPanel/cashOrder'

this.http.post(url, payload, this.httpOptionsClient1).subscribe(
  (data: any) => {
    if (data && data.data) {
      // console.log('order response', data.data)
      this.session_id = data.data.payment_session_id;
      this.cashfreeOrderId = data.data.order_id;
      this.doPayment();
    } else {
      console.error('Invalid data format:', data);
    }
  },
  (error) => {
    const errorMessage = error.message;
    console.error('Error:', errorMessage);
  }
);


}

async doPayment() {
  this.isLoading = false;
  if (!this.session_id) {
    console.error('Session ID not available.');
    return;
  }
  this.activeModal.close();

  let checkoutOptions = {
    paymentSessionId: this.session_id,
    redirectTarget: '_modal',
  };

  this.cashfree.checkout(checkoutOptions).then((result: any) => {
    if (result.error) {
      console.log('User has closed the popup or an error occurred during payment.');
      Swal.fire({
        icon: 'info',
        title: 'Payment Cancelled',
        text: 'Your payment request has been cancelled successfully.',
        confirmButtonText: 'OK'
      });
            console.log(result.error);
    }
    if (result.redirect) {
      console.log('Payment will be redirected.');
    }
    if (result.paymentDetails) {    
      console.log(result.paymentDetails)      ;                                                                                                                                                                                                                  
      console.log('Payment has been completed.');
      this.addWalletAmount();
    }
  });
}



addWalletAmount(){
  let payload = {
    serviceId: this.selectedServiceId,
    hitAmount: this.amount,
    orderId: this.cashfreeOrderId

  }
  var url = ProjParams.clientUrl + 'xb/apiPanel/addServiceWalletAmount'; 

  this.http.patch(url, payload, this.httpOptionsClient1).subscribe(
    (data: any) => {
      this.updateSuccessEvent.emit();
      this.sharedService.sendPaymentSuccessfulFlag(true); // Send flag when data is fetched
      console.log('the order id is:P', this.cashfreeOrderId); 
      // this.initiateRefund(this.cashfreeOrderId, '4ss8er0e01592zx0v4w5251g5s0d4hj71wd', 100);

    },
    
    (error) => {
      const errorMessage = error.message;
      console.error('Error:', errorMessage);
    }
  );
}


private cashfreeApiUrl = 'https://sandbox.cashfree.com'; // Change to production URL for live
private cashfreeApiVersion = '2023-08-01';
private cashfreeHeaders = new HttpHeaders({
  'Content-Type': 'application/json',
  'x-api-version': this.cashfreeApiVersion,
  'x-client-id': 'TEST101948873b34c974d0a56a02227178849101',
  'x-client-secret': 'cfsk_ma_test_b2abde17a56e7da630aa4b6687fbedca_22c1e137',
});


initiateRefund(orderId: string, refundId: string, refundAmount: number) {
  const refundPayload = {
    refund_amount: refundAmount,
    refund_id: refundId,
    refund_note: 'Customer requested refund',
    refund_speed: 'STANDARD'
  };

  this.http.post(`${this.cashfreeApiUrl}/pg/orders/${orderId}/refunds`, refundPayload, { headers: this.cashfreeHeaders })
    .subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') { // Check for success status
          console.log('Refund initiated successfully:', response);
          Swal.fire({
            icon: 'success',
            title: 'Refund Success',
            text: 'Refund initiated successfully. You will receive a confirmation email shortly.'
          });
        } else {
          console.error('Refund failed:', response.message);
          Swal.fire({
            icon: 'error',
            title: 'Refund Error',
            text: `Refund failed. Error: ${response.message}` // Provide specific error message
          });
        }
      },
      (error) => {
        console.error('Error initiating refund:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Refund Error',
          text: 'Failed to initiate refund. Please try again later.'
        });
      }
    );
}


}
