

// export var ProjParams = {
//   apptype:"YUVAHH",
//   appversion:'v2.3',
//   apiurl: 'https://api.xcibil.com/api/v1/',
//   apiurlNew: 'https://api.xcibil.com/api/',
//   clientUrl: 'https://api.xcibil.com/api/client/',
//   ApiId:'APmw2sAKlamAP',
//   TokenExpireStatus:'450',
//   voterIdStatus:'422',
//   ApiSecret:'GyaPaG23jaY5aaa',
//   base_url: "https://www.xcibil.com/",
//   imgBaseUrl : 'https://api.xcibil.com/'
// }

// export var ProjParams = {
//     apptype:"YUVAHH",
//     appversion:'v2.3',
//     apiurl: 'https://api.championscricket.co.in/api/v1/',
//     apiurlNew: 'https://api.championscricket.co.in/api/',
//     clientUrl: 'https://api.championscricket.co.in/api/client/',
//     ApiId:'APmw2sAKlamAP',
//     TokenExpireStatus:'450',
//     voterIdStatus:'422',
//     ApiSecret:'GyaPaG23jaY5aa',
//     base_url: "https://www.championscricket.co.in/",
//     imgBaseUrl : 'https://api.championscricket.co.in/'
// }

export var ProjParams = {
    apptype:"YUVAHH",
    appversion:'v2.3',
    apiurl: 'https://api.newpaytm.com/api/v1/',
    apiurlNew: 'https://api.newpaaytm.com/api/',
    clientUrl: 'https://api.newpaytm.com/api/client/',
    ApiId:'APmw2sAKlamAP',
    TokenExpireStatus:'450',
    voterIdStatus:'422',
    ApiSecret:'GyaPaG23jaY5as',
    base_url: "https://www.newpaytm.com/",
 imgBaseUrl : 'https://api.newpaytm.com/'
}
