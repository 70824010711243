import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { ProjParams } from 'src/app/config';
import { SharedService } from 'src/app/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-gst-filing',
  templateUrl: './search-gst-filing.component.html',
  styleUrls: ['./search-gst-filing.component.scss'],
})
export class SearchGstFilingComponent implements OnInit {
  isLoading: boolean;
  clientId: any;
  secretKey: any;
  clientIdUAT: any;
  secretKeyUAT: any;
  gstNumber: any;
  gstNumberUAT: any;

  response: any;
  show: boolean;
  showUAT: boolean;

  panDetailMessage: any;
  panDetailMessageUAT: any;

  showDetail: boolean = false;
  showDetailUAT: boolean = false;
  panDetail: any;
  panDetailUAT: any;

financialYear = 'Select a financial year';
financialYearUAT = 'Select a financial year';
  clientDetail: any;
panNumber: any;
panNumberUAT: any;


  constructor(private router: Router, private http: HttpClient, private communicationService: SharedService, private titleService: Title, private clientDetailService: ClientDetailsServiceService) {
   }

  ngOnInit() {
    this.titleService.setTitle('Search GST Filing Detail');
    this.generateFinancialYears();
    this.titleService.setTitle('Search GST Detail')
    this.clientDetailService.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
          this.clientId = this.clientDetail.client_id;
          this.secretKey = this.clientDetail.secret_key;
          this.clientIdUAT = this.clientDetail.sandbox_client_id;
          this.secretKeyUAT = this.clientDetail.sandbox_secret_key;
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );
  }


  onClientIdChange(event: any) {
    console.log(event);
  }

  searchGstFiling() {
    if (!this.validateForm()) {
      return;
    }
    
    // Proceed with your logic if all fields are filled
    
  
    this.isLoading = true;
    const url = ProjParams.apiurlNew + `public/apiRoute/searchGstFiling/?gstno=${this.panNumber}&fy=${this.financialYear}`;

  
    const headers = new HttpHeaders({
      'clientid': this.clientId,
      'secretkey': this.secretKey
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.response = response
        this.show = true
   

        if(response.message ===  'Service is Not Active ')
          {
          this.panDetailMessage = response.message;
          }
        else if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.panDetailMessage = response.message;
          }else {
            this.communicationService.sendApiHitSignal();

          this.showDetail = true;
          this.panDetail = response.data
          this.panDetailMessage = response.message;
        }
      },
      (error) => {
        this.show = true; 
        this.isLoading = false;
        this.panDetailMessage = error.error.message;

        console.error(error);
        if (error.error.status === 'Failed' && error.error.statusCode === 404 && error.error.message === 'GST not found') {
          this.panDetail = error.error.data
        
          this.panDetailMessage = error.error.message;
          this.communicationService.sendApiHitSignal();


          
        } 
      }
    );
  }


  searchGstFilingUAT() {
    if (!this.validateFormuat()) {
      return;
    }
    
    // Proceed with your logic if all fields are filled
    
  
    this.isLoading = true;
    const url = ProjParams.apiurlNew + `public/apiRoute/searchFilingGstUAT/?gstno=${this.panNumberUAT}&fy=${this.financialYearUAT}`;

  
    const headers = new HttpHeaders({
      'clientid': this.clientIdUAT,
      'secretkey': this.secretKeyUAT
    });
  
    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.response = response
        this.showUAT = true
   

        if(response.message ===  'Service is Not Active ')
          {
          this.panDetailMessageUAT = response.message;
          }
        else if(response.message ===  'Contact Support to recharge the Hits')
          {
          this.panDetailMessageUAT = response.message;
          }else {
            this.communicationService.sendApiHitSignal();

          this.showDetailUAT = true;
          this.panDetailUAT = response.data
          this.panDetailMessageUAT = response.message;
        }
      },
      (error) => {
        this.showUAT = true; 
        this.isLoading = false;
        this.panDetailMessage = error.error.message;

        console.error(error);
        if (error.error.status === 'Failed' && error.error.statusCode === 404 && error.error.message === 'GST not found') {
          this.panDetailUAT = error.error.data
        
          this.panDetailMessageUAT = error.error.message;
          this.communicationService.sendApiHitSignal();


          
        } 
      }
    );
  }

  hideUAT(){
    // this.clientIdUAT = '';
    // this.secretKeyUAT = '';
    this.showUAT = false;
    this.panDetailMessageUAT = '';
    this.showDetailUAT = false;
    this.panDetailUAT = '';
    this.gstNumberUAT = '';
    this.financialYearUAT = 'Select a financial year'
  }
  

  hide(){
    // this.clientId = '';
    // this.secretKey = '';
    this.show = false;
    this.panDetailMessage = '';
    this.showDetail = false;
    this.panDetail = '';
    this.gstNumber = '';
    this.financialYear = 'Select a financial year'
  }
  goBack(){
    this.router.navigate(['/api/gst-api']);
  }
  financialYears: string[] = [];

  generateFinancialYears() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Determine the latest financial year based on the current date
    // If the current month is January to March, use the previous year
    const latestYear = currentMonth < 3 ? currentYear : currentYear + 1;

    // Generate financial years starting from 2017-18 up to the latest year
    for (let year = latestYear; year >= 2018; year--) {
      this.financialYears.push(`${year - 1}-${year.toString().slice(-2)}`);
    }
    console.log('the financial years:', this.financialYears)
  }

   
  validateForm(): boolean {
    let isValid = true;

    isValid = this.validateField('clientId') && isValid;
    isValid = this.validateField('secretKey') && isValid;
    isValid = this.validateField('panNumber') && isValid;
    isValid = this.validateField('financialYear') && isValid;


    return isValid;
  }

  clientIdError: any;
  secretKeyError: any;
  panNumberError: any;
  financialYearError: any;

  validateField(field: string): boolean {
    console.log(field)

    let isValid = true;
    let value = '';
    let errorMessage = '';
    let isPAN = false;

    switch (field) {
      case 'clientId':
        value = this.clientId;
        errorMessage = 'Please enter a Client ID';
        this.clientIdError = '';
        break;
      case 'secretKey':
        value = this.secretKey;
        errorMessage = 'Please enter a Secret Key';
        this.secretKeyError = '';
        break;
      case 'panNumber':
        value = this.panNumber;
        if (!value) {
          errorMessage = 'Field is empty';
          isValid = false; // Mark the field as invalid
        } else {
          errorMessage = 'Invalid GST number';
          isPAN = true; // Set isPAN to true for further validation
          this.panNumberError = ''; // Clear any previous error message
        }
        break;
        case 'financialYear':
          value = this.financialYear;
          if (!value || value === 'Select a financial year') {
            errorMessage = 'Please select a year'

            isValid = false; // Mark the field as invalid
          } else {
            errorMessage = '';
            this.financialYearError = ''; // Clear any previous error message
          }
          break;
    }

    const element = document.getElementById(field + 'Input') as HTMLInputElement;

    if (isPAN && isValid) {
      isValid = this.validatePAN(value); // Validate PAN format if necessary
    }

    if (!isValid) {
      this.highlightField(element);
      this.setErrorMessage(field, errorMessage);
    } else {
      this.resetFieldStyle(element);
      this.setErrorMessage(field, '');
    }

    return isValid;
  }

  setErrorMessage(field: string, message: string) {
    switch (field) {
      case 'clientId':
        this.clientIdError = message;
        break;
      case 'secretKey':
        this.secretKeyError = message;
        break;
      case 'panNumber':
        this.panNumberError = message;
        break;
      case 'financialYear':
        this.financialYearError = message;
        console.log(this.financialYearError)
        break;
    }
  }
  validateFormuat(): boolean {
    let isValid = true;

    isValid = this.validateFielduat('clientIduat') && isValid;
    isValid = this.validateFielduat('secretKeyuat') && isValid;
    isValid = this.validateFielduat('panNumberuat') && isValid;
    isValid = this.validateFielduat('financialYearuat') && isValid;


    return isValid;
  }

  clientIdErroruat: any;
  secretKeyErroruat: any;
  panNumberErroruat: any;
  financialYearErroruat: any;
  validateFielduat(field: string): boolean {
    console.log(field)
    let isValid = true;
    let value = '';
    let errorMessageuat = '';
    let isPAN = false;
  
    switch (field) {
      case 'clientIduat':
        value = this.clientIdUAT;
        errorMessageuat = 'Please enter a Client ID';
        this.clientIdErroruat = '';
        break;
      case 'secretKeyuat':
        value = this.secretKeyUAT;
        errorMessageuat = 'Please enter a Secret Key';
        this.secretKeyErroruat = '';
        break;
      case 'panNumberuat':
        value = this.panNumberUAT;
        if (!value) {
          errorMessageuat = 'Field is empty';
          isValid = false; // Mark the field as invalid
        } else {
          errorMessageuat = 'Invalid GST number';
          isPAN = true; // Set isPAN to true for further validation
          this.panNumberErroruat = ''; // Clear any previous error message
        }
        break;
        case 'financialYearuat':
          value = this.financialYearUAT;
          if (!value || value === 'Select a financial year') {
            errorMessageuat = 'Please select a year'

            isValid = false; // Mark the field as invalid
          } else {
            this.financialYearErroruat = ''; // Clear error message if valid
            errorMessageuat = ''

          }
          break;
    }
  
    
    const elementuat = document.getElementById(field + 'Input') as HTMLInputElement;
  
    if (isPAN && isValid) {
      isValid = this.validatePANuat(value); // Validate PAN format if necessary
    }
  
    if (!isValid) {
      this.highlightFielduat(elementuat);
      this.setErrorMessageuat(field, errorMessageuat);
    } else {
      this.resetFieldStyleuat(elementuat);
      this.setErrorMessageuat(field, '');
    }
  
    return isValid;
  }

  setErrorMessageuat(field: string, message: string) {
    switch (field) {
      case 'clientIduat':
        this.clientIdErroruat = message;
        break;
      case 'secretKeyuat':
        this.secretKeyErroruat = message;
        break;
      case 'panNumberuat':
        this.panNumberErroruat = message;
        break;
        case 'financialYearuat':
          console.log(this.financialYearErroruat)
          this.financialYearErroruat = message;
          break;
    }
  }

  toUpperCase(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.gstNumber = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorder(event); // Call resetBorder here instead of in the template
  }

  resetBorder(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightField(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyle(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyle(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  toUpperCaseuat(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.gstNumberUAT = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorderuat(event); // Call resetBorder here instead of in the template
  }

  resetBorderuat(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  highlightFielduat(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 6px red';
  
    setTimeout(() => {
      this.resetFieldStyleuat(element);
    }, 3000); // Reset after 3 seconds
  }
  
  resetFieldStyleuat(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }


  validatePAN(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }

  validatePANuat(pan: string): boolean {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstinPattern.test(pan);
  }



}
