import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  ApiLoginModalComponent
} from '../../homepages/api-login-modal/api-login-modal.component';
import {
  Platform
} from '@ionic/angular';
import {
  FunctionsService
} from '../../functions.service';
import {
  AuthService,
} from '../../auth.service';
import {
  HttpClient,
} from '@angular/common/http';
import {
  DataService
} from '../../data.service';
import {
  CookieService
} from 'angular2-cookie';
import {
  ProjParams
} from '../../config';
import swal from 'sweetalert';
import {
  AuthGuard
} from '../../auth.guard';
import {
  EncryptionServiceService
} from 'src/app/encryption-service.service';
import {
  Title
} from '@angular/platform-browser';
Router
ActivatedRoute
NgbModal

@Component({
  selector: 'app-api-login',
  templateUrl: './api-login.component.html',
  styleUrls: ['./api-login.component.scss'],
})
export class ApiLoginComponent implements OnInit {
  username: any;
  password: any;
  userId: any;
  showPassword: boolean = false;
  isLoading: boolean = false;
  errors: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private platform: Platform,
    private fun: FunctionsService,
    private auth: AuthService,
    private http: HttpClient,
    private dataService: DataService,
    private cookieService: CookieService,
    private authGuard: AuthGuard,
    private encryptionService: EncryptionServiceService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle('XCIBIL - Login')
  }

  //to admin dashboard
  redirectToDashboard() {
    this.router.navigate(['/api/dashboard-new'])
  }

  //redirect to api page
  redirectToAllApis() {
    this.router.navigate(['./all-api-page'])
  }


  //open-modal forgot-password
  openModal() {
    const modalRef = this.modalService.open(ApiLoginModalComponent);
    modalRef.componentInstance.name = 'World';

    this.modalService.open(this.content, {
      centered: true
    });

  }
  content(content: any, arg1: {
    centered: true;
  }) {
    throw new Error('Method not implemented.');
  }




  signIn() {
    var email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var phoneRegex = /^[0-9]{10}$/;

    this.platform.ready().then(() => {


      if (!email.test(this.userId) && !phoneRegex.test(this.userId)) {
        swal("Error", "Please enter correct Email or Phone Number", "info");
        return;
      }

      const payload = {
        password: this.password,
        userId: this.userId
      };

      var res_data = null;
      var url = ProjParams.apiurl + 'apiPanel/login';
      this.isLoading = true;

      this.http.post(url, payload).subscribe(
        (data) => {
          this.isLoading = false;
          res_data = data;
          this.router.navigateByUrl('/all-api-page');
        },
        (err) => {
          this.isLoading = false;
          this.errors = err.error.message;
          swal("Info", this.errors, "info");

          this.router.navigateByUrl('./api-login');
        },
        async () => {
          if (res_data.statusCode === 200) {
            localStorage.setItem('userIsLoggedIn', 'true');
            localStorage.setItem("newUserAuthKey", res_data.data.token);

            if (!res_data.data.isPasswordChange) {
              const encryptedEmail = this.encryptionService.encrypt(res_data.data.userEmail);

              this.router.navigate(['/password-reset', encryptedEmail]);
            } else {
              this.router.navigate(['/all-api-page']);

            }
          }
        });
    });
  }


  isInvalidEmail: boolean = false;

  validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(this.userId)) {
      this.isInvalidEmail = true;
    } else {
      this.isInvalidEmail = false;
    }
  }

  clearEmailErrorMessage() {
    this.isInvalidEmail = false;
  }


  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }



}