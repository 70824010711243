import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ServerStatusService } from './server-status.service'; // Adjust the import path as per your actual service location

@Injectable({
  providedIn: 'root'
})
export class ServerGuard implements CanActivate {

  constructor(private serverStatusService: ServerStatusService, private router: Router) {}

  canActivate(): boolean {
    const isRunning = localStorage.getItem('server')
    if (isRunning && isRunning != 'running') {
      console.log('the server is not running will be redirecting to error page...')
      this.router.navigate(['/error']); // Redirect to server-down page or handle as needed
      return false; // Prevent navigation to the protected route
    }

    return true; // Allow navigation to the protected route if server is running
  }
}
