import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjParams } from 'src/app/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private http: HttpClient) { }

  ngOnInit() {}
  showPassword: boolean = false;


    oldPassword: any;
    newPassword: any;
 
  confirmPassword: any;

   //close modal icon logic
   closeModal() {
    this.activeModal.close('Modal closed');
  }

   //open-profile-modal
   openSettingsModal() {
    const modalRef = this.modalService.open(SettingsModalComponent, { size: 'xl'});
    modalRef.componentInstance.name = 'World';
    this.modalService.open(this.content, { centered: true });
  }
  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }

  previewSelectedImage(event: Event) {
    const input = event.target as HTMLInputElement;
    const previewImage = document.getElementById('previewImage') as HTMLImageElement;

    const file = input.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (previewImage) {
          previewImage.src = e.target?.result as string;
        }
      }
    }
  }

  removeImage() {
    const previewImage = document.getElementById('previewImage') as HTMLImageElement;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;

    if (previewImage) {
      previewImage.src = '';
    }
    
    if (input) {
      input.value = '';
    }
  }

  activeContent: 'profile' | 'password-email' = 'profile';

  toggleContent(content: 'profile' | 'password-email') {
    this.activeContent = content;
  }

  clientToken = localStorage.getItem('newUserAuthKey');




  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };

  updatePassword() {
    // console.log(this.newPassword);
    // console.log(this.confirmPassword);

    if (!this.newPassword || !this.confirmPassword) {
      Swal.fire('Info', 'Enter your new password and confirm it.', 'info');
      console.error('New password and confirm password do not match');
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      console.error('New password and confirm password do not match');
      return;
    }
  
    var url = ProjParams.clientUrl + 'xb/apiPanel/changePassword';
    const patchData = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
      // Add any other fields as needed
    };
  
    // Send the PATCH request
    this.http.patch(url, patchData, this.httpOptionsClient)
      .subscribe(
        (response) => {
          // console.log('Password updated successfully:', response);
          Swal.fire('Success', 'Password updated successfully', 'success');
        },
        (error) => {
          console.error('Error updating password:', error);
          Swal.fire('Info', 'Password was not updated', 'info');

        }
      );
  }
  
  toggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

  

}
