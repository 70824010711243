import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GstAuthComponent } from './gst-auth.component';

@NgModule({
    imports: [ RouterModule, CommonModule, FormsModule],
    declarations: [ GstAuthComponent],
    exports: [GstAuthComponent]
})

export class GstAuthModule {}
 