import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ApiLoginComponent } from './api-login.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ApiLoginComponent]
})
export class ApiLoginModule {}
