import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sms-card-filter',
  templateUrl: './sms-card-filter.component.html',
  styleUrls: ['./sms-card-filter.component.scss'],
})
export class SmsCardFilterComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {}

  //close modal icon logic
  closeModal() {
    this.activeModal.close('Modal closed');
  }
}
