import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { QueryDetailComponent } from './query-detail.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbPaginationModule
  ],
  declarations: [QueryDetailComponent]
})
export class QueriesComentModule {}
