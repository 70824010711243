import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProjParams } from 'src/app/config';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import Swal from 'sweetalert2';
import { NavbarProfileModalComponent } from '../navbar-profile-modal/navbar-profile-modal.component';

@Component({
  selector: 'app-query-detail',
  templateUrl: './query-detail.component.html',
  styleUrls: ['./query-detail.component.scss'],
})
export class QueryDetailComponent implements OnInit {
  clientToken = localStorage.getItem('newUserAuthKey');
  isLoading: boolean = false;

    httpOptionsClient = {
    headers: new HttpHeaders({
        'clientauth':this.clientToken
      }) 
    };
  queries: any;
  queryId: number;
  queryComments: any;
  queryData: any;
  msg: any;
  clientDetails: any;
  currentDate: Date;
queriesCount: any;
  selectedStatus: any;
  isMobileView: boolean = false;

  constructor(private http: HttpClient, private route: ActivatedRoute, private location: Location, private flagService: DashboardFlagService, private titleService: Title, private modalService: NgbModal) { }

  ngOnInit() {
    this.checkScreenSize(); // Initial check when the component loads

    this.flagService.setDashboardFlag(true);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(false);

    this.flagService.sendMessage('Load');
    this.route.params.subscribe(params => {
      this.queryId = +params['id'];
    });

    var countUrl = ProjParams.clientUrl + 'xb/apiPanel/getQueryCounts';


    this.http.get(countUrl, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.queriesCount = data.data;
          console.log(this.queriesCount);
        } else {
          console.error('Invalid data format:', data);
        }
      },
      
      (error) => {
        if (error.error.message) {
          console.error('Error:', error); 
        }          console.error('Error:', error);
      }
    );

    var clientDetailUrl = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 

    this.http.get(clientDetailUrl, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.clientDetails = data.data;
        } else {
          console.error('Invalid data format:', data);
        }
      },
      
      (error) => {
        const errorMessage = error.message;
        console.error('Error:', error);
      }
    );

    this.getQuery();
    this.startPolling();


  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobileView = window.innerWidth < 600; // Adjust the width as per your breakpoint
  }

  
  ngOnDestroy(): void {
    this.stopPolling(); // Clean up the interval
  }


  getQuery(){
    var url = ProjParams.clientUrl + 'xb/apiPanel/getQuery';

  this.isLoading = true;
    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.isLoading = false;
          this.queries = data.data;
          this.queryData = this.queries.filter(log => log.id === this.queryId);
          this.queryComments = this.queryData[0].api_query_comments;
          this.selectedStatus = this.queryData[0].status || '';
          const date = new Date(this.queryData[0].createdAt);
          this.queryData[0].createdAt = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          });
          this.titleService.setTitle(`Queries | ${this.queryData[0].query_id}`)

        } else {
          console.error('Invalid data format:', data);
          this.isLoading = false;

        }
      },
      
      (error) => {
        this.isLoading = false;
        if (error.error.message) {
          console.error('Error:', error); 
        }          console.error('Error:', error);
      }
    );
  }

  loadComments(){
    var url = ProjParams.clientUrl + 'xb/apiPanel/getQuery';

    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.queries = data.data;
          this.queryData = this.queries.filter(log => log.id === this.queryId);
          this.queryComments = this.queryData[0].api_query_comments;
          const date = new Date(this.queryData[0].createdAt);
          this.queryData[0].createdAt = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          });
        } else {
          console.error('Invalid data format:', data);

        }
      },
      
      (error) => {
        if (error.error.message) {
          console.error('Error:', error); 
        }          console.error('Error:', error);
      }
    );
  }

  goBack(){
    this.location.back();
  }
  msgError: boolean = false;
  
// Inside your component class
onInputChange() {
  this.msgError = false;
  this.resetBorder();
}

resetBorder() {
  const inputElement = document.querySelector('.comment-input') as HTMLElement;
  if (inputElement) {
    inputElement.style.borderBottom = '2px solid #aeadad';
    inputElement.style.boxShadow = 'none';
  }
}

createComment() {
  if (!this.msg) {
    this.msgError = true;
    this.highlightBorder();
    return;
  }

  this.msgError = false;
  const apiUrl = ProjParams.clientUrl + 'xb/apiPanel/addComment'; 

  const payload = {
    query_id: this.queryId,
    msg: this.msg                                        
  };

  console.log(payload);
  this.http.post(apiUrl, payload, this.httpOptionsClient).subscribe(
    (data: any) => {
      if (data && data.data) {
        this.msg = '';
        this.loadComments();
      } else {
        console.error('Invalid data format:', data);
      }
    },
    (error) => {
      this.isLoading = false;
      if (error.error.message) {
        console.error('Error:', error); 
      }
    }
  );
}

highlightBorder() {
  const inputElement = document.querySelector('.comment-input') as HTMLElement;
  if (inputElement) {
    inputElement.style.borderBottom = '1px solid red';
    inputElement.style.boxShadow = '0 2px 2px -2px red';
  }
}

@ViewChild('commentsSection') private commentsSection: ElementRef;


ngAfterViewChecked() {
  this.scrollToBottom();
}

scrollToBottom(): void {
  try {
    this.commentsSection.nativeElement.scrollTop = this.commentsSection.nativeElement.scrollHeight;
  } catch (err) {
    console.error('Scroll to bottom failed:', err);
  }
}

openDocument(url: string) {
  if (!this.queryData[0].attach_url) {
    Swal.fire({
      text: 'No document attached',
      icon: 'info',
      confirmButtonText: 'Okay',
      timer: 3000,
      timerProgressBar: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
    return;
  }
  const fullUrl = ProjParams.imgBaseUrl + url;
  window.open(fullUrl, '_blank');
  console.log(fullUrl)
}

private intervalId: any;

startPolling(): void {
  this.intervalId = setInterval(() => {
    this.loadComments();
  }, 120000); // 3 minutes
}

stopPolling(): void {
  if (this.intervalId) {
    clearInterval(this.intervalId);
  }
}

getFormattedDateWithDay(): string {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString('en-GB', { month: 'short' });
  const year = currentDate.getFullYear();

  return `${day} ${month} ${year}`;
}


openProfileModal() {
  this.modalService.open(NavbarProfileModalComponent, {windowClass: 'custom-modal15px'});
 
   }


}
