import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef
} from '@angular/core';

import {
  Router
} from '@angular/router';
import {
  DatePipe,
  Location
} from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarAddMoneyModalComponent } from '../navbar-add-money-modal/navbar-add-money-modal.component';
import { NavbarProfileModalComponent } from '../navbar-profile-modal/navbar-profile-modal.component';
import { DataService } from 'src/app/data.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjParams } from 'src/app/config';
import { FlagService } from 'src/app/flag.service';
import { SharedService } from 'src/app/shared.service';
import { Subscription } from 'rxjs';
import { ClientDetailsServiceService } from 'src/app/client-details-service.service';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';

Router

@Component({
  selector: 'app-navbar-new',
  templateUrl: './navbar-new.component.html',
  styleUrls: ['./navbar-new.component.scss'],
  providers: [DatePipe]
})
export class NavbarNewComponent implements OnInit {
  currentDate: Date = new Date();
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton: Element;
  private sidebarVisible: boolean;
  addBoxShadow=false;
  public isCollapsed = true;
  serviceList: any[] = [];

  
  @ViewChild("navbar-cmp", {
    static: false
  }) button;
  responseData: any[] = [];
  clientToken = localStorage.getItem('newUserAuthKey');

  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };
  dashboard: boolean;
  banking: boolean;
  pan: boolean;
  xcibil: boolean;
  totalBal: any; 
  private apiHitSubscription: Subscription;
  gst: boolean;
  serviceDetail: any;
  subscription: any;
isLoading: any;
  userDetail: any;


  constructor(location: Location, private renderer: Renderer2, private element: ElementRef, private router: Router, private datePipe: DatePipe, private modalService: NgbModal, private dataService: DataService, private http: HttpClient, private flagService: FlagService,
    private sharedService: SharedService, private clientdetailsservice: ClientDetailsServiceService, private communicationService: DashboardFlagService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;


    this.apiHitSubscription = this.sharedService.apiHit$.subscribe(() => {
      this.getServiceList(); // Call your method here
    });

    

  }

    pageFlag: string;
    ngOnInit() {
      this.flagService.currentName.subscribe(name => {
        this.pageFlag = name;
      });

      this.subscription = this.communicationService.actionEvent.subscribe((message: string) => {
        if (message === 'Load') {
          this.dashboard = localStorage.getItem('isDashboard') === 'true';
          this.banking = localStorage.getItem('isBanking') === 'true';
          this.pan = localStorage.getItem('isPanApi') === 'true';
          this.xcibil = localStorage.getItem('isXcibil') === 'true';
          this.gst = localStorage.getItem('isGst') === 'true';
        }
      });

   
    


  
    this.clientdetailsservice.getClientServiceDetail().subscribe(
      (data) => {
        if (data) {
          this.serviceDetail = data.data;
          
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    ); 

    this.clientdetailsservice.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.userDetail = data.data;
          
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    ); 

    // this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });

      // console.log('Token from local storage for client list:', this.adminToken);
      var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 

      this.http.get(url, this.httpOptionsClient).subscribe(
        (data: { data: any[] }) => {
          this.responseData = data.data;
          // console.log(this.responseData);
        },
        
        (error) => {
          Swal.fire("Info", "Problem fetching data from server, make sure you are connected to internet", "info");
          console.error('Error:', error);
        }
      );
      this.getServiceList();

      
      this.sharedService.flagObservable$.subscribe(flag => {
        if (flag) {
          this.getServiceList();
        }
      });
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }
    sidebarToggle() {
      if (this.sidebarVisible === false) {
        this.sidebarOpen();
      } else {
        this.sidebarClose();
      }
    }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = < HTMLElement > document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = < HTMLElement > document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    // console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }
  onCollapse() {
    this.addBoxShadow = true;
  }
  
  getFormattedDateWithDay() {
    return this.datePipe.transform(this.currentDate, 'EEEE, d MMM yyyy');
  }

  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }

  openAddMoneyModal() {
    const modalRef = this.modalService.open(NavbarAddMoneyModalComponent, { windowClass: "custom-modal10px" });
    modalRef.componentInstance.serviceDetail = this.serviceDetail;
  
    modalRef.componentInstance.updateSuccessEvent.subscribe(() => {
      this.serviceList = [];
      this.getServiceList();
      Swal.fire('Success', 'Amount added to wallet successfully', 'success');
    });

  }
  

  openAddMoneyModal1() {
     this.modalService.open(NavbarAddMoneyModalComponent,   { size: 'lg', windowClass: 'custom-modal15px'});
  }

  openProfileModal() {
   this.modalService.open(NavbarProfileModalComponent, {windowClass: 'custom-profile-modal'});

  }


  getServiceList() {
    var url = ProjParams.clientUrl + 'xb/apiPanel/getService';
    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: { data: any[] }) => {
        this.serviceList = data.data;
  
        this.totalBal = 0; 
  
        for (let index = 0; index < this.serviceList.length; index++) {
          const element = this.serviceList[index];
      
          if (element.api_user_service && element.api_user_service.hit_amount) {
              this.totalBal += parseFloat(element.api_user_service.hit_amount);
          }
        }
        
      },
      
      (error) => {
        Swal.fire("Info", "Problem fetching data from server, make sure you are connected to internet", "info");
        console.error('Error:', error);
      }
    );
  }
  


}