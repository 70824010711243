import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProjParams } from 'src/app/config';
import { DataService } from 'src/app/data.service';
import Swal from 'sweetalert2';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';

@Component({
  selector: 'app-creden-and-bill',
  templateUrl: './creden-and-bill.component.html',
  styleUrls: ['./creden-and-bill.component.scss'],
})
export class CredenAndBillComponent implements OnInit {
  responseData: any[];
  clientDetails: any = {};
  serviceLogs: any[] = [];
  serviceUATLogs: any[] = [];
  clientToken = localStorage.getItem('newUserAuthKey');
  isLoading: boolean = false;

    httpOptionsClient = {
    headers: new HttpHeaders({
        'clientauth':this.clientToken
      }) 
    };
  dashboard: any;
  banking: any;
  pan: any;
  xcibil: any;
  gst: boolean;

  constructor(private router: Router, private dataService: DataService, private http: HttpClient, private location: Location, private titleService: Title, private flagService: DashboardFlagService) { }

  ngOnInit() {
    this.titleService.setTitle('Credentials and Billing')
    this.dashboard = localStorage.getItem('isDashboard') === 'true';
    this.banking = localStorage.getItem('isBanking') === 'true';
    this.pan = localStorage.getItem('isPanApi') === 'true';
    this.xcibil = localStorage.getItem('isXcibil') === 'true';
    this.gst = localStorage.getItem('isGst') === 'true';


// Set flags based on conditions
if (this.dashboard) {
  this.flagService.setDashboardFlag(true);
} else {
  this.flagService.setDashboardFlag(false);
}

if (this.banking) {
  this.flagService.setBankingFlag(true);
} else {
  this.flagService.setBankingFlag(false);
}

if (this.pan) {
  this.flagService.setPanApiFlag(true);
} else {
  this.flagService.setPanApiFlag(false);
}

if (this.xcibil) {
  this.flagService.setXcibilFlag(true);
} else {
  this.flagService.setXcibilFlag(false);
}

if (this.gst) {
  this.flagService.setGstFlag(true);
} else {
  this.flagService.setGstFlag(false);
}

      this.isLoading = true;
      var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail';

  
      this.http.get(url, this.httpOptionsClient).subscribe(
        (data: any) => {
          if (data && data.data) {
            this.isLoading = false;
            this.clientDetails = data.data;
          } else {
            console.error('Invalid data format:', data);
          }
        },
        
        (error) => {
          this.isLoading = false;
          if (error.error.message) {
            console.error('Error:', error); 
          }          console.error('Error:', error);
        }
      );

      this.getServiceLogs();
      this.getServiceUATLogs();

  }

  goBack(){
    this.location.back()
  }


  getServiceLogs() {
    var logsUrl = ProjParams.clientUrl + 'xb/apiPanel/serviceLogs';
    this.http.get(logsUrl, this.httpOptionsClient).subscribe(
      (data: { data: any[] }) => {
        this.serviceLogs = data.data;
  
        // Check the values from local storage and filter serviceLogs accordingly
        if (this.pan) {
          this.serviceLogs = this.serviceLogs.filter(log => log.service_id === 1);
        } else if (this.banking) {
          this.serviceLogs = this.serviceLogs.filter(log => log.service_id === 3);
        } else if (this.xcibil) {
          this.serviceLogs = this.serviceLogs.filter(log => log.service_id === 2);
        }

        else if (this.gst) {
          this.serviceLogs = this.serviceLogs.filter(log => log.service_id === 4);
        }
  
        this.serviceLogs = this.serviceLogs.filter(log => log.service_status === 'Credit');
      },
      
      (error) => {
        if (error.error.message) {
          console.error('Error:', error); 
        }
        console.error('Error:', error);
      }
    );
  }
  

  getServiceUATLogs() {
    var logsUrl = ProjParams.clientUrl + 'xb/apiPanel/serviceUATLogs';
    this.http.get(logsUrl, this.httpOptionsClient).subscribe(
      (data: { data: any[] }) => {
        this.serviceUATLogs = data.data;

        if (this.pan) {
          this.serviceUATLogs = this.serviceUATLogs.filter(log => log.service_id === 1);
        } else if (this.banking) {
          this.serviceUATLogs = this.serviceUATLogs.filter(log => log.service_id === 3);
        } else if (this.xcibil) {
          this.serviceUATLogs = this.serviceUATLogs.filter(log => log.service_id === 2);
        }  else if (this.gst) {
          this.serviceUATLogs = this.serviceUATLogs.filter(log => log.service_id === 4);
        }
        this.serviceUATLogs = this.serviceUATLogs.filter(log => log.service_status === 'Credit');
      },
      
      (error) => {
        if (error.error.message) {
          console.error('Error:', error.error.message); 
        }        
      }
    );
  } 

  activeTab: string = 'sandbox'; 
  apiDocTab: boolean = false;

  switchTab(tab: string): void {
    this.activeTab = tab;
    if (tab == 'api-doc') {
      this.apiDocTab = true;
    } else{
      this.apiDocTab = false;
    }
  }

  copied11: boolean = false;
  copyToClipboard11() {
    const textToCopy = this.clientDetails.sandbox_client_id || '--';

    // Create a temporary textarea to copy the text to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    this.copied11 = true;

    setTimeout(() => {
      this.copied11 = false;
    }, 2000);

    // Provide some visual feedback to the user (you can customize this part)
    // alert('Text copied to clipboard!');
  }

  copied12: boolean = false;

  copyToClipboard12() {
    const textToCopy = this.clientDetails.sandbox_secret_key || '--';

    // Create a temporary textarea to copy the text to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    this.copied12 = true;
    setTimeout(() => {
      this.copied12= false;
    }, 2000);

    // Provide some visual feedback to the user (you can customize this part)
    // alert('Text copied to clipboard!');
  }

  copied21: boolean = false;

  copyToClipboard21() {
    const textToCopy = this.clientDetails.client_id || '--';

    // Create a temporary textarea to copy the text to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    this.copied21 = true;
    setTimeout(() => {
      this.copied21 = false;
    }, 2000);

    // Provide some visual feedback to the user (you can customize this part)
    // alert('Text copied to clipboard!');
  }

  copied22: boolean = false;

  copyToClipboard22() {
    const textToCopy = this.clientDetails.secret_key || '--';

    // Create a temporary textarea to copy the text to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    this.copied22 = true;

    setTimeout(() => {
      this.copied22 = false;
    }, 2000);

    // Provide some visual feedback to the user (you can customize this part)
    // alert('Text copied to clipboard!');
  }


  downloadPDF() {
    this.http.get('assets/XCIBIL\'%20API%20documentation.pdf', { responseType: 'blob' }).subscribe(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'XCIBIL_API_documentation.pdf';
      link.click();
    });
  }

  
  

}
