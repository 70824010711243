import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceTableSearchBoxComponent } from '../invoice-table-search-box/invoice-table-search-box.component';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import html2pdf from 'html2pdf.js';
import { ProjParams } from 'src/app/config';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { RaiseQueryComponent } from '../raise-query/raise-query.component';

Router
ActivatedRoute






@Component({
  selector: 'app-invoice-api-new',
  templateUrl: './invoice-api-new.component.html',
  styleUrls: ['./invoice-api-new.component.scss'],
})
export class InvoiceApiNewComponent implements OnInit {
  isLoading : boolean = false;
  currentDate: string;
  dateAfterOneMonth: string;
  clientDetails: any;
  companyName: any;
  gstNo: any;
  address: any;

  constructor(private route: ActivatedRoute, private modalService: NgbModal, private flagService: DashboardFlagService, private http: HttpClient, private router: Router, private titleService: Title) {
    this.getCurrentDate();
    this.calculateDateAfterOneMonth();
   }
  shouldDisplayAlert: boolean = false; 
  showInput: boolean = false;
  isFlagTrue: boolean = false;



  ngOnInit() {
    this.titleService.setTitle('XCIBIL - Invoices')

    this.flagService.setDashboardFlag(true);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setBankingFlag(false);
    this.flagService.setGstFlag(false);

    this.flagService.sendMessage('Load');


    this.getInvoiceList();
    this.clientDetail()
  }

  getCurrentDate() {
    const currentDate = new Date();
    this.currentDate = this.formatDate(currentDate);
  }

  calculateDateAfterOneMonth() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    this.dateAfterOneMonth = this.formatDate(currentDate);
  }

  formatDate(date: Date): string {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = this.padNumber(date.getDate());
    return `${day} ${month}, ${year}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }



  currentPage = 1;
  pageSize = 10; 

  get paginatedData() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.invoiceList.slice(start, end);
  }


    
  get totalNumberOfPages(): number {
    const totalItems = this.invoiceList.length;
    return Math.ceil(totalItems / this.pageSize);
  }

  onPrevClick() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
  onNextClick() {
    if (this.currentPage < this.totalNumberOfPages) {
      this.currentPage++;
    }
  }
  
  onPageSizeChange() {
    // Reset to the first page when the page size changes
    this.currentPage = 1;
  
    // this.isOverflowEnabled = this.pageSize !== 10;
    
  }


  openInvoiceTableSearchBoxModal() {
    const modalRef = this.modalService.open(InvoiceTableSearchBoxComponent, { size: 'lg',});
    modalRef.componentInstance.name = 'World';

    this.modalService.open(this.content, { centered: true });
  }
  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }



  clientToken = localStorage.getItem('newUserAuthKey');




  httpOptionsClient = {
   headers: new HttpHeaders({
      'clientauth':this.clientToken
    }) 
  };


  invoiceList: any;
invoiceItems: any[] = [];
invoiceMonth: any;
invoiceYear: any;
invoiceId: any;
invoiceNo: any;




getInvoiceList() {
  this.isLoading = true;
  const logsUrl = ProjParams.clientUrl + 'xb/apiPanel/invoiceList';
  
  this.http.get(logsUrl, this.httpOptionsClient).pipe(
    map((data: { data: any[] }) => {
      this.isLoading = false;
      this.isFlagTrue = false;
      this.invoiceList = data.data;
      return data.data;      
    }),
    catchError((error) => {
      this.isLoading = false;
      this.isFlagTrue = false;
      console.error('Error:', error);
      return throwError("There was a problem getting data from the server");
    }),
    finalize(() => {
      this.isLoading = false;
      this.isFlagTrue = false;
    })
  ).subscribe((invoiceList) => {
    this.invoiceList = invoiceList;
    
    if (this.invoiceList?.length > 0) {
      this.invoiceItems = []; 
    
      for (const invoice of this.invoiceList) {
        const invoiceMonth = invoice.invoice_month;
        const invoiceYear = invoice.invoice_year;
        const invoiceId = invoice.bill_no;
    
        this.invoiceYear = invoiceYear;
        this.invoiceMonth = invoiceMonth;
        this.invoiceId = invoiceId;
    
        for (const item of invoice.api_invoice_items) {
          this.invoiceItems.push({
            invoiceMonth: invoiceMonth,
            invoiceYear: invoiceYear,
            invoiceId: item.invoice_id,
            description: item.description,
            totalAmount: item.total_amount
          });
        }
      }
    }
    
  });
}




clientDetail(){
  var url = ProjParams.clientUrl + 'xb/apiPanel/clientUserDetail'; 

  this.http.get(url, this.httpOptionsClient).subscribe(
    (data: any) => {
      if (data && data.data) {
        this.clientDetails = data.data;
        this.companyName = this.clientDetails?.company_name;
        this.gstNo = this.clientDetails?.gst_no;
        this.address = this.clientDetails?.address;

      } else {
        console.error('Invalid data format:', data);
      }
    },
    
    (error) => {
      const errorMessage = error.message;
      if (error.error.message) {
        Swal.fire("Error", error.error.message , "error");
        console.error('Error:', error); 
      }      console.error('Error:', error);
    }
  );
}

navigateToInvoice(id: any){
  this.router.navigate(['/api/invoice/view-invoice', id]);
}

generatePDF(id: number) {
  this.invoiceNo = id;
  if (!this.invoiceList) {
    console.error('Invoice data is not available.');
    
    return;
  }

  const invoice = this.invoiceList.find(invoice => invoice.id === this.invoiceNo);

  // Load the HTML template
  this.http.get('assets/invoice-template.html', { responseType: 'text' }).subscribe((htmlTemplate: string) => {
    let htmlContent = htmlTemplate;

    const invoiceDate = new Date(invoice.createdAt);
    // const amountInWords = this.convertToWords(invoice.total_amount);


const createdAt = new Date(invoice.createdAt);

const dateAfterMonth = new Date(createdAt);
dateAfterMonth.setDate(dateAfterMonth.getDate() + 30);


function formatDate(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}
const formattedDateAfterMonth = formatDate(dateAfterMonth);
const gstText = 'GSTIN';

    const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
    let gstTextWithNo = '';
    if (this.gstNo) {
      gstTextWithNo = `${gstText} ${this.gstNo}`;
    }

    // Replace placeholders in the HTML content
    htmlContent = htmlContent
      .replace('{{sacCode}}', invoice.sac_code)
      .replace('{{billNo}}', invoice.bill_no)
      .replace('{{invoiceDate}}', formattedInvoiceDate)
      .replace('{{invoiceMonth}}', invoice.invoice_month)
      .replace('{{invoiceYear}}', invoice.invoice_year)
      .replace('{{totalAmountWithoutTax}}', parseFloat(invoice.without_gst_amount).toFixed(2))
      .replace('{{totalAmountWithoutTax1}}', parseFloat(invoice.without_gst_amount).toFixed(2))
      .replace('{{cgstAmount}}', invoice.cgst_amount)
      .replace('{{sgstAmount}}', invoice.sgst_amount)
      .replace('{{totalAmountAfterTax}}', invoice.total_amount)
      // .replace('{{amountInWords}}', amountInWords)
      .replace('{{dateAfterMonth}}', formattedDateAfterMonth)
      .replace('{{address}}', this.address)
      .replace('{{address1}}', this.address)
      .replace('{{companyName}}', this.companyName)
      .replace('{{companyName1}}', this.companyName)
      .replace('{{gstTextWithNo}}', gstTextWithNo)
      .replace('{{gstTextWithNo1}}', gstTextWithNo); 



    let tableRows = '';
    let serialNumber = 1; 
    invoice.api_invoice_items.forEach((apiInvoiceItem) => {
      
        tableRows += `
          <tr>
            <td class="custom-text3"  style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${serialNumber}</td>
            <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.description || '--'}</td>
            <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem;">${invoice.sac_code || '--'}</td>
            <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.hit_count || '--'}</td>
            <td class="custom-text3"   style="border-right: 2px solid black; vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.hit_charge || '--'}</td>
            <td class="custom-text3"   style=" vertical-align: top; padding: 0.5rem; ">${apiInvoiceItem.total_amount || '--'}</td>
          </tr>`;
          serialNumber++; 

      });
      

    htmlContent = htmlContent.replace('{{tableRows}}', tableRows);

    const opt = {
      // margin: 0.5,
      filename: 'invoice.pdf',
      // image: { type: 'jpg', quality: 2 },
      // jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait', dpi: 1200 },
      // html2canvas: { logging: true, scrollX: 0, scrollY: 0 },
    //   pagebreak: { mode: ['avoid-all']
    //  }
    };

    html2pdf().from(htmlContent).output('dataurlnewwindow');
  });

  
}
 
resetData() {
  this.isLoading = true;
  this.invoiceList = []; // Reset data
}

reload() {
  this.isFlagTrue = true;
  this.resetData(); // Reset data
  this.getInvoiceList(); // Fetch data again
}

openRaiseQueryModal() {
this.modalService.open(RaiseQueryComponent, {windowClass: 'custom-modal20px'});
}



}