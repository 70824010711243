import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CredenAndBillComponent } from './creden-and-bill.component';



@NgModule({
  imports: [
    FormsModule,
    CommonModule
  ],
  declarations: [CredenAndBillComponent]
})
export class CredenAndBillModule {}
