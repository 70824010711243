import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreXcibilReportService {
  panDate: any;
  history: any;
  panNumber: any

  constructor() { }

  panuatDetail: any; // Define panuatDetail property

  // Method to set panuatDetail
  setPanuatDetail(data: any) {
    this.panuatDetail = data;
  }

  setDate(date: any) {
    this.panDate = date;
  }

  setPan(panNumber: any) {
    this.panNumber = panNumber;
  }
}
