import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsCardFilterComponent } from '../sms-card-filter/sms-card-filter.component';
import { HistoryTableFilterComponent } from '../history-table-filter/history-table-filter.component';
import { HistoryTableSearchBoxComponent } from '../history-table-search-box/history-table-search-box.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjParams } from 'src/app/config';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DashboardFlagService } from 'src/app/dashboard-flag.service';
Router
ActivatedRoute
NgbModal
SmsCardFilterComponent

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss'],
})
export class DashboardNewComponent implements OnInit {
  public canvas : any;
  public ctx;
  public chartColor;
  public chartEmail;

  public chartHours;
  responseData: any[];
  countList: any[] = [];
  serviceLogs: any[] = [];
showInput: boolean = false;
  isLoading: boolean = false;

  clientToken = localStorage.getItem('newUserAuthKey');




   httpOptionsClient = {
    headers: new HttpHeaders({
       'clientauth':this.clientToken
     }) 
   };


  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private http: HttpClient, private flagService: DashboardFlagService){


   }
  shouldDisplayAlert: boolean = false; // Set this to false

  ngOnInit() {
this.getServiceLogs();
this.getDashboardCounts();
this.flagService.setDashboardFlag(true);
this.flagService.setPanApiFlag(false);
this.flagService.setXcibilFlag(false);
this.flagService.setBankingFlag(false);
this.flagService.sendMessage('Load');

    
  }



  currentPage = 1;
  pageSize = 6;

  get  paginatedData() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.serviceLogs.slice(start, end);
  }

  //open-sms-filter-modal
  openSmsFilterModal() {
    const modalRef = this.modalService.open(SmsCardFilterComponent);
    modalRef.componentInstance.name = 'World';

    this.modalService.open(this.content, { centered: true });

  }
  content(content: any, arg1: { centered: true; }) {
    throw new Error('Method not implemented.');
  }

  //open-table-filter
  openTableFilter() {
    const modalRef = this.modalService.open(HistoryTableFilterComponent);
    modalRef.componentInstance.name = 'World';

    this.modalService.open(this.content, { centered: true });
  }

  //open-table-filter
  openHistoryTableSearchModal() {
    const modalRef = this.modalService.open(HistoryTableSearchBoxComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.name = 'World';

    this.modalService.open(this.content, { centered: true });
  }

  getServiceLogs() {
    this.isLoading = true;
    const logsUrl = ProjParams.clientUrl + 'xb/apiPanel/serviceLogs';
    
    this.http.get(logsUrl, this.httpOptionsClient).pipe(
      map((data: { data: any[] }) => {
        // this.serviceLogs = data.data;
            return data.data        
      }),
      catchError((error) => {
        console.error('Error:', error);
        return throwError("There was a problem getting data from the server");
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe((serviceLogs) =>{
      this.serviceLogs = serviceLogs;
    }
    );
  }


  
  getDashboardCounts(): void {
    this.isLoading = true;
    const url = ProjParams.clientUrl + 'xb/apiPanel/apiHitCounts';
  
  
    this.http.get(url, this.httpOptionsClient).pipe(
      map((data: any) => {
        this.countList = data.data;
        // console.log('Dashboard counts retrieved successfully:', this.countList);
      }),
      catchError((error) => {
        console.error('Error:', error);
        return throwError('Could not retrieve data from the server');
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe();
  }

  
}
