import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  ProjParams
} from 'src/app/config';
import Swal from 'sweetalert2';
import {
  DashboardFlagService
} from 'src/app/dashboard-flag.service';
import {
  Router
} from '@angular/router';
import {
  Title
} from '@angular/platform-browser';
import {
  ClientDetailsServiceService
} from 'src/app/client-details-service.service';

@Component({
  selector: 'app-search-equifax',
  templateUrl: './search-equifax.component.html',
  styleUrls: ['./search-equifax.component.scss'],
})
export class SearchEquifaxComponent implements OnInit {


  newEnteredConsentId: any;
  pdfData: any;
  clientDetail: any;
  submitForm() {
    throw new Error('Method not implemented.');
  }

  gender = 'Select gender';
  name: any;
  phoneNumber: any;
  docType: any;
  docNumber: any;
  address: any;
  addressType = 'Select address type';
  pincode: any;
  dob: any
  clientId: any;
  secretKey: any;
  consentId: any;
  otp: any
  enteredConsentId: any;
  nameError: any;
  genderError: any;
  dobError: any;
  mobileError: any;
  docNumberError: any;
  clientIdError: any;
  secretKeyError: any;
  addressError: any;
  addressTypeError: any;
  pincodeError: any;

  constructor(private http: HttpClient, private modalService: NgbModal, private flagService: DashboardFlagService, private router: Router, private titleService: Title, private clientDetailSevrice: ClientDetailsServiceService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Fetch EQUIFAX Report')
    this.flagService.setDashboardFlag(false);
    this.flagService.setPanApiFlag(false);
    this.flagService.setXcibilFlag(false);
    this.flagService.setBankingFlag(true);


    this.flagService.sendMessage('Load');

    this.clientDetailSevrice.getClientUserDetail().subscribe(
      (data) => {
        if (data) {
          this.clientDetail = data.data
          this.clientId = this.clientDetail.client_id;
          this.secretKey = this.clientDetail.secret_key;
        } else {
          console.error('Failed to fetch client details from service');
        }
      },
      (error) => {
        console.error('Error fetching client details:', error);
      }
    );
  }



  onDobChange() {
    // console.log(this.dob); 
  }




  consent: any
  showConsentId: boolean = false;
  showForm: boolean = false;


  onGenerateConsentId() {
    if (!this.validateForm()) {
      return;
    }

    this.isLoading = true;

    const headers = new HttpHeaders({
      'clientid': this.clientId,
      'secretkey': this.secretKey
    });
    const url = ProjParams.apiurlNew + 'public/apiRoute/generateConsentId';

    const payload = {
      name: this.name,
      gender: this.gender,
      phoneNumber: "91" + this.phoneNumber,
      docType: 'PAN',
      docNumber: this.docNumber,
      address: this.address,
      addressType: this.addressType,
      dob: this.dob,
      pinCode: this.pincode
    };

    // console.log('Payload:', payload);

    this.http.post < any > (url, payload, {
        headers
      })
      .subscribe(response => {
        // console.log('Response consent id:', response.data.consentId);
        this.consent = response.data.consentId
        this.showConsentId = true
        this.showForm = true;
        this.isLoading = false;
        // this.openOtpInputDialog();

      }, error => {
        console.error('Error:', error);
        this.isLoading = false;
        Swal.fire('Info', error.error.message, 'info')
      });
  }
  validateForm(): boolean {
    let isValid = true;
    isValid = this.validateField('clientId') && isValid;
    isValid = this.validateField('secretKey') && isValid;
    isValid = this.validateField('docNumber') && isValid;
    isValid = this.validateField('phoneNumber') && isValid;
    isValid = this.validateField('address') && isValid;
    isValid = this.validateField('addressType') && isValid;
    isValid = this.validateField('pincode') && isValid;
    isValid = this.validateField('gender') && isValid;
    isValid = this.validateField('name') && isValid;
    isValid = this.validateField('dob') && isValid;
    return isValid;
  }

  validateField(field: string): boolean {
    let isValid = true;
    let value = '';
    let errorMessage = '';
    let isPAN = false;

    switch (field) {
      case 'clientId':
        value = this.clientId;
        errorMessage = 'Please enter a Client ID';
        this.clientIdError = '';
        break;
      case 'secretKey':
        value = this.secretKey;
        errorMessage = 'Please enter a Secret Key';
        this.secretKeyError = '';
        break;
      case 'docNumber':
        value = this.docNumber;
        if (!value) {
          errorMessage = 'Please enter document number';
          isValid = false;
        } else {
          errorMessage = 'Invalid PAN number';
          isPAN = true;
          this.docNumberError = '';
        }
        break;
      case 'phoneNumber':
        value = this.phoneNumber;
        if (!value) {
          errorMessage = 'Please enter a mobile number';
          isValid = false;
          this.mobileError = errorMessage;
        } else {
          errorMessage = '';
          this.mobileError = '';
        }
        break;
      case 'address':
        value = this.address;
        if (!value) {
          errorMessage = 'Please enter an address';
          isValid = false;
          this.addressError = errorMessage;
        } else {
          errorMessage = '';
          this.addressError = '';
        }
        break;
      case 'addressType':
        value = this.addressType;
        if (!value || value === 'Select address type') {
          errorMessage = 'Please select an address type';
          isValid = false;
          this.addressTypeError = errorMessage;
        } else {
          errorMessage = '';
          this.addressTypeError = '';
        }
        break;
      case 'pincode':
        value = this.pincode;
        if (!value) {
          errorMessage = 'Please enter a pincode';
          isValid = false;
          this.pincodeError = errorMessage;
        } else {
          errorMessage = '';
          this.pincodeError = '';
        }
        break;
      case 'gender':
        value = this.gender;
        if (!value || value === 'Select gender') {
          errorMessage = 'Please select a gender';
          isValid = false;
          this.genderError = errorMessage;
        } else {
          errorMessage = '';
          this.genderError = '';
        }
        break;
      case 'name':
        value = this.name;
        if (!value) {
          errorMessage = 'Please enter a name';
          isValid = false;
          this.nameError = errorMessage;
        } else {
          errorMessage = '';
          this.nameError = '';
        }
        break;
      case 'dob':
        value = this.dob;
        if (!value) {
          errorMessage = 'Please enter a date of birth';
          isValid = false;
          this.dobError = errorMessage;
        } else {
          errorMessage = '';
          this.dobError = '';
        }
        break;
    }
  

    const element = document.getElementById(field) as HTMLInputElement;

    if (isPAN && isValid) {
      isValid = this.validatePAN(value); // Validate PAN format if necessary
    }



    if (!isValid) {
      this.highlightField(element);
      this.setErrorMessage(field, errorMessage);
    } else {
      this.resetFieldStyle(element);
      this.setErrorMessage(field, '');
    }

    return isValid;
  }

  setErrorMessage(field: string, message: string) {
    switch (field) {
      case 'clientId':
        this.clientIdError = message;
        break;
      case 'secretKey':
        this.secretKeyError = message;
        break;
      case 'docNumber':
        this.docNumberError = message;
        break;
      case 'phoneNumber':
        this.mobileError = message;
        break;

      case 'address':
        this.addressError = message;
        break;
      case 'addressType':
        this.addressTypeError = message;
        break;

      case 'dob':
        this.dobError = message;
        break;

      case 'gender':
        this.genderError = message;
        break;

      case 'pincode':
        this.pincodeError = message;
        break;

      case 'name':
        this.nameError = message;
        break;
    }
    console.log(message)

  }

  highlightField(element: HTMLElement) {
    // element.style.border = '0.3px solid red';
    element.style.boxShadow = '0 0 3px red';

    setTimeout(() => {
      this.resetFieldStyle(element);
    }, 3000); // Reset after 3 seconds
  }

  resetFieldStyle(element: HTMLElement) {
    // element.style.border = '';
    element.style.boxShadow = '';
  }

  resetBorder(event: Event) {
    const element = event.target as HTMLInputElement;
    element.style.border = '';
    element.style.boxShadow = '';
  }

  checkClearInput(field: string, event: Event) {
    const input = (event.target as HTMLInputElement).value;
    
    if (!input) {
        switch (field) {
          case 'clientId':
            this.clientDetail.client_id = ''
            this.clientId = ''
            break;
          case 'secretKey':
            this.clientDetail.secret_key = ''
            this.secretKey = ''
            break;
        }
    }
  }

  validatePAN(pan: string): boolean {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panPattern.test(pan);
  }

  validateMobileNumber(number: string): boolean {
    const mobilePattern = /^[6-9]\d{9}$/;
    return mobilePattern.test(number);
  }

  validateName(name: string): boolean {
    if (!name || name.trim().length === 0) {
      return false; // Name is empty or only whitespace
    }
    const namePattern = /^[A-Za-z\s'-]+$/;
    return namePattern.test(name.trim());
  }

  validatePincode(pincode: string): boolean {
    const pincodePattern = /^[1-9][0-9]{5}$/;
    return pincodePattern.test(pincode);
  }

  toUpperCase(event: any) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    this.docNumber = input.value; // Update the model
    input.setSelectionRange(start, end);
    this.resetBorder(event); // Call resetBorder here instead of in the template
  }



  enteredOtp: any;
  verifyConsentId() {

    if (!this.enteredConsentId || !this.enteredOtp) {
      let message = 'Please enter';

      if (!this.enteredConsentId) message += ' Consent ID,';
      if (!this.enteredOtp) message += ' the OTP,';


      if (message.endsWith(',')) {
        message = message.slice(0, -1);
      }

      Swal.fire("Info", message, "info");
      return;
    }

    const headers = new HttpHeaders({
      'clientid': this.clientId,
      'secretkey': this.secretKey
    });
    const url = ProjParams.apiurlNew + 'public/apiRoute/verifyConsentId';

    this.isLoading = true;
    const payload = {
      consentId: this.enteredConsentId,
      otp: this.enteredOtp
    };




    this.http.post < any > (url, payload, {
        headers
      })
      .subscribe(response => {
        this.isLoading = false;
        Swal.fire('Success', 'OTP verified sucessfully', 'success');
        console.log('Response:', response);
        this.showForm = false;
        // this.openOtpInputDialog2();
        this.showNewForm = true;
      }, error => {
        this.isLoading = false;
        console.error('Error:', error);
        if (error.messsage = 'OTP Verification Unsuccessful') {
          // this.openOtpInputDialog();
          Swal.fire('Info', 'Incorrect OTP, Please enter correct otp', 'info')
        }
      });

  }


  reportData: any;
  errorMessage: any;
  show: boolean = false;
  showNewForm: boolean = false;
  isLoading: boolean = false;

  fetchReport() {
    this.isLoading = true;
    const headers = new HttpHeaders({
      'clientid': this.clientId,
      'secretkey': this.secretKey
    });
    const url = ProjParams.apiurlNew + 'public/apiRoute/fetchEquifax';

    const payload = {
      consentId: this.newEnteredConsentId
    };

    // console.log('Payload:', payload);
    // console.log('headers:', headers);


    this.http.post < any > (url, payload, {
        headers
      })
      .subscribe(response => {
        this.isLoading = false;
        // console.log('Response:', response);
        this.showNewForm = false;
        this.showConsentId = false;
        this.show = true;
        this.reportData = response.data;
        this.fetchReportPDF();
      }, error => {
        this.isLoading = false;
        this.showConsentId = false;
        this.show = true;
        console.error('Error:', error);
        this.showNewForm = false;
        if (error.status === 400 && error.error.message === "Requested user details were not found. Please Check Detail you entered") {
          // Handle specific error message
          this.show = false;
          Swal.fire('Info', error.error.message, 'info');
        } else {
          this.show = false;
          // Handle other errors
          this.errorMessage = error.message;
          Swal.fire('Info', this.errorMessage, 'info');
        }
      });



  }








  fetchReportPDF() {
    this.isLoading = true;
    const headers = new HttpHeaders({
      'clientid': this.clientId,
      'secretkey': this.secretKey
    });
    const url = ProjParams.apiurlNew + 'public/apiRoute/getequifaxReportPdf';

    const payload = {
      panNo: this.docNumber
    };

    this.http.post < any > (url, payload, {
        headers
      })
      .subscribe((response) => {
        this.isLoading = false;
        const pdfData = response.data[0].reportData;
        this.pdfData = pdfData;
        this.show = true;

      }, error => {
        this.isLoading = false;
        this.show = true;
        console.error('Error:', error);
      });
  }

  viewPDF() {
    const decodedData = atob(this.pdfData);
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], {
      type: 'application/pdf'
    });
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new window
    const newWindow = window.open(url, '_blank');

    // Clean up
    URL.revokeObjectURL(url);
  }

  downloadPDF() {
    if (typeof this.pdfData !== 'string' || this.pdfData === '') {
      console.error('PDF data is not valid');
      return;
    }

    let decodedData;
    try {
      decodedData = atob(this.pdfData);
    } catch (error) {
      console.error('Failed to decode PDF data:', error);
      return;
    }

    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], {
      type: 'application/pdf'
    });

    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'Equifax Report.pdf';

    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }





  clear() {
    this.show = false;
    this.reportData = '';
    this.gender = 'Select gender';
    this.name = '';
    this.phoneNumber = '';
    this.docType = '';
    this.docNumber = '';
    this.address = '';
    this.addressType = 'Select address type';
    this.pincode = '';
    this.dob = ''
    this.clientId = '';
    this.secretKey = '';
    this.consentId = '';
    this.otp = ''
    this.enteredConsentId = '';
    this.enteredOtp = '';
    this.newEnteredConsentId = '';
    this.pdfData = '';




  }

  goBack() {
    this.router.navigate(['/api/banking-api'])
  }

  isPanValid(panNumber: string): boolean {
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    return panRegex.test(panNumber);
  }





}