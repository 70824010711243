import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';
  import {
    RouterModule
  } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SearchXcibilComponent } from './search-xcibil.component';

  
  
  
  @NgModule({
    imports: [ RouterModule, CommonModule, FormsModule ],
      declarations: [ SearchXcibilComponent ],
      exports: [ SearchXcibilComponent ]
  })
  export class SearchXcibilModule {}