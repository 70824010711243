import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-query-table-search-box',
  templateUrl: './query-table-search-box.component.html',
  styleUrls: ['./query-table-search-box.component.scss'],
})
export class QueryTableSearchBoxComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal){ }

  ngOnInit() {}

    //close modal icon logic
    closeModal() {
      this.activeModal.close('Modal closed');
    }

}
