import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewInvoiceComponent } from './view-invoice.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    
],
  declarations: [ViewInvoiceComponent]
})
export class ViewInvoiceModule {}
