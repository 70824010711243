import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarNewComponent } from './navbar-new.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [ RouterModule, CommonModule, NgbModule],
    declarations: [ NavbarNewComponent],
    exports: [ NavbarNewComponent ]
})

export class NavbarNewModule {}
    