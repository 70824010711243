import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GstApiComponent } from './gst-api.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [ RouterModule, CommonModule, FormsModule],
    declarations: [ GstApiComponent],
    exports: [GstApiComponent]
})

export class GstApiModule {}
 