// dashboard-flag.service.ts

import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardFlagService {
  private isDashboard: boolean = false;

  setDashboardFlag(value: boolean): void {
    this.isDashboard = value;
    localStorage.setItem('isDashboard', String(value));
  }

  getDashboardFlag(): boolean {
    const storedValue = localStorage.getItem('isDashboard');
    if (storedValue !== null) {
      this.isDashboard = JSON.parse(storedValue);
    }
    return this.isDashboard;
  }

  setBankingFlag(value: boolean): void {
    this.isDashboard = value;
    localStorage.setItem('isBanking', String(value));
  }

  getBankingFlag(): boolean {
    const storedValue = localStorage.getItem('isBanking');
    if (storedValue !== null) {
      this.isDashboard = JSON.parse(storedValue);
    }
    return this.isDashboard;
  }


  setPanApiFlag(value: boolean): void {
    this.isDashboard = value;
    localStorage.setItem('isPanApi', String(value));
  }

  getPanApiFlag(): boolean {
    const storedValue = localStorage.getItem('isPanApi');
    if (storedValue !== null) {
      this.isDashboard = JSON.parse(storedValue);
    }
    return this.isDashboard;
  }


  setXcibilFlag(value: boolean): void {
    this.isDashboard = value;
    localStorage.setItem('isXcibil', String(value));
  }

  getXcibilFlag(): boolean {
    const storedValue = localStorage.getItem('isXcibil');
    if (storedValue !== null) {
      this.isDashboard = JSON.parse(storedValue);
    }
    return this.isDashboard;
  }

  setGstFlag(value: boolean): void {
    this.isDashboard = value;
    localStorage.setItem('isGst', String(value));
  }

  getGstFlag(): boolean {
    const storedValue = localStorage.getItem('isGst');
    if (storedValue !== null) {
      this.isDashboard = JSON.parse(storedValue);
    }
    return this.isDashboard;
  }

  public actionEvent: EventEmitter<string> = new EventEmitter();

  constructor() { }

  sendMessage(message: string) {
    this.actionEvent.emit(message);
  }

  
}
