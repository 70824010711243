import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjParams } from 'src/app/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-api-login-modal-three',
  templateUrl: './api-login-modal-three.component.html',
  styleUrls: ['./api-login-modal-three.component.scss'],
})
export class ApiLoginModalThreeComponent implements OnInit {

  constructor( private activeModal: NgbActiveModal, private modalService: NgbModal, private router: Router, private http: HttpClient) { }
  isLoading: boolean = false;
  errors: any;
  confirmStrong: boolean = false;
  isPasswordStrong: boolean = false;
  passwordStrengthMessage: string;


  @Input() email: any;

  data: any = {
  newPassword: '',
  userId: '',
  }
showPassword: boolean = false;


  ngOnInit() {
    this.data.userId  = this.email;
    // console.log('the email from modal two is', this.data.userId);
  }


  login: any = {
    newPassowrd: '',
    confirmPassword: ''
  }


  isNotSame: boolean =  false;

  validatePasswords() {
    this.isNotSame = (this.login.confirmPassword !== this.data.newPassword) && this.data.newPassword && this.login.confirmPassword;
  }

  clearPassError() {
    this.isNotSame = false
  }

  clientToken = localStorage.getItem('newUserAuthKey');

  httpOptionsClient = {
    headers: new HttpHeaders({
       'clientauth':this.clientToken
     }) 
   };


   resetPassword() {
    // Validate input
    if (!this.login.newPassword || !this.login.confirmPassword) {
      Swal.fire('Info', 'Please enter and confirm your new password', 'info');
      return;
    }

    if (this.login.newPassword !== this.login.confirmPassword) {
      Swal.fire('Info', 'Passwords do not match', 'info');
      return;
    }

    if (!this.isPasswordStrong) {
      return;
    }

    const patchData = {
      newPassword: this.login.newPassword,
      userId: this.data.userId
      // Add other fields as needed
    };

    const apiUrl = ProjParams.apiurl + 'apiPanel/resetPassword';
    // console.log('Request Headers:', this.httpOptionsClient);

    this.http.patch(apiUrl, patchData)
      .subscribe(
        (response) => {
          // console.log('Password updated successfully:', response);
          Swal.fire('Success', 'Password updated successfully', 'success').then(() => {
            this.activeModal.close(); 
            this.router.navigate(['/api-login']); 
          });
        },
        (error) => {
          console.error('Error updating password:', error);
          Swal.fire('Info', 'Failed to update password. Please try again.', 'info');
        }
      );
  }


  toggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

  isInputFocused: boolean = false;

  onInputFocus() {
    this.confirmStrong = true;
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  checkPasswordStrength() {
    if (this.login.newPassword && this.login.newPassword.length >= 8 && /[0-9]/.test(this.login.newPassword) &&
        /[a-z]/.test(this.login.newPassword) &&
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.login.newPassword)) {
      this.isPasswordStrong = true;
      this.passwordStrengthMessage = "Password meets strength requirements";
    } else {
      this.isPasswordStrong = false;
      this.passwordStrengthMessage = "Password should be 8+ characters with a mix of letters, numbers, and symbols";
    }
  }

}
