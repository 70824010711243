import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { ProjParams } from 'src/app/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-raise-query',
  templateUrl: './raise-query.component.html',
  styleUrls: ['./raise-query.component.scss'],
})
export class RaiseQueryComponent implements OnInit {
  concernInBrief: any;
  @Output() updateSuccessEvent = new EventEmitter<void>();
  typeError: any;
  type = 'Select Type';
  concern: any;
  selectedFile: File | null = null;
  selectedFileName: string | null = null;
  fileError: string = '';
  relatedTo: any;
  clientToken = localStorage.getItem('newUserAuthKey');

    httpOptionsClient = {
    headers: new HttpHeaders({
        'clientauth':this.clientToken
      }) 
    };
  constructor(private activeModal: NgbActiveModal, private http: HttpClient) {}

  ngOnInit() {
this.loadQueryTypes();
  }
  validateField(arg0: string) {
    throw new Error('Method not implemented.');
  }

  selectedFiles: File[] = [];

  closeModal() {
    this.activeModal.close()
  }

  headers: any;
  queryTypes: any[] = [];

  loadQueryTypes(){
    var url = ProjParams.clientUrl + 'xb/apiPanel/getQueryTypes';
    const clientToken = localStorage.getItem('newUserAuthKey');
  
    
    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.queryTypes = data.data;

        } else {
          console.error('Invalid data format:', data);

        }
      },
      
      (error) => {
        if (error.error.message) {
          console.error('Error:', error); 
        }          console.error('Error:', error);
      }
    );
  }





  showTypeError: boolean = false;
  showConcernError: boolean = false;


  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg'];
  
    // Reset state
    this.selectedFile = null;
    this.fileError = '';
  
    if (!file) {
      this.fileError = 'No file selected.';
      return;
    }
  
    if (!validTypes.includes(file.type)) {
      this.fileError = 'Please select only PDF or JPG/JPEG files.';
      return;
    }
  
    if (file.size > 1000000) {
      this.fileError = 'File size should be less than 1MB.';
      return;
    }
  
    this.selectedFile = file;
  }
  
  async createQuery(): Promise<void> {
    console.log(this.selectedFile);
  
    // Check for errors
    this.showTypeError = this.type === 'Select Type';
    if (!this.concern) {
      this.showConcernError = true;
      return;
    }
    this.showConcernError = false;
    this.showTypeError = false;
  
    // Create FormData instance
    const formData = new FormData();
  
    // Append JSON data as a string
    formData.append('query_type', this.type);
    formData.append('query_related_to', '');
    formData.append('description', this.concern);
  
    // Append file if selected
    if (this.selectedFile) {
      formData.append('attachment', this.selectedFile, this.selectedFile.name);
    }
  
    // API endpoint URL
    const apiUrl = ProjParams.clientUrl + 'xb/apiPanel/createQuery';
  
    // Get the client token from local storage
    const clientToken = localStorage.getItem('newUserAuthKey');
  
    // Define headers
    const headers = new Headers();
    if (clientToken) {
      headers.append('clientauth', clientToken);
    }
  
    try {
      // Send the request
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: headers,
      });
  
      // Handle the response
      const data = await response.json();
      if (response.ok) {
        Swal.fire('Success', 'Query created successfully', 'success');
        this.updateSuccessEvent.emit();
        this.resetForm();
      } else {
        Swal.fire('Error', data.message || 'Error creating query', 'error');
      }
    } catch (error) {
      console.error('Error creating query', error);
      Swal.fire('Error', 'Error creating query', 'error');
    }
  }
  
  

  resetForm() {
    this.activeModal.close();
    this.type = 'Select Type';
    this.concern = '';
    this.selectedFile = null;
    this.selectedFileName = '';
    this.showTypeError = false;
    this.showConcernError = false;
    this.fileError = '';
  }


}