import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ErrorPageComponent } from './error-page.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [ErrorPageComponent]
})
export class ErrorPageModule {}
