import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoice-table-search-box',
  templateUrl: './invoice-table-search-box.component.html',
  styleUrls: ['./invoice-table-search-box.component.scss'],
})
export class InvoiceTableSearchBoxComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {}

    //close modal icon logic
    closeModal() {
      this.activeModal.close('Modal closed');
    }

}
