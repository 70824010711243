import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjParams } from 'src/app/config';
import { EncryptionServiceService } from 'src/app/encryption-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pass-reset',
  templateUrl: './pass-reset.component.html',
  styleUrls: ['./pass-reset.component.scss'],
})
export class PassResetComponent implements OnInit {


  isLoading: boolean = false;
  confirmStrong: boolean = false;
  isPasswordStrong: boolean = false;
  passwordStrengthMessage: string;
      newPassword: any;
    confirmPassword:any;
    userId: any;



  constructor(private http : HttpClient, private router: Router, private encryptionService: EncryptionServiceService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      // Get the encrypted parameters from the route
      const encryptedUserId = params['email'];

  
      // Decrypt the parameters using the EncryptionService
      const decryptedEmail = this.encryptionService.decryptEmail(encryptedUserId);
      this.userId = decryptedEmail;
  
      // console.log('Decrypted Customer Branch ID:', this.decryptedCustomerBranchId);

      // console.log('teh customer email from client list is : ', this.userId);



    });
  }

  signIn() {
    if (!this.isNotSame) {

      //post goes here

    }
  }

  isNotSame = false;

  validatePasswords() {
    this.isNotSame = (this.confirmPassword !== this.newPassword) && this.newPassword && this.confirmPassword;
  }

  clearPassError() {
    this.isNotSame = false
  }

  resetPassword() {
    if (!this.isNotSame && this.isPasswordStrong) {

      //post goes here

      const patchData = {
        newPassword: this.newPassword,
        userId: this.userId
        // userId: this.userId
      };
  
      const apiUrl = ProjParams.apiurl + 'apiPanel/resetPassword';
  
      this.http.patch(apiUrl, patchData)
        .subscribe(
          (response) => {
            // console.log('Password reset successfull:', response);
            Swal.fire('Success', 'Password reset successfull.', 'success').then(() => {
              this.router.navigate(['/all-api-page']);
            });
          },
          (error) => {
            console.error('Error updating password:', error);
            const errorMessage = error.message
            Swal.fire('Info', errorMessage, 'info');
          }
        );
    }


  }

  isInputFocused: boolean = false;

  onInputFocus() {
    this.confirmStrong = true;
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  checkPasswordStrength() {
    if (this.newPassword && this.newPassword.length >= 8 && /[0-9]/.test(this.newPassword) &&
        /[a-z]/.test(this.newPassword) &&
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.newPassword)) {
      this.isPasswordStrong = true;
      this.passwordStrengthMessage = "Password meets strength requirements";
    } else {
      this.isPasswordStrong = false;
      this.passwordStrengthMessage = "Password should be 8+ characters with a mix of letters, numbers, and symbols";
    }
  }



}