import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorAccessGuard implements CanActivate {
  constructor( private router: Router) {}

  canActivate(): boolean {
    const server = localStorage.getItem('server'); 

    if (server === 'running') {
      this.router.navigate(['/api/pan-api']); 
      return false; 
    }

    return true; 
  }
  
}
