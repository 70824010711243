import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private flagSubject = new Subject<boolean>();
  flagObservable$ = this.flagSubject.asObservable();

  private paymentSubject = new Subject<boolean>();
  paymentObservable$ = this.paymentSubject.asObservable();

  sendFlag(flag: boolean) {
    this.flagSubject.next(flag);
  }


  sendPaymentSuccessfulFlag(paymentFlag: boolean) {
    this.paymentSubject.next(paymentFlag);
  }


  private apiHitSubject = new Subject<void>();

  apiHit$ = this.apiHitSubject.asObservable();

  sendApiHitSignal() {
    this.apiHitSubject.next();
  }

  private formData: any = {
    clientId: '',
    secretKey: '',
    panNumber: ''
  };

  setFormData(data: any) {
    this.formData = data;
  }

  getFormData() {
    return this.formData;
  }



  private formDatauat: any = {
    clientId: '',
    secretKey: '',
    panNumber: ''
  };

  setFormDatauat(data: any) {
    this.formDatauat = data;
  }

  getFormDatauat() {
    return this.formDatauat;
  }


  
}
