// all-api-page.component.ts

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ProjParams } from 'src/app/config';
import { DataService } from 'src/app/data.service';
import { FlagService } from 'src/app/flag.service';

@Component({
  selector: 'app-all-api-page',
  templateUrl:  './all-api-page.component.html',
  styleUrls: ['./all-api-page.component.scss'],
})
export class AllApiPageComponent implements OnInit {
  clientDetails: any = [];
  clientToken = localStorage.getItem('newUserAuthKey');

   httpOptionsClient = {
    headers: new HttpHeaders({
       'clientauth':this.clientToken
     }) 
   };

  constructor(private router: Router, private dataService: DataService, private authService: AuthService, private http: HttpClient, private flagService: FlagService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle('XCIBIL - API Services')
    window.history.pushState(null, '', window.location.href);

    this.getService();
  }

  redirectToDashboard() {
        this.router.navigate(['/api/pan-api']);
        this.flagService.updateName('pan-api');
  }

  redirectToBankingApi() {
    this.router.navigate(['/api/banking-api']);
  }

  redirectToPanApi() {
    this.router.navigate(['/api/pan-api']);
  }

  redirectToXcibilReportApi() {
    this.router.navigate(['/api/xcibil-report']);
  }

  getService(){
    var url = ProjParams.clientUrl + 'xb/apiPanel/getService';

    this.http.get(url, this.httpOptionsClient).subscribe(
      (data: any) => {
        if (data && data.data) {
          this.clientDetails = data.data;
          // console.log('Client Details: ', this.clientDetails);
        } else {
          console.error('Invalid data format:', data);
        }
      },
      (error) => {
        if (error.error.message) {
          console.error('Error:', error); 
        }        console.error('Error:', error);
      }
    );
  }
}
